import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { Filter } from "src/app/models/filter.interface";
import { IChargeItemsInfo } from "../billing.interface";
import { BillingApiService } from "./billing-api.service";

@Injectable({
  providedIn: "root",
})
export class BillingService {
  constructor(private invoiceApi: BillingApiService) {}

  public findInvoices(params: DataSourceBackendParam): Observable<IChargeItemsInfo[]> {
    params.filters = this.formatFilters(params.filters);
    return this.invoiceApi.findInvoices(params);
  }

  public getInvoicesCount(services: string[], search?: string, filters?: Filter[]): Observable<{ count: number }> {
    return this.invoiceApi.getInvoicesCount(services, search, this.formatFilters(filters));
  }

  private formatFilters(filters: Filter[]): Filter[] {
    // Placeholder pour adapter les filtres si nécessaire
    return filters;
  }
}
