export enum BILLING_TYPE {
  PLANNED = "PLANNED",
  BILLABLE = "BILLABLE",
  NOT_BILLABLE = "NOT_BILLABLE",
  BILLED = "BILLED",
}

/**
 * Charge item information for display in the invoice list.
 */
export interface IChargeItemsInfo {
  caremateIdentifier: string;
  firstname: string;
  lastname: string;
  birthDate: string;
  category: string; // (de la prescription associé)
  startDate: string;
  endDate: string;
  code: string;
  totalPrice: string;
  statut: BILLING_TYPE;
  sentAt?: string;
}
