import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RelatedHelper } from "../helpers/relatedHelper";
import { Coding } from "../models/coding.interface";
import { Identifier } from "../models/identifier.interface";
import { IRelatedPerson } from "../models/related.interface";
import { Reference } from "../models/sharedModels.model";
import { RelatedApiService } from "./api/related-api.service";

@Injectable({
  providedIn: "root",
})
export class RelatedService {
  constructor(private relatedApiService: RelatedApiService) {}

  public create(
    patient: Reference,
    firstname: string,
    lastname: string,
    mail: string,
    phone: string,
    birthdate: string,
    relationship?: Coding[],
    reqParams?: Record<string, string | boolean>
  ): Observable<IRelatedPerson> {
    const related = RelatedHelper.asRelatedPerson(patient, firstname, lastname, mail, phone, birthdate, relationship);
    return this.relatedApiService.create(related, reqParams);
  }

  public getPatientRelateds(patientId: string): Observable<IRelatedPerson[]> {
    return this.relatedApiService.getPatientRelateds(patientId);
  }

  public update(
    identifier: Identifier,
    patient: Reference,
    isActive: boolean,
    firstname: string,
    lastname: string,
    mail: string,
    phone: string,
    birthdate: string,
    relationship?: Coding[]
  ): Observable<IRelatedPerson> {
    const related = RelatedHelper.asRelatedPerson(patient, firstname, lastname, mail, phone, birthdate, relationship, isActive);
    related.identifier = identifier;
    return this.relatedApiService.update(related);
  }
}
