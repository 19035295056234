<mat-card class="comunicare-widget" cdkDrag [cdkDragDisabled]="!isDraggable">
  <mat-card-header class="widget-header biggerHeader">
    <mat-card-title class="custom-widget-title">
      {{ "widget.pendingPrescriptions" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list class="p-0" [ngStyle]="isLoading ? { display: 'none' } : {}">
      <mat-list-item *ngFor="let element of dataSource.rawData$ | async" class="widget-list-item pt-05 pb-05">
        <button
          class="mr-1"
          mat-icon-button
          [routerLink]="['/patient', { id: encodeToB64(element.patientCaremateId) }]"
          [state]="{
            caremateIdentifier: element.patientCaremateId,
            widget: widgetName.PENDING_PRESCRIPTIONS
          }"
          matTooltip="{{ element.lastname + ' ' + element.firstname }}"
          matTooltipClass="truncatedNamesTooltip"
        >
          <mat-icon>face</mat-icon>
        </button>

        <div class="widget-list-details">
          <div class="line">
            <p class="custom-widget-font">
              <b>{{ element.lastname }} {{ element.firstname }}</b>
              - {{ element.category }}
            </p>
          </div>

          <div class="line">
            <p class="custom-widget-font">
              {{ element.dateRequest | date : "dd/MM/yyyy HH:mm" }}
            </p>
          </div>

          <div class="line">
            <p class="custom-widget-font">
              {{ "table.status" | translate }} :
              {{ "prescription.status." + element.status | translate }}
            </p>
          </div>
        </div>

        <button
          mat-icon-button
          matTooltip="{{ 'btn.goToPatient' | translate }}"
          class="custom-icon-w"
          color="primary"
          (click)="validatePrescription(element)"
        >
          <mat-icon class="custom-icon-small warn">task_alt</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
    <p class="text-center custom-widget-font" *ngIf="dataSource.data.length === 0 && !isLoading">
      {{ "widget.noData" | translate }}
    </p>
    <div class="spinner-container" *ngIf="(dataSource.loading$ | async) || isLoading">
      <mat-spinner></mat-spinner>
    </div>
    <mat-paginator
      #paginator
      [pageIndex]="0"
      [pageSize]="currentPageSize ? currentPageSize : 5"
      [length]="pendingPrescriptionsCount"
      [hidePageSize]="true"
      [ngClass]="{ hide: dataSource.data.length === 0 && isLoading }"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
