import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JoditAngularModule } from "jodit-angular";
import { ChartsModule } from "ng2-charts";
import { CookieService } from "ngx-cookie-service";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AddToGroupDialogComponent } from "./components/add-to-group-dialog/add-to-group-dialog.component";
import { GroupSearchOnlyComponent } from "./components/add-to-group-dialog/group-search-only/group-search-only.component";
import { GroupServerSideSearchComponent } from "./components/add-to-group-dialog/group-server-side-search-component/group-server-side-search.component";
import { CareplanComponent } from "./components/careplan/careplan.component";
import { ChangeLangComponent } from "./components/change-lang/change-lang.component";
import { CommunicationOverviewComponent } from "./components/communication-overview/communication-overview.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { FiltersDisplayModule } from "./components/filters-display/filters-display.module";
import { AddCommunicationComponent } from "./components/forms/add-communication/add-communication.component";
import { AddGroupComponent } from "./components/forms/add-group/add-group.component";
import { AddOrganizationComponent } from "./components/forms/add-organization/add-organization.component";
import { AddServiceComponent } from "./components/forms/add-service/add-service.component";
import { EditAnonymousPatientComponent } from "./components/forms/edit-anonymous-patient/edit-anonymous-patient.component";
import { InitPwdComponent } from "./components/forms/init-password/init-password.component";
import { GlobalErrorDisplayComponent } from "./components/global-error-display/global-error-display.component";
import { GlobalHelpDialogComponent } from "./components/global-help-dialog/global-help-dialog.component";
import { ItemFilterModule } from "./components/item-filter/item-filter.module";
import { ItemSelectorComponent } from "./components/item-selector/item-selector.component";
import { LangConflictDialogComponent } from "./components/lang-conflict-dialog/lang-conflict-dialog.component";
import { ObservationChartComponent } from "./components/observation-chart/observation-chart.component";
import { OrganizationComponent } from "./components/organization/organization.component";
import { AnswerNewQrComponent } from "./components/patient-QR/answer-new-qr/answer-new-qr.component";
import { ListOfPracQRComponent } from "./components/patient-QR/list-of-prac-qr/list-of-prac-qr.component";
import { PatientQRComponent } from "./components/patient-QR/patient-QR.component";
import { QRDetailsComponent } from "./components/patient-QR/qr-details/qr-details.component";
import { QuestionnaireScoringGraphComponent } from "./components/patient-QR/qr-graph/questionnaireScoringGraph";
import { QRValuePossiblesComponent } from "./components/patient-QR/qr-values-possibles/qr-values-possibles.component";
import { ScoreDetailsComponent } from "./components/patient-QR/score-details/score-details.component";
import { AlertDetailsEditComponent } from "./components/patient-alerts/alert-details/alert-details-edit/alert-details-edit.component";
import { AlertDetailsHistoricComponent } from "./components/patient-alerts/alert-details/alert-details-historic/alert-details-historic.component";
import { AlertDetailsComponent } from "./components/patient-alerts/alert-details/alert-details.component";
import { RuleAlertComponent } from "./components/patient-alerts/patient-alerts.component";
import { PatientCareplansComponent } from "./components/patient-careplans/patient-careplans.component";
import { PatientCommunicationsComponent } from "./components/patient-communications/patient-communications.component";
import { DrugAddComponent } from "./components/patient-drugs/drug-add/drug-add.component";
import { DrugDetailsComponent } from "./components/patient-drugs/drug-details/drug-details.component";
import { DrugsHistoricComponent } from "./components/patient-drugs/drugs-historic/drugs-historic.component";
import { PatientDrugsComponent } from "./components/patient-drugs/patient-drugs.component";
import { PatientInfoModule } from "./components/patient-infos/patient-infos.module";
import { PatientObservationsComponent } from "./components/patient-observations/patient-observations.component";
import { PatientRewardComponent } from "./components/patient-reward/patient-reward.component";
import { RewardDetailsEditComponent } from "./components/patient-reward/reward-details/reward-details-edit/reward-details-edit.component";
import { RewardDetailsHistoricComponent } from "./components/patient-reward/reward-details/reward-details-historic/reward-details-historic.component";
import { RewardDetailsComponent } from "./components/patient-reward/reward-details/reward-details.component";
import { RewardsDialogComponent } from "./components/patient-reward/rewards-dialog/rewards-dialog.component";
import { PatientRiskAssessmentComponent } from "./components/patient-risk-assessment/patient-risk-assessment.component";
import { RiskAssessmentChartComponent } from "./components/patient-risk-assessment/risk-assessment-details/risk-assessment-chart/risk-assessment-chart.component";
import { RiskAssessmentDetailsComponent } from "./components/patient-risk-assessment/risk-assessment-details/risk-assessment-details.component";
import { PatientTeleconsultationDialogComponent } from "./components/patient-teleconsultations-dialog/patient-teleconsultations-dialog.component";
import { ServerSideSearchComponent } from "./components/patient-teleconsultations-dialog/server-side-search-component/server-side-search.component";
import { PatientTeleconsultationsComponent } from "./components/patient-teleconsultations/patient-teleconsultations.component";
import { PatientVitalSignsComponent } from "./components/patient-vitalSigns/patient-vital-signs.component";
import { VitalSignsHistoricComponent } from "./components/patient-vitalSigns/vital-signs-historic/vital-signs-historic.component";
import { PreferenceDialogComponent } from "./components/preference-dialog/preference-dialog.component";
import { ReleaseNoteDialogComponent } from "./components/release-note-dialog/release-note-dialog.component";
import { ServiceDetailsComponentComponent } from "./components/service-details-component/service-details-component.component";
import { TeleConsultationDialogComponent } from "./components/tele-consultation-dialog/tele-consultation-dialog.component";
import { UserInfosComponent } from "./components/user-infos/user-infos.component";
import { UserListDialogComponentComponent } from "./components/user-list-dialog-component/user-list-dialog-component.component";
import { WidgetModule } from "./components/widgets/widget.module";
import { UserHelper } from "./helpers/UserHelper";
import { FormsData } from "./helpers/formsData";
import { HelpData } from "./helpers/helpData";
import { I18nModule } from "./i18n.module";
import { CommunicationsPageComponent } from "./pages/communications-page/communications-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { ErrorPageComponent } from "./pages/error-page/error-page.component";
import { GroupsListPageComponent } from "./pages/groups-list-page/groups-list-page.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { MyServicePageComponent } from "./pages/my-service-page/my-service-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { OrganizationListPageComponent } from "./pages/organization-list-page/organization-list-page.component";
import { PatientListPageComponent } from "./pages/patient-list-page/patient-list-page.component";
import { PatientPageComponent } from "./pages/patient-page/patient-page.component";
import { QuestionnaireListPageComponent } from "./pages/questionnaire-list-page/questionnaire-list-page.component";
import { RewardsPageComponent } from "./pages/rewards-page/rewards-page.component";
import { TeleconsultationsPageComponent } from "./pages/teleconsultation-page/teleconsultation-page.component";
import { UserListPageComponent } from "./pages/user-list-page/user-list-page.component";
import { ApiService } from "./providers/api/api.service";
import { GlobalErrorHandler } from "./providers/global-error-handler";
import { HttpErrorInterceptor } from "./providers/http-error-interceptor";
import { MatPaginatorI18N } from "./providers/mat-paginator-i18n";
import { TileManager } from "./providers/tile-manager.service";
// eslint-disable-next-line max-len
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DatePipe, DecimalPipe } from "@angular/common";
import { MatBadgeModule } from "@angular/material/badge";
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MdePopoverModule } from "@material-extended/mde";
import { NgxViewerjsModule } from "ngx-viewerjs";
import { environment } from "src/environments/environment";
import { CareplanEditorPageModule } from "./careplan-editor/views/careplan-editor-page/careplan-editor-page.module";
import { CareplansListPageComponent } from "./careplan-editor/views/careplans-list-page/views/careplans-list-page/careplans-list-page.component";
import { AnsPractitionnerResultComponent } from "./components/ans-practitionner-result/ans-practitionner-result.component";
import { AppointmentDelayDialogComponent } from "./components/appointment-delay-dialog/appointment-delay-dialog.component";
import { AppointmentDelayComponent } from "./components/appointment-delay/appointment-delay.component";
import { StreamObsChartComponent } from "./components/charts/stream-obs-chart/stream-obs-chart.component";
import { StreamObsStatChartComponent } from "./components/charts/stream-obs-stat-chart/stream-obs-stat-chart.component";
import { ConfirmResetPasswordDialogComponent } from "./components/confirm-reset-password-dialog/confirm-reset-password-dialog.component";
import { DisplayAuthcodeConfirmationComponent } from "./components/display-authcode-confirmation/display-authcode-confirmation.component";
import { DisplayEnvironementDialogComponent } from "./components/display-environement-dialog/display-environement-dialog.component";
import { DisplayPicturesComponent } from "./components/display-pictures/display-pictures.component";
import { ErrorGeneratePasswordComponent } from "./components/error-generate-password/error-generate-password.component";
import { ErrorOnGettingCaremateIDComponent } from "./components/error-on-getting-caremate-id/error-on-getting-caremate-id.component";
import { AddDrugSchemaComponent } from "./components/forms/add-drug-schema/add-drug-schema.component";
import { PauseDrugSchemaComponent } from "./components/forms/add-drug-schema/pause-drug-schema/pause-drug-schema.component";
import { KnowledgeCreationDialogComponent } from "./components/forms/add-knowledge/add-knowledge.component";
import { ChooseInsuranceDialogComponent } from "./components/forms/add-patient/patient-already-exists-dialog/choose-insurance-dialog/choose-insurance-dialog.component";
import { PatientAlreadyExistsDialogComponent } from "./components/forms/add-patient/patient-already-exists-dialog/patient-already-exists-dialog.component";
import { TownServerSideSearchComponent } from "./components/forms/add-patient/town-server-side-search-component/town-server-side-search.component";
import { AddStepwiseDrugComponent } from "./components/forms/add-stepwise-drug/add-stepwise-drug.component";
import { CreateStepwiseComponent } from "./components/forms/add-stepwise-drug/create-stepwise/create-stepwise.component";
import { ResetOwnPasswordComponent } from "./components/forms/reset-own-password/reset-own-password.component";
import { VitalSignFormInputComponent } from "./components/forms/vitalSignForm/vital-sign-form-input/vital-sign-form-input.component";
import { VitalSignFormComponent } from "./components/forms/vitalSignForm/vital-sign-form/vital-sign-form.component";
import { KnowledgeMediaListModalComponent } from "./components/knowledge-media-list-modal/knowledge-media-list-modal.component";
import { MfaSetupComponent } from "./components/mfa-setup/mfa-setup.component";
import { NoColumnInfoComponent } from "./components/no-column-info/no-column-info.component";
import { OrgServiceSelectionComponent } from "./components/org-service-selection-dialog/org-service-selection-dialog.component";
import { EncodeObservationsComponent } from "./components/patient-QR/answer-new-qr/encode-observations/encode-observations.component";
import { PdfTypeChoiceComponent } from "./components/patient-QR/pdf-type-choice/pdf-type-choice.component";
import { AlertPreferenceComponent } from "./components/patient-alerts/alert-preference/alert-preference.component";
import { DocumentModalComponent } from "./components/patient-documents/document-modal/document-modal.component";
import { PatientDocumentsComponent } from "./components/patient-documents/patient-documents.component";
import { AddDrugIntakeDialogComponent } from "./components/patient-drugs/add-drug-intake-dialog/add-drug-intake-dialog.component";
import { DrugCommentComponent } from "./components/patient-drugs/drug-add/drug-comment/drug-comment.component";
import { DrugCycleComponent } from "./components/patient-drugs/drug-add/drug-cycle/drug-cycle.component";
import { DrugServerSideSearchModule } from "./components/patient-drugs/drug-add/drug-server-side-search-component/drug-server-side-search.module";
import { DrugExpansionPanelComponent } from "./components/patient-drugs/drug-expansion-panel/drug-expansion-panel.component";
import { DrugGraphComponent } from "./components/patient-drugs/drug-graph/drug-graph.component";
import { DrugStockComponent } from "./components/patient-drugs/drug-stock/drug-stock.component";
import { DrugsCalendarComponent } from "./components/patient-drugs/drugs-calendar/drugs-calendar.component";
import { PatientFormComponent } from "./components/patient-form/patient-form.component";
import { IdentityStatusChangeHistoricComponent } from "./components/patient-infos/identity-status-change-historic/identity-status-change-historic.component";
import { PatientDisableDialogComponent } from "./components/patient-infos/patient-disable-dialog/patient-disable-dialog.component";
import { PatientHistoricDialogComponent } from "./components/patient-infos/patient-historic-dialog/patient-historic-dialog.component";
import { DefaultObservationTableComponent } from "./components/patient-observations/default-observation-table/default-observation-table.component";
import { HourlyObservationTableComponent } from "./components/patient-observations/hourly-observation-table/hourly-observation-table.component";
import { MirGraphModalComponent } from "./components/patient-observations/mir-graph-modal/mir-graph-modal.component";
import { MomentObservationTableComponent } from "./components/patient-observations/moment-observation-table/moment-observation-table.component";
import { ObservationAlertsHistoryModalComponent } from "./components/patient-observations/observation-alerts-history-modal/observation-alerts-history-modal.component";
import { ObservationDefinitionListComponent } from "./components/patient-observations/observation-definition-list/observation-definition-list.component";
import { ObservationStatsContainerComponent } from "./components/patient-observations/observation-stats-container/observation-stats-container.component";
import { ObservationStatsComponent } from "./components/patient-observations/observation-stats-container/observation-stats/observation-stats.component";
import { StatCardComponent } from "./components/patient-observations/observation-stats-container/stat-card/stat-card.component";
import { StatLinechartComponent } from "./components/patient-observations/observation-stats-container/stat-linechart/stat-linechart.component";
import { StatStackedBarplotChartComponent } from "./components/patient-observations/observation-stats-container/stat-stacked-barplot-chart/stat-stacked-barplot-chart.component";
import { StatTableComponent } from "./components/patient-observations/observation-stats-container/stat-table/stat-table.component";
import { PatientObservationsPipesModule } from "./components/patient-observations/patient-observations-pipes/patient-observations-pipes.module";
import { TranslateObservationNamePipe } from "./components/patient-observations/patient-observations-pipes/translate-observation-name.pipe";
import { PatientObservationsStatCardComponent } from "./components/patient-observations/patient-observations-stat-card/patient-observations-stat-card.component";
import { SmallObservationTableComponent } from "./components/patient-observations/small-observation-table/small-observation-table.component";
import { RiskAssessmentStore } from "./components/patient-risk-assessment/application/risk-assessment.store";
import { PredictionModelImportComponent } from "./components/patient-risk-assessment/prediction-model-import/prediction-model-import.component";
import { PhotoBoothDialogComponent } from "./components/photo-booth-dialog/photo-booth-dialog.component";
import { PractitionerSelectorComponent } from "./components/practitioner-selector/practitioner-selector.component";
import { RelatedFormComponent } from "./components/related-form/related-form.component";
import { DateIntervalSelectorComponent } from "./components/shared/date-interval-selector/date-interval-selector.component";
import { SearchInputComponent } from "./components/shared/search-input/search-input.component";
import { UiAlertModule } from "./components/shared/ui-alert/ui-alert.module";
import { UiDateRangeMaxComponent } from "./components/shared/ui-date-range-max/ui-date-range-max.component";
import { UiFileDropModule } from "./components/shared/ui-file-drop/ui-file-drop.module";
import { UiScrollContainerComponent } from "./components/shared/ui-scroll-container/ui-scroll-container.component";
import { UiSeparatorModule } from "./components/shared/ui-separator/ui-separator.module";
import { ShowHidePasswordComponent } from "./components/show-hide-password/show-hide-password.component";
import { TimingEditorModule } from "./components/timing-editor/timing-editor.module";
import { TopMenuIconsComponent } from "./components/top-menu-icons/top-menu-icons.component";
import { TwoFAModalComponent } from "./components/two-famodal/two-famodal.component";
import { ValueArrayDialogComponent } from "./components/value-array-dialog/value-array-dialog.component";
import { WarningBoxModalComponent } from "./components/warning-box-modal/warning-box-modal.component";
import { WidgetActionsComponent } from "./components/widget-actions/widget-actions.component";
import { DndFilesModule } from "./directives/dnd-files/dnd-files.module";
import { NoHeaderTabsDirective } from "./directives/no-header-tabs.directive";
import { FileLogger } from "./helpers/fileLogger";
import { AccessGroupDialogComponent } from "./pages/access-groups-page/access-group-dialog/access-group-dialog.component";
import { AccessGroupComponent } from "./pages/access-groups-page/access-group/access-group.component";
import { AccessGroupsPageComponent } from "./pages/access-groups-page/access-groups-page.component";
import { GroupPermissionsComponent } from "./pages/access-groups-page/group-permissions/group-permissions.component";
import { PractitionerAccessGroupsDialogComponent } from "./pages/access-groups-page/practitioner-access-groups-dialog/practitioner-access-groups-dialog.component";
import { PractitionerAccessGroupsComponent } from "./pages/access-groups-page/practitioner-access-groups/practitioner-access-groups.component";
import { BillingModule } from "./pages/billing/billing.module";
import { CorrespondantPageComponent } from "./pages/correspondant-page/correspondant-page.component";
import { DataExtractionComponent } from "./pages/data-extraction/data-extraction.component";
import { ConfirmMailDialogComponent } from "./pages/delete-request-list-page/confirm-mail-dialog/confirm-mail-dialog.component";
import { DeleteRequestListPageComponent } from "./pages/delete-request-list-page/delete-request-list-page.component";
import { DrugSchemaPageComponent } from "./pages/drug-schema-page/drug-schema-page.component";
import { FhirQuestionnaireListPageComponent } from "./pages/fhir-questionnaire-list-page/fhir-questionnaire-list-page.component";
import { QuestionnaireHistoryComponent } from "./pages/fhir-questionnaire-list-page/questionnaire-history/questionnaire-history.component";
import { IdentityVerifMethodsPageComponent } from "./pages/identity-verif-methods-page/identity-verif-methods-page.component";
import { InstallComunicarePageComponent } from "./pages/install-comunicare-page/install-comunicare-page.component";
import { JournalInsiPageComponent } from "./pages/journal-insi-page/journal-insi-page.component";
import { DraftWarningDialogComponent } from "./pages/knowledge-details-page/draft-warning-dialog/draft-warning-dialog.component";
import { WhenInputModule } from "./pages/knowledge-details-page/knowledge-criteria/when-input/when-input.module";
import { KnowledgeDetailsPageComponent } from "./pages/knowledge-details-page/knowledge-details-page.component";
import { KnowledgeServicesComponent } from "./pages/knowledge-details-page/knowledge-services/knowledge-services.component";
import { MediaSelectorDialogComponent } from "./pages/knowledge-details-page/media-selector-dialog/media-selector-dialog.component";
import { KnowledgeListPipesModule } from "./pages/knowledge-list-page/knowledge-list-pipes/knowledge-list-pipes.module";
import { KnowledgeListPageModule } from "./pages/knowledge-list-page/knowledge-list.module";
import { KnowledgeComparisonDialogComponent } from "./pages/knowledge-media-editor/knowledge-comparison-dialog/knowledge-comparison-dialog.component";
import { KnowledgeMediaEditorComponent } from "./pages/knowledge-media-editor/knowledge-media-editor.component";
import { KnowledgeOverviewComponent } from "./pages/knowledge-media-editor/knowledge-overview/knowledge-overview.component";
import { SelectAccountModalComponent } from "./pages/login-page/select-account-modal/select-account-modal.component";
import { PatientListComponent } from "./pages/patient-list-page/patient-list/patient-list.component";
import { SimplePatientListPageComponent } from "./pages/patient-list-page/simple-patient-list-page/simple-patient-list-page.component";
import { PatientRegistrationPageComponent } from "./pages/patient-registration-page/patient-registration-page.component";
import { ChoiceInputComponent } from "./pages/questionnaire-editor-page/components/input-properties/choice-input/choice-input.component";
import { DateInputComponent } from "./pages/questionnaire-editor-page/components/input-properties/date-input/date-input.component";
import { InputPropertiesComponent } from "./pages/questionnaire-editor-page/components/input-properties/input-properties.component";
import { LinkInputComponent } from "./pages/questionnaire-editor-page/components/input-properties/link-input/link-input.component";
import { NumberInputComponent } from "./pages/questionnaire-editor-page/components/input-properties/number-input/number-input.component";
import { RatingInputComponent } from "./pages/questionnaire-editor-page/components/input-properties/rating-input/rating-input.component";
import { TextInputComponent } from "./pages/questionnaire-editor-page/components/input-properties/text-input/text-input.component";
import { TimeInputComponent } from "./pages/questionnaire-editor-page/components/input-properties/time-input/time-input.component";
import { UnitsValueSetComponent } from "./pages/questionnaire-editor-page/components/input-properties/units-value-set/units-value-set.component";
import { ValueSetComponent } from "./pages/questionnaire-editor-page/components/input-properties/value-set/value-set.component";
import { ItemDragDropComponent } from "./pages/questionnaire-editor-page/components/item-drag-drop/item-drag-drop.component";
import { PagePropertiesComponent } from "./pages/questionnaire-editor-page/components/page-properties/page-properties.component";
import { PagesEditorComponent } from "./pages/questionnaire-editor-page/components/pages-editor/pages-editor.component";
import { QuestionnaireParamsComponent } from "./pages/questionnaire-editor-page/components/questionnaire-properties/questionnaire-params/questionnaire-params.component";
import { QuestionnairePropertiesComponent } from "./pages/questionnaire-editor-page/components/questionnaire-properties/questionnaire-properties.component";
import { QuestionnaireScoringFormulaComponent } from "./pages/questionnaire-editor-page/components/questionnaire-properties/questionnaire-scoring-formula/questionnaire-scoring-formula.component";
import { QuestionnaireStartDateComponent } from "./pages/questionnaire-editor-page/components/questionnaire-properties/questionnaire-start-date/questionnaire-start-date.component";
import { UseContextComponent } from "./pages/questionnaire-editor-page/components/use-context/use-context.component";
import { ValueSetDialogComponent } from "./pages/questionnaire-editor-page/components/value-set-dialog/value-set-dialog.component";
import { ValueSetListDialogComponent } from "./pages/questionnaire-editor-page/components/value-set-list-dialog/value-set-list-dialog.component";
import { QuestionnaireEditorPageComponent } from "./pages/questionnaire-editor-page/questionnaire-editor-page.component";
import { MobileQuestionnaireListSettingsComponent } from "./pages/questionnaire-list-page/mobile-questionnaire-list-settings/mobile-questionnaire-list-settings.component";
import { QuizListPageComponent } from "./pages/quiz-list-page/quiz-list-page.component";
import { StatEventsPageComponent } from "./pages/statistics-pages/stat-events-page/stat-events-page.component";
import { StatSummaryPageComponent } from "./pages/statistics-pages/stat-summary-page/stat-summary-page.component";
import { TemplateQuestionnaireListPageComponent } from "./pages/template-questionnaire-list-page/template-questionnaire-list-page.component";
import { UserPageComponent } from "./pages/user-page/user-page.component";
import { FindObsDefByLoincPipe } from "./pipes/find-obs-def-by-loinc.pipe";
import { GetEffectiveTimingPipe } from "./pipes/get-effective-timing.pipe";
import { LoincAndValueMeaningPipe } from "./pipes/loinc-to-itranslation.pipe";
import { PipesModule } from "./pipes/pipes.module";
import { TranslateAlertNamePipe } from "./pipes/sharedAlerts.pipe";
import { PwaService } from "./providers/pwa.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    DashboardPageComponent,
    NotFoundPageComponent,
    GlobalErrorDisplayComponent,
    PatientListPageComponent,
    UserListPageComponent,
    ChangeLangComponent,
    UserInfosComponent,
    ItemSelectorComponent,
    ConfirmationDialogComponent,
    LangConflictDialogComponent,
    PatientPageComponent,
    TeleConsultationDialogComponent,
    PatientCareplansComponent,
    PatientObservationsComponent,
    ObservationChartComponent,
    PatientDrugsComponent,
    DrugDetailsComponent,
    RuleAlertComponent,
    CareplanComponent,
    AlertDetailsComponent,
    AlertDetailsEditComponent,
    AlertDetailsHistoricComponent,
    PatientVitalSignsComponent,
    VitalSignsHistoricComponent,
    PatientQRComponent,
    QRDetailsComponent,
    TeleconsultationsPageComponent,
    PatientTeleconsultationsComponent,
    PatientTeleconsultationDialogComponent,
    PatientVitalSignsComponent,
    ServerSideSearchComponent,
    InitPwdComponent,
    QRValuePossiblesComponent,
    QuestionnaireScoringGraphComponent,
    OrganizationListPageComponent,
    OrganizationComponent,
    UserListDialogComponentComponent,
    ServiceDetailsComponentComponent,
    AddOrganizationComponent,
    PreferenceDialogComponent,
    AddServiceComponent,
    GlobalHelpDialogComponent,
    MyServicePageComponent,
    ReleaseNoteDialogComponent,
    AddToGroupDialogComponent,
    GroupServerSideSearchComponent,
    AnswerNewQrComponent,
    ListOfPracQRComponent,
    GroupsListPageComponent,
    AddGroupComponent,
    ErrorPageComponent,
    EditAnonymousPatientComponent,
    QuestionnaireListPageComponent,
    ScoreDetailsComponent,
    DrugAddComponent,
    DrugsHistoricComponent,
    AddCommunicationComponent,
    CommunicationsPageComponent,
    CommunicationOverviewComponent,
    PatientCommunicationsComponent,
    PatientRiskAssessmentComponent,
    RiskAssessmentDetailsComponent,
    RiskAssessmentChartComponent,
    GroupSearchOnlyComponent,
    RewardsPageComponent,
    RewardsDialogComponent,
    PatientRewardComponent,
    RewardDetailsEditComponent,
    RewardDetailsHistoricComponent,
    RewardDetailsComponent,
    TemplateQuestionnaireListPageComponent,
    FhirQuestionnaireListPageComponent,
    QuestionnaireEditorPageComponent,
    QuestionnairePropertiesComponent,
    PagesEditorComponent,
    PagePropertiesComponent,
    InputPropertiesComponent,
    ItemDragDropComponent,
    ValueSetDialogComponent,
    ValueSetListDialogComponent,
    PatientAlreadyExistsDialogComponent,
    ChooseInsuranceDialogComponent,
    ConfirmResetPasswordDialogComponent,
    ErrorGeneratePasswordComponent,
    ErrorOnGettingCaremateIDComponent,
    DisplayAuthcodeConfirmationComponent,
    DisplayEnvironementDialogComponent,
    KnowledgeDetailsPageComponent,
    KnowledgeMediaEditorComponent,
    MediaSelectorDialogComponent,
    KnowledgeCreationDialogComponent,
    DraftWarningDialogComponent,
    AddDrugSchemaComponent,
    PauseDrugSchemaComponent,
    DrugSchemaPageComponent,
    DrugCycleComponent,
    DrugsCalendarComponent,
    AlertPreferenceComponent,
    PatientDisableDialogComponent,
    PatientHistoricDialogComponent,
    KnowledgeOverviewComponent,
    NoHeaderTabsDirective,
    KnowledgeComparisonDialogComponent,
    ValueArrayDialogComponent,
    VitalSignFormComponent,
    VitalSignFormInputComponent,
    QuestionnaireParamsComponent,
    ChoiceInputComponent,
    DateInputComponent,
    NumberInputComponent,
    RatingInputComponent,
    TextInputComponent,
    TimeInputComponent,
    UnitsValueSetComponent,
    ValueSetComponent,
    QuizListPageComponent,
    DisplayPicturesComponent,
    DeleteRequestListPageComponent,
    ConfirmMailDialogComponent,
    WarningBoxModalComponent,
    MfaSetupComponent,
    TwoFAModalComponent,
    ObservationDefinitionListComponent,
    MomentObservationTableComponent,
    HourlyObservationTableComponent,
    DrugCommentComponent,
    PatientDocumentsComponent,
    DocumentModalComponent,
    AccessGroupsPageComponent,
    GroupPermissionsComponent,
    AccessGroupComponent,
    AccessGroupDialogComponent,
    PractitionerAccessGroupsComponent,
    PractitionerAccessGroupsDialogComponent,
    UserPageComponent,
    NoColumnInfoComponent,
    AnsPractitionnerResultComponent,
    TownServerSideSearchComponent,
    IdentityStatusChangeHistoricComponent,
    CorrespondantPageComponent,
    StatSummaryPageComponent,
    StatEventsPageComponent,
    JournalInsiPageComponent,
    ResetOwnPasswordComponent,
    SelectAccountModalComponent,
    AddStepwiseDrugComponent,
    ShowHidePasswordComponent,
    KnowledgeServicesComponent,
    SimplePatientListPageComponent,
    PatientListComponent,
    CreateStepwiseComponent,
    ObservationStatsContainerComponent,
    StatCardComponent,
    StatLinechartComponent,
    StatStackedBarplotChartComponent,
    StatTableComponent,
    ObservationStatsComponent,
    ObservationStatsContainerComponent,
    DefaultObservationTableComponent,
    SmallObservationTableComponent,
    AppointmentDelayComponent,
    AppointmentDelayDialogComponent,
    DateIntervalSelectorComponent,
    PractitionerSelectorComponent,
    IdentityVerifMethodsPageComponent,
    ObservationAlertsHistoryModalComponent,
    QuestionnaireHistoryComponent,
    UiScrollContainerComponent,
    QuestionnaireScoringFormulaComponent,
    DataExtractionComponent,
    TopMenuIconsComponent,
    CareplansListPageComponent,
    UseContextComponent,
    QuestionnaireStartDateComponent,
    EncodeObservationsComponent,
    PredictionModelImportComponent,
    KnowledgeMediaListModalComponent,
    PdfTypeChoiceComponent,
    AddDrugIntakeDialogComponent,
    DrugGraphComponent,
    UiDateRangeMaxComponent,
    StreamObsChartComponent,
    StreamObsStatChartComponent,
    PatientObservationsStatCardComponent,
    LinkInputComponent,
    DrugStockComponent,
    OrgServiceSelectionComponent,
    WidgetActionsComponent,
    MirGraphModalComponent,
    PhotoBoothDialogComponent,
    InstallComunicarePageComponent,
    DrugExpansionPanelComponent,
    SearchInputComponent,
    MobileQuestionnaireListSettingsComponent,
    PatientRegistrationPageComponent,
    PatientFormComponent,
    RelatedFormComponent,
  ],
  imports: [
    BrowserModule,
    I18nModule,
    CareplanEditorPageModule, // Module containing specific routing must be imported before AppRoutingModule or the wild card ** will be triggered before the paths defined in that module.
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    DragDropModule,
    MatCheckboxModule,
    ItemFilterModule,
    FiltersDisplayModule,
    PatientInfoModule,
    MatDialogModule,
    FormsData,
    MatTooltipModule,
    UserHelper,
    WidgetModule,
    MatSlideToggleModule,
    MatSliderModule,
    ChartsModule,
    MatSnackBarModule,
    MatTabsModule,
    MatStepperModule,
    NgxMatSelectSearchModule,
    MatGridListModule,
    HelpData,
    MatAutocompleteModule,
    MatRadioModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule,
    JoditAngularModule,
    MatChipsModule,
    ClipboardModule,
    KnowledgeListPipesModule,
    PipesModule,
    MatMenuModule,
    MatChipsModule,
    NgxViewerjsModule,
    NgxMatMomentModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MdePopoverModule,
    MatChipsModule,
    MatButtonToggleModule,
    PatientObservationsPipesModule,
    KnowledgeListPageModule,
    WhenInputModule,
    DndFilesModule,
    DrugServerSideSearchModule,
    UiAlertModule,
    TimingEditorModule,
    UiFileDropModule,
    MatBadgeModule,
    UiSeparatorModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production || environment.envName === "dev" || environment.envName === "test",
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    BillingModule,
  ],
  providers: [
    ApiService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18N },

    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    CookieService,
    TranslateObservationNamePipe,
    TileManager,
    TranslateAlertNamePipe,
    LoincAndValueMeaningPipe,
    GetEffectiveTimingPipe,
    FindObsDefByLoincPipe,
    MatIconRegistry,
    FileLogger,
    DecimalPipe,
    DatePipe,
    RiskAssessmentStore,
    PwaService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
