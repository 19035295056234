import { CdkDrag } from "@angular/cdk/drag-drop";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { IPrescription, RENEW_TIMING } from "src/app/models/prescription.interface";
import { IWidgetComponent } from "src/app/models/widget.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PrescriptionService } from "src/app/providers/prescription.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { PrescriptionsToBeRenewedDataSource } from "./renewable-prescriptions-widget.datasource";

export interface TableRow {
  name: string;
  category: string;
  date: string;
  frequency: string;
  membership: string;
  action: string;
}

@Component({
  selector: "app-renewable-prescriptions-widget",
  templateUrl: "./renewable-prescriptions-widget.component.html",
  styleUrls: ["./renewable-prescriptions-widget.component.scss"],
})
export class RenewablePrescriptionsWidgetComponent implements IWidgetComponent, OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selectedTiming: RENEW_TIMING = RENEW_TIMING.WEEK;

  renewalTimings = [
    { value: RENEW_TIMING.WEEK, label: "prescription.timing.week" },
    { value: RENEW_TIMING.TWO_WEEKS, label: "prescription.timing.twoWeeks" },
    { value: RENEW_TIMING.MONTH, label: "prescription.timing.month" },
    { value: RENEW_TIMING.END_OF_MONTH, label: "prescription.timing.endOfMonth" },
  ];
  public renewablePrescriptions: number;

  isDraggable: boolean;
  cdkDrag: CdkDrag<unknown>;

  displayedColumns: string[] = ["patient", "category", "date", "frequency", "adherence"];

  public dataSource: PrescriptionsToBeRenewedDataSource;
  public renewablePredictions: IPrescription[] = [];
  private onDestroy$ = new Subject<void>();
  public currentPageSize: number;
  public renewablePrescriptionsCount: number;
  private healthcareServicesRefs: string[];
  public isLoading = true;

  constructor(
    private prescriptionService: PrescriptionService,
    public responsiveService: ResponsiveService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService
  ) {
    this.currentPageSize = 5;
  }

  ngOnInit(): void {
    this.dataSource = new PrescriptionsToBeRenewedDataSource(this.prescriptionService);

    // Subscribe to the services setup watcher (emits when services are initialized or change)
    this.sessionService.servicesSetupWatch.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.updateHealthcareServicesRefsAndLoad();
    });

    // If services are already available when the component is initialized, load data immediately
    if (this.sessionService.currentService) {
      this.updateHealthcareServicesRefsAndLoad();
    }
  }

  private updateHealthcareServicesRefsAndLoad(): void {
    if (this.sessionService.currentService.reference === "all") {
      // If "all" services are selected, get all available service references
      this.healthcareServicesRefs = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    } else {
      // Otherwise, only use the current service reference
      this.healthcareServicesRefs = [this.sessionService.currentService?.reference];
    }
    this.loadRenewablePrescriptions(); // Trigger loading of renewable prescriptions
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((_page: PageEvent) => {
      this.currentPageSize = this.paginator.pageSize;
      this.loadRenewablePrescriptions();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadRenewablePrescriptions() {
    this.prescriptionService
      .getPrescriptionsToBeRenewedCount(this.selectedTiming, this.healthcareServicesRefs?.length ? this.healthcareServicesRefs : undefined)
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.renewablePrescriptionsCount = result?.count;
        this.dataSource.loadData({
          pageNumber: 0,
          pageSize: this.currentPageSize,
          filters: [],
          timing: this.selectedTiming,
          services: this.healthcareServicesRefs,
        });

        this.isLoading = false;
      });
  }

  public onTimingChange(_event: MatOptionSelectionChange): void {
    this.loadRenewablePrescriptions();
  }

  public encodeToB64(str: string): string {
    return Base64Helper.utf8_to_b64(str);
  }
}
