import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Tools } from "src/app/helpers/tools";
import { IPostPatientAdditionalData } from "src/app/models/patient.interface";
import { ChooseInsuranceDialogComponent } from "./choose-insurance-dialog/choose-insurance-dialog.component";

@Component({
  selector: "app-patient-already-exists-dialog",
  templateUrl: "./patient-already-exists-dialog.component.html",
  styleUrls: ["./patient-already-exists-dialog.component.scss"],
})
export class PatientAlreadyExistsDialogComponent implements OnInit {
  public isOtherOrg: boolean;
  public userOtherOrg: {
    mailAlreadyUsed?: boolean;
    wrongMail?: boolean;
    verifyNamesAndSexe?: boolean;
  };
  public isPractitioner: boolean;
  public usersFound: {
    sameEmail: boolean;
    isRelated: boolean;
    existingPatient: any;
    isSelected?: boolean;
  }[] = [];
  public indexSelected = 0;
  public relatedRegistration = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PatientAlreadyExistsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const additionalData: IPostPatientAdditionalData[] = this.data.additionalData;
    this.isPractitioner = additionalData[0].isPractitioner;
    const isSameOrg = additionalData.findIndex((ad) => ad.sameOrganization === true) !== -1;
    if (isSameOrg) {
      additionalData.forEach((user) => {
        if (user.sameOrganization || user.isRelated) {
          this.usersFound.push({
            existingPatient: user.existingPatient,
            isRelated: user.isRelated,
            sameEmail: user.existingPatient.mail === this.data.mail,
          });
        }
      });
      this.usersFound[this.indexSelected].isSelected = true;
    } else {
      const isOtherOrg = additionalData.findIndex((ad) => !ad.sameOrganization && !ad.isRelated) !== -1;
      if (isOtherOrg) {
        const otherOrgPatients = additionalData.filter((ad) => !ad.sameOrganization && !ad.isRelated);
        if (otherOrgPatients?.length > 1) {
          // multiple users found in differents irg, mail or identifier already used
          this.userOtherOrg = {
            mailAlreadyUsed: true,
          };
        } else {
          // one user found in other org
          this.userOtherOrg = {
            mailAlreadyUsed: otherOrgPatients[0].mailAlreadyUsed,
            wrongMail: otherOrgPatients[0].wrongMail,
            verifyNamesAndSexe: otherOrgPatients[0].verifyNamesAndSexe,
          };
        }
      } else {
        const isRelated = additionalData.findIndex((ad) => ad.isRelated === true) !== -1;
        if (isRelated) {
          this.usersFound.push({
            existingPatient: additionalData[0].existingPatient,
            isRelated: additionalData[0].isRelated,
            sameEmail: additionalData[0].existingPatient.mail === this.data.mail,
          });
        }
      }
    }
    this.isOtherOrg = Tools.isDefined(this.userOtherOrg);
    this.relatedRegistration = additionalData[0]?.relatedRegistration;
  }

  public validate(): void {
    if (
      this.usersFound[this.indexSelected].existingPatient.requestChangeInsurance ||
      this.usersFound[this.indexSelected].existingPatient.requestChangeAddress
    ) {
      // need to choose the insurance and/or address
      const dialogRef = this.dialog.open(ChooseInsuranceDialogComponent, {
        disableClose: true,
        data: {
          oldIns: this.usersFound[this.indexSelected].existingPatient.requestChangeInsurance
            ? this.usersFound[this.indexSelected].existingPatient.insurance
            : undefined,
          newIns: this.usersFound[this.indexSelected].existingPatient.requestChangeInsurance ? this.data.insuranceRef.display : undefined,
          updateAddress: this.usersFound[this.indexSelected].existingPatient.requestChangeAddress,
        },
        panelClass: "dialog-container-scroll",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.dialogRef.close({
            forceUpdateUser: this.usersFound[this.indexSelected].existingPatient.mail,
            needChangeInsurance: result.needChangeInsurance,
            needChangeAddress: result.needChangeAddress,
          });
        }
      });
    } else {
      this.dialogRef.close({
        forceUpdateUser: this.usersFound[this.indexSelected].existingPatient.mail,
      });
    }
  }

  public closeAll() {
    this.dialog.closeAll();
  }

  public sendMail() {
    const mailText = "mailto:contact@comunicare.be?subject=Patient already exists in a different organization"; // add the links to body
    window.location.href = mailText;
  }
}
