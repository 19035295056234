<div class="ui-dialog-container" style="width: 100%">
  <div class="ui-dialog-content" style="display: flex; justify-content: center">
    <form [formGroup]="relatedForm">
      <h2>
        {{ !isCreation ? ("forms.updateRelatedTitle" | translate) : ("forms.createRelatedTitle" | translate) }}
        &nbsp;
      </h2>

      <div class="spinner-container" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
      <ng-container *ngIf="!isLoading">
        <div>
          <!-- SURNAME -->
          <mat-form-field>
            <input
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.number], 'name')"
              maxlength="100"
              cdkFocusInitial
              type="text"
              matInput
              [placeholder]="'forms.name' | translate"
              formControlName="name"
              required
              [errorStateMatcher]="matcher"
            />
          </mat-form-field>
          <!-- FIRSTNAME -->
          <mat-form-field>
            <input
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.number], 'firstname')"
              maxlength="100"
              type="text"
              matInput
              [placeholder]="'forms.firstname' | translate"
              formControlName="firstname"
              required
              [errorStateMatcher]="matcher"
            />
          </mat-form-field>
          <br />

          <!-- BIRTHDATE -->
          <mat-form-field>
            <mat-label>{{ "forms.birthdate" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="birthDate"
              [max]="maxDate"
              formControlName="birthDate"
              placeholder="{{ 'forms.birthdateFormat' | translate }}"
              required
              [errorStateMatcher]="matcher"
            />
            <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
            <mat-datepicker #birthDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <!-- PHONE -->
          <mat-form-field>
            <input
              (keypress)="preventCharacter($event, [PREVENTCHARACTER.letter, PREVENTCHARACTER.specialCharExceptPlus], 'phone')"
              type="phone"
              matInput
              placeholder="{{ 'forms.phone' | translate }}"
              formControlName="phone"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-error *ngIf="relatedForm.get('phone').hasError('phoneValidator')">
              {{ "forms.checkPhoneFormat" | translate }}
            </mat-error>
          </mat-form-field>

          <!-- MAIL -->
          <mat-form-field>
            <input
              type="mail"
              matInput
              placeholder="{{ 'forms.mail' | translate }}"
              formControlName="mail"
              (click)="resetEmailAlreadyTaken()"
              required
              [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="!relatedForm.get('mail').errors?.emailAlreadyTaken">{{ "page.login.email-required" | translate }}</mat-error>
            <mat-error *ngIf="!relatedForm.get('mail').errors?.email && relatedForm.get('mail').errors?.emailAlreadyTaken">
              {{ "forms.emailAlreadyTaken" | translate }}</mat-error
            >
          </mat-form-field>

          <ui-alert class="mt-1 mb-0" [options]="{ type: 'warning' }" *ngIf="relatedForm.get('mail').errors?.emailAlreadyTaken">
            {{ "forms.samePerson" | translate }}
            <a [routerLink]="" (click)="sendMail()" class="link">{{ "forms.byClickingHere" | translate }}</a>
          </ui-alert>
          <br />

          <div formGroupName="relatedPermissions" style="margin-bottom: 10px">
            <div formGroupName="careplan" style="display: flex">
              <p style="width: 30%">{{ "forms.relatedPermissions.careplan" | translate }}</p>
              <mat-checkbox formControlName="view" style="margin-left: 10px">{{
                "forms.relatedPermissions.view" | translate
              }}</mat-checkbox>
            </div>
            <div formGroupName="agenda" style="display: flex">
              <p style="width: 30%">{{ "forms.relatedPermissions.agenda" | translate }}</p>
              <mat-checkbox formControlName="view" style="margin-left: 10px">{{
                "forms.relatedPermissions.view" | translate
              }}</mat-checkbox>
            </div>
            <div formGroupName="drug" style="display: flex">
              <p style="width: 30%">{{ "forms.relatedPermissions.drug" | translate }}</p>
              <mat-checkbox formControlName="view" style="margin-left: 10px">{{
                "forms.relatedPermissions.view" | translate
              }}</mat-checkbox>
              <mat-checkbox formControlName="edit" style="margin-left: 10px">{{
                "forms.relatedPermissions.edit" | translate
              }}</mat-checkbox>
            </div>
            <div formGroupName="observation" style="display: flex">
              <p style="width: 30%">{{ "forms.relatedPermissions.observation" | translate }}</p>
              <mat-checkbox formControlName="view" style="margin-left: 10px">{{
                "forms.relatedPermissions.view" | translate
              }}</mat-checkbox>
              <mat-checkbox formControlName="edit" style="margin-left: 10px">{{
                "forms.relatedPermissions.edit" | translate
              }}</mat-checkbox>
            </div>
            <div formGroupName="questionnaire" style="display: flex">
              <p style="width: 30%">{{ "forms.relatedPermissions.questionnaire" | translate }}</p>
              <mat-checkbox formControlName="view" style="margin-left: 10px">{{
                "forms.relatedPermissions.view" | translate
              }}</mat-checkbox>
              <mat-checkbox formControlName="edit" style="margin-left: 10px">{{
                "forms.relatedPermissions.edit" | translate
              }}</mat-checkbox>
            </div>
            <div formGroupName="usefulLinks" style="display: flex">
              <p style="width: 30%">{{ "forms.relatedPermissions.usefulLinks" | translate }}</p>
              <mat-checkbox formControlName="view" style="margin-left: 10px">{{
                "forms.relatedPermissions.view" | translate
              }}</mat-checkbox>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="ui-dialog-actions" style="float: right; margin-top: 10px">
    <button mat-raised-button *ngIf="isCreation" color="primary" (click)="apply(true)" style="margin-right: 5px">
      {{ "forms.relatedPermissions.newRelated" | translate }}
    </button>
    <button *ngIf="isCreation" [disabled]="saveInProgress" mat-raised-button color="primary" (click)="apply()">
      {{ (patientRegistrationService.isLastStep ? "model.patient.finish" : "globalHelp.nextSlide") | translate }}
    </button>
    <button mat-raised-button *ngIf="!isCreation" color="primary" [disabled]="saveInProgress" (click)="apply()">
      {{ "forms.updateUser" | translate }}
    </button>
    <button
      mat-raised-button
      *ngIf="!isCreation"
      color="primary"
      [disabled]="saveInProgress"
      style="margin-left: 10px"
      (click)="apply(false, false, true)"
    >
      {{ "forms.updateAndClose" | translate }}
    </button>
  </div>
</div>
