import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { Filter } from "src/app/models/filter.interface";
import { ApiService } from "src/app/providers/api/api.service";
import { BaseApiService } from "src/app/providers/api/baseApi.service";
import { IChargeItemsInfo } from "../billing.interface";

@Injectable({
  providedIn: "root",
})
export class BillingApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Invoice", // Theme !
      [], // Post route paths
      ["dashboard/chargeItemsInfos", "dashboard/nbChargeItemsInfos"],
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public findInvoices(params: DataSourceBackendParam): Observable<IChargeItemsInfo[]> {
    return this.api.get(this.readRoutes[0], {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      search: params.search,
      sortId: params.sortId,
      sortOrder: params.sortOrder,
      servicesIds: params.services,
      filters: JSON.stringify(params.filters),
    }) as Observable<IChargeItemsInfo[]>;
  }

  public getInvoicesCount(services?: string[], search?: string, filters?: Filter[]): Observable<{ count: number }> {
    const params: any = {
      search,
    };

    if (services?.length) {
      params.servicesIds = services;
    }
    if (filters?.length) {
      params.filters = JSON.stringify(filters);
    }

    return this.api.get(this.readRoutes[1], params) as Observable<{ count: number }>;
  }
}
