import { Identifier } from "../models/identifier.interface";
import { Reference } from "../models/reference.interface";

export const OBSERVATION_LOINC_MAP_CONVERTOR = {
  1: "8310-5", // temperature
  2: "55284-4", // blood pressure
  3: "8867-4", // heart rate
  4: "3141-9", // body weight
};

export class FHIRHelper {
  public static get USUAL(): string {
    return "usual";
  }
  public static get SYSTEM_COMUNICARE(): string {
    return "http://comunicare.io";
  }
  public static get SYSTEM_SNOMED(): string {
    return "http://snomed.info/sct";
  }
  public static get SYSTEM_LOINC(): string {
    return "http://loinc.org";
  }
  public static get OLD_SYSTEM_CAREMATE(): string {
    return "caremate";
  }
  public static get SYSTEM_TELECOM_MAIL(): string {
    return "email";
  }
  public static get SYSTEM_TELECOM_PHONE(): string {
    return "phone";
  }
  public static get SYSTEM_TELECOM_MSSANTE(): string {
    return "msSante";
  }
  public static get SYSTEM_CONTACT_INSURANCE(): string {
    return "ins";
  }
  public static get SYSTEM_SOCIAL_SECURITY_IDENTIFIER_NUMBER(): string {
    return "SSIN";
  }
  public static get SYSTEM_ORGANIZATION(): string {
    return "organization";
  }
  public static get USE_ANS(): string {
    return "ANS";
  }
  public static get USE_MATRICULE_INS(): string {
    return "matriculeINS";
  }
  public static get SYSTEM_MATRICULE_INS(): string {
    return "https://esante.gouv.fr/produits-services/referentiel-ins";
  }
  public static get SYSTEM_TYPE_MATRICULE_INS(): string {
    return "https://esante.gouv.fr/actualites/ins-publication-des-oid-des-autorites-daffectation";
  }
  public static get SYSTEM_CAREPLAN_ACTIVITY_CATEGORY(): string {
    return "http://hl7.org/fhir/care-plan-activity-category";
  }

  // SNOMED codes

  public static get SNOMED_TELECONSULTATION(): string {
    return "448337001";
  }

  // LOINC observation codes
  public static get CODE_TEMPERATURE(): string {
    return "8310-5";
  }
  public static get CODE_BLOODPRESURE(): string {
    return "55284-4";
  }
  public static get CODE_BP_DIASTOLIC(): string {
    return "8462-4";
  }
  public static get CODE_BP_SYSTOLIC(): string {
    return "8480-6";
  }
  public static get CODE_BODYWEIGHT(): string {
    return "3141-9";
  }
  public static get CODE_HEARTRATE(): string {
    return "8867-4";
  }
  public static get CODE_NEUROPATHY(): string {
    return "62795-0";
  }
  public static get CODE_FATIGUE(): string {
    return "28100-6";
  }
  public static get CODE_PAIN(): string {
    return "72514-3";
  }
  public static get CODE_PAIN_B(): string {
    return "3044-6";
  }
  public static get CODE_PAIN_MM(): string {
    return "72514-3-MM";
  }
  public static get CODE_PAIN_IC(): string {
    return "72514-3-IC";
  }
  public static get CODE_GONFLEMENT_IC(): string {
    return "86480-1-cardio";
  }
  public static get CODE_GONFLEMENT(): string {
    return "86480-1";
  }
  public static get CODE_GONFLEMENT_A(): string {
    return "86480-1-A";
  }
  public static get CODE_GONFLEMENT_B(): string {
    return "86480-1-B";
  }
  public static get CODE_GONFLEMENT_CARDIO(): string {
    return "86480-1-cardio";
  }
  public static get CODE_GONFLEMENT_MM(): string {
    return "86480-1-MM";
  }
  public static get CODE_DYSPNEA_IC(): string {
    return "54564-0-IC";
  }
  public static get CODE_DYSPNEA(): string {
    return "54564-0";
  }
  public static get CODE_BPCOQ(): string {
    return "bpcoQ";
  }
  public static get CODE_BPCOQ_A(): string {
    return "49727002";
  }
  public static get CODE_BPCOQ_B(): string {
    return "248595008";
  }
  public static get CODE_BPCOQ_C(): string {
    return "365445003";
  }
  public static get CODE_BPCOQ_D(): string {
    return "267036007";
  }
  public static get CODE_BPCOQ_E(): string {
    return "275723000";
  }
  public static get CODE_SPO2(): string {
    return "20564-1";
  }
  public static get CODE_DY_COVID(): string {
    return "54564-0-covid-19";
  }
  public static get CODE_DY_COVID_A(): string {
    return "54564-0-covid-19a";
  }
  public static get CODE_DY_COVID_B(): string {
    return "54564-0-covid-19b";
  }
  public static get CODE_COUGH_COVID(): string {
    return "64145-6-covid-19";
  }
  public static get CODE_COUGH(): string {
    return "64145-6";
  }
  public static get CODE_BT_MM(): string {
    return "8310-5-MM";
  }
  public static get CODE_REDNESS(): string {
    return "66530-7";
  }
  public static get CODE_CHILLS(): string {
    return "69710-2";
  }
  public static get CODE_ACTIVITY_DURATION(): string {
    return "55411-3";
  }
  public static get CODE_WALKING_DISTANCE(): string {
    return "41953-1";
  }
  public static get CODE_MOOD(): string {
    return "80296-7";
  }
  public static get CODE_GLUCOSE(): string {
    return "2339-0";
  }
  public static get CODE_INJECTION_UNIT(): string {
    return "20448-7";
  }

  // Practitioner roles
  public static get ROLE_PHYSICIAN(): string {
    return "309343006";
  }
  public static get ROLE_NURSE(): string {
    return "106292003";
  }
  public static get ROLE_SECRETARY(): string {
    return "394572006";
  }
  public static get ROLE_PHARMACIST(): string {
    return "46255001";
  }
  public static get ROLE_OTHER(): string {
    return "394738000";
  }
  public static get GENDER_MALE(): string {
    return "male";
  }
  public static get GENDER_FEMALE(): string {
    return "female";
  }
  public static get GENDER_FEMALE_SHORT(): string {
    return "F";
  }
  public static get GENDER_MALE_SHORT(): string {
    return "M";
  }

  public static get RES_QUESTIONNAIRE(): string {
    return "Questionnaire";
  }
  public static get RES_QUESTIONNAIRERESPONSE(): string {
    return "QuestionnaireResponse";
  }

  public static get GOAL_CATEGORY_CARDIO(): string {
    return "cardio-frequency";
  }

  /**
   * Return main identifier
   */
  public static getMainIdentifier(entity: any, firstAsDefault?: boolean): Identifier | null {
    if (entity && entity.identifier) {
      for (const identifier of entity.identifier) {
        if (identifier.system === FHIRHelper.SYSTEM_COMUNICARE || identifier.system === "caremate") {
          return identifier;
        }
      }
      // not found ? possible with practitioner who are not user
      if (firstAsDefault && entity.identifier.length) {
        return entity.identifier[0];
      }
    }
    return null;
  }

  /**
   * Return insurance identifier
   */
  public static getInsIdentifier(entity: any, firstAsDefault?: boolean): Identifier | null {
    for (const identifier of entity.identifier) {
      if (identifier.use === FHIRHelper.SYSTEM_CONTACT_INSURANCE || identifier.system === FHIRHelper.SYSTEM_CONTACT_INSURANCE) {
        return identifier;
      }
    }
    // not found ? possible with practitioner who are not user
    if (firstAsDefault && entity.identifier.length) {
      return entity.identifier[0];
    }
    return null;
  }

  /**
   * Return insurance identifier
   */
  public static getOrganizationIdentifier(entity: any, firstAsDefault?: boolean): Identifier | null {
    if (entity && entity.identifier) {
      for (const identifier of entity.identifier) {
        if (identifier.use === FHIRHelper.SYSTEM_ORGANIZATION) {
          return identifier;
        }
      }
      // not found ? possible with practitioner who are not user
      if (firstAsDefault && entity.identifier.length) {
        return entity.identifier[0];
      }
    }
    return null;
  }

  /**
   * Convert Identifier to Reference
   */
  public static identifier2reference(identifier: Identifier): Reference {
    return {
      reference: identifier.system,
      display: identifier.label ? identifier.label : identifier.value,
    };
  }

  public static getAnsId(identifiers: Identifier[]): Identifier | null {
    if (identifiers && identifiers.length) {
      for (const identifier of identifiers) {
        if (identifier.use === FHIRHelper.USE_ANS) {
          return identifier;
        }
      }
    }
    return null;
  }
}
