import { BreakpointObserver } from "@angular/cdk/layout";
import { AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { first, map, takeUntil } from "rxjs/operators";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { Tools } from "src/app/helpers/tools";
import { Patient, PatientUser } from "src/app/models/patient.interface";
import { IRelatedPerson } from "src/app/models/related.interface";
import { PatientService } from "src/app/providers/patient.service";
import { patientRegistrationService } from "src/app/providers/patientRegistration.service";
import { RelatedService } from "src/app/providers/related.service";

@Component({
  selector: "app-patient-registration-page",
  templateUrl: "./patient-registration-page.component.html",
  styleUrls: ["./patient-registration-page.component.scss"],
})
export class PatientRegistrationPageComponent implements OnInit, OnDestroy, AfterViewInit {
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  public stepperOrientation;
  public isLoading = true;
  public isCreation = true;
  public patient: PatientUser;
  public source: string;
  public timeline = [];
  public go2patientPage: boolean;
  public relateds: IRelatedPerson[] = [];
  private directToCareplan: boolean;

  @ViewChild("stepper") private stepper: MatStepper;
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private relatedService: RelatedService,
    public patientRegistrationService: patientRegistrationService,
    private patientService: PatientService,
    private cdr: ChangeDetectorRef
  ) {
    // get the previous url to redirect back the user there
    this.router.events
      .pipe(
        first(),
        map(() => this.router.getCurrentNavigation().previousNavigation?.finalUrl?.toString())
      )
      .subscribe((previousUrl) => {
        this.patientRegistrationService.previousUrl = previousUrl;
      });
    this.isCreation = this.router.getCurrentNavigation()?.extras?.state?.mode !== FORMS_MODE.UPDATE;
    this.patient = this.router.getCurrentNavigation()?.extras?.state?.patient || false;
    this.source = this.router.getCurrentNavigation()?.extras?.state?.source;

    this.directToCareplan = this.router.getCurrentNavigation()?.extras?.state?.directToCareplan || false;
    if (this.directToCareplan) {
      this.patientRegistrationService.patientUser = this.router.getCurrentNavigation()?.extras?.state?.patientUser;
      this.patientRegistrationService.careplan = this.router.getCurrentNavigation()?.extras?.state?.careplan;
      this.patientRegistrationService.careplans = this.router.getCurrentNavigation()?.extras?.state?.careplans;
      this.patientRegistrationService.createNewCareplan = this.router.getCurrentNavigation()?.extras?.state?.createNewCareplan;
      this.patientRegistrationService.includeBirthdateAndGenderForm =
        this.router.getCurrentNavigation()?.extras?.state?.includeBirthdateAndGenderForm;
      this.patientRegistrationService.registration = this.router.getCurrentNavigation()?.extras?.state?.registration;
      this.patientRegistrationService.healthcareService = this.router.getCurrentNavigation()?.extras?.state?.healthcareService;
    }

    const breakpointObserver = inject(BreakpointObserver);

    this.stepperOrientation = breakpointObserver
      .observe("(min-width: 800px)")
      .pipe(map(({ matches }) => (matches ? "horizontal" : "vertical")));
  }
  ngOnInit(): void {
    if (this.isCreation) {
      this.timeline = [
        {
          id: "patientRegistration",
          title: this.translateService.instant("forms.createPatientTitle"),
          subtitles: [],
        },
        {
          id: "careplan",
          title: this.translateService.instant("page.careplan.title"),
          subtitles: [],
        },
      ];
      if (this.directToCareplan) {
        this.timeline[0].subtitles.push(
          "✓ " +
            this.patientRegistrationService.patientUser?.patient.name[0].given +
            " " +
            this.patientRegistrationService.patientUser?.patient.name[0].family
        );
      } else {
        this.patientRegistrationService.onPatientCreated$
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((event: { patientSaved: Patient; forceUpdateUser?: string }) => {
            this.patientCreated(event);
          });
        this.patientRegistrationService.onRelatedCreated$.pipe(takeUntil(this.onDestroy$)).subscribe((event: IRelatedPerson) => {
          this.relatedCreated(event);
        });
        this.patientRegistrationService.onNeedReferentChanged$.pipe(takeUntil(this.onDestroy$)).subscribe((event: boolean) => {
          this.timelineWithReferent(event);
        });
        this.patientRegistrationService.onNextStep$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
          this.onNext();
        });
        this.patientRegistrationService.onNeedCareplanChanged$.pipe(takeUntil(this.onDestroy$)).subscribe((event: boolean) => {
          this.timelineWithCareplan(event);
        });
        this.patientRegistrationService.isCreation = this.isCreation;
        this.patientRegistrationService.source = this.source;
      }
      this.isLoading = false;
    } else {
      this.relatedService
        .getPatientRelateds(this.patient.user.caremateIdentifier)
        .pipe(first())
        .subscribe((relateds) => {
          if (relateds?.length) {
            this.relateds = relateds;
          }
        });
    }
  }
  ngAfterViewInit(): void {
    if (this.directToCareplan) {
      this.stepper.next();
      this.calculateLastStep();
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private patientCreated(event: { patientSaved: Patient; forceUpdateUser?: string }): void {
    this.patientRegistrationService.patientSaved = event.patientSaved;
    this.patientRegistrationService.isForceUpdateUser = Tools.isDefined(event.forceUpdateUser);
    const patientStep = this.timeline.find((step) => step.id === "patientRegistration");
    patientStep.subtitles.push("✓ " + event.patientSaved.name[0].given + " " + event.patientSaved.name[0].family);
  }

  private timelineWithReferent(needReferent: boolean): void {
    const timelineReferentIndex = this.timeline.findIndex((step) => step.id === "relatedRegistration");
    if (needReferent && timelineReferentIndex === -1) {
      // add referent at second place of the timeline
      this.timeline.splice(1, 0, {
        id: "relatedRegistration",
        title: this.translateService.instant("forms.createRelatedTitle"),
        subtitles: [],
      });
    }
    if (!needReferent && timelineReferentIndex !== -1) {
      // delete referent to the timeline
      this.timeline.splice(timelineReferentIndex, 1);
    }
    this.calculateLastStep();
  }

  private timelineWithCareplan(needCareplan: boolean): void {
    const timelineCareplanIndex = this.timeline.findIndex((step) => step.id === "careplan");
    if (needCareplan && timelineCareplanIndex === -1) {
      // add careplan to the timeline
      this.timeline.push({
        id: "careplan",
        title: this.translateService.instant("page.careplan.title"),
        subtitles: [],
      });
    }
    if (!needCareplan && timelineCareplanIndex !== -1) {
      // delete referent to the timeline
      this.timeline.splice(timelineCareplanIndex, 1);
    }
    this.calculateLastStep();
  }

  private relatedCreated(event: IRelatedPerson): void {
    const relatedStep = this.timeline.find((step) => step.id === "relatedRegistration");
    relatedStep.subtitles.push("✓ " + event.name.given.join(" ") + " " + event.name.family.join(" "));
  }

  public onNext(): void {
    let indexNextStep: number = this.stepper.selectedIndex + 1;
    if (this.timeline.findIndex((step) => step.id === "relatedRegistration") === -1) {
      // no related step in timeline, increment the index to skip the form
      indexNextStep++;
    }
    const patientSaved = this.patientRegistrationService.patientSaved;
    switch (indexNextStep) {
      case 1:
        this.router.navigate(["patientRegistration", "relateds"]);
        this.stepper.next();
        this.calculateLastStep();

        break;
      case 2: {
        this.isLoading = true;
        const id = this.patientService.getComunicareId(patientSaved);
        this.patientService
          .getPatientUser(id)
          .pipe(first())
          .subscribe((patientUser) => {
            this.patientRegistrationService.patientUser = patientUser;
            this.patientRegistrationService.registration = true;
            this.patientRegistrationService.healthcareService = patientSaved.healthcareservice[patientSaved.healthcareservice.length - 1]; // last service added to the patient
            this.router.navigate(["patientRegistration", "careplan"]);
            this.isLoading = false;
            this.stepper.next();
            this.calculateLastStep();
          });
        break;
      }
    }
  }

  private calculateLastStep(): void {
    this.patientRegistrationService.isLastStep = this.timeline.length - 1 === this.stepper.selectedIndex;
  }
}
