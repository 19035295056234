import moment from "moment";
import { Coding } from "../models/coding.interface";
import { Reference } from "../models/reference.interface";
import { IRelatedPerson } from "../models/related.interface";
import { ACTION_STATUS_ENTITY, STATUS_ENTITY } from "../models/sharedInterfaces";
import { FHIRHelper } from "./FHIRhelper";

/**
 * Helper class for Related Patient and Person
 */
export class RelatedHelper {
  // shared entity category
  public static get SHARE_CAREPLAN(): string {
    return "careplan";
  }
  public static get SHARE_DRUG(): string {
    return "drug";
  }
  public static get SHARE_AGENDA(): string {
    return "agenda";
  }
  public static get SHARE_OBSERATION(): string {
    return "observation";
  }
  public static get SHARE_FEELING(): string {
    return "feeling";
  }
  public static get SHARE_NOTE(): string {
    return "note";
  }
  public static get SHARE_USEFUL_LINKS(): string {
    return "usefulLinks";
  }

  // shared rights
  public static get SHARE_RIGHT_NOACCESS(): string {
    return "0";
  }
  public static get SHARE_RIGHT_READONLY(): string {
    return "1";
  } // for simple caregiver
  public static get SHARE_RIGHT_READWRITE(): string {
    return "2";
  }
  public static get SHARE_RIGHT_FULL(): string {
    return "3";
  } // for substitute

  /**
   * Check if related has read right on a category
   * @param related
   * @param shareRight
   */
  public static hasReadRightOn(related: IRelatedPerson, shareRight: string, checkActive = true): boolean {
    if (!related.active && checkActive) return false;
    const coding = related.relationship.find((rel) => rel.system === shareRight);
    if (coding !== undefined) return coding.code !== RelatedHelper.SHARE_RIGHT_NOACCESS;
    return false;
  }

  /**
   * Check if related has write right on a category
   * @param related
   * @param shareRight
   */
  public static hasWriteRightOn(related: IRelatedPerson, shareRight: string, checkActive = true): boolean {
    if (!related.active && checkActive) return false;
    const coding = related.relationship.find((rel) => rel.system === shareRight);
    if (coding !== undefined) return coding.code === RelatedHelper.SHARE_RIGHT_READWRITE;
    return false;
  }

  /**
   * Return related person phone number
   * @param related
   */
  public static getRelatedPhone(related: IRelatedPerson): string {
    try {
      if (!related.telecom || related.telecom.length === 0) return "";
      for (const telecom of related.telecom) {
        if (telecom.system === FHIRHelper.SYSTEM_TELECOM_PHONE) return telecom.value.trim();
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  /**
   * Return related person mail
   * @param related
   */
  public static getRelatedMail(related: IRelatedPerson): string {
    try {
      if (!related.telecom || related.telecom.length === 0) return "";
      for (const telecom of related.telecom) {
        if (telecom.system === FHIRHelper.SYSTEM_TELECOM_MAIL) return telecom.value.trim().toLocaleLowerCase();
      }
    } catch (err) {
      return null;
    }
  }

  /**
   *
   * @param firstname
   * @param lastname
   * @param mail
   * @param phone
   */
  public static asRelatedPerson(
    patient: Reference,
    firstname: string,
    lastname: string,
    mail: string,
    phone: string,
    birthdate?: string,
    relationship?: Coding[],
    isActive?: boolean
  ): IRelatedPerson {
    const related: IRelatedPerson = {
      _id: null,
      resourceType: "RelatedPerson",
      modified: moment().format(),
      entityStatus: [STATUS_ENTITY.LOCKED],
      actionStatus: ACTION_STATUS_ENTITY.CREATED,
      identifier: null,
      active: isActive ?? false,
      patient: patient,
      relationship: relationship?.length
        ? relationship
        : [
            {
              system: RelatedHelper.SHARE_CAREPLAN,
              code: RelatedHelper.SHARE_RIGHT_NOACCESS,
            },
            {
              system: RelatedHelper.SHARE_DRUG,
              code: RelatedHelper.SHARE_RIGHT_NOACCESS,
            },
            {
              system: RelatedHelper.SHARE_AGENDA,
              code: RelatedHelper.SHARE_RIGHT_NOACCESS,
            },
            {
              system: RelatedHelper.SHARE_OBSERATION,
              code: RelatedHelper.SHARE_RIGHT_NOACCESS,
            },
            {
              system: RelatedHelper.SHARE_FEELING,
              code: RelatedHelper.SHARE_RIGHT_NOACCESS,
            },
            {
              system: RelatedHelper.SHARE_NOTE,
              code: RelatedHelper.SHARE_RIGHT_NOACCESS,
            },
            {
              system: RelatedHelper.SHARE_USEFUL_LINKS,
              code: RelatedHelper.SHARE_RIGHT_NOACCESS,
            },
          ],
      name: {
        use: "official",
        family: [lastname],
        given: [firstname],
      },
      telecom: [],
      birthDate: birthdate ? moment(birthdate).format("YYYY-MM-DD") : undefined,
    };
    // set Telecom
    if (!related.telecom) related.telecom = [];
    if (phone) {
      related.telecom.push({
        system: FHIRHelper.SYSTEM_TELECOM_PHONE,
        value: phone,
      });
    }
    if (mail) {
      related.telecom.push({
        system: FHIRHelper.SYSTEM_TELECOM_MAIL,
        value: mail,
      });
    }
    return related;
  }
}
