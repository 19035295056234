<div class="ui-dialog-container">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button mat-dialog-close color="warn" aria-label="Close dialog" matTooltip="{{ 'btn.close' | translate }}">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-dialog-content">
    <h4>{{ "pdf.questionnaire.export.title" | translate }} :</h4>
    <div class="btn-container">
      <button
        mat-raised-button
        (click)="onExportPDF(false)"
        style="margin-right: 0.5em"
        [disabled]="exportPdfListInProgress || exportPdfTableInProgress"
      >
        {{ "pdf.questionnaire.export.list" | translate }}
        <mat-icon *ngIf="exportPdfListInProgress"><mat-spinner diameter="24"></mat-spinner></mat-icon>
      </button>
      <button mat-raised-button (click)="onExportPDF(true)" [disabled]="exportPdfListInProgress || exportPdfTableInProgress">
        {{ "pdf.questionnaire.export.table" | translate }}
        <mat-icon *ngIf="exportPdfTableInProgress"><mat-spinner diameter="24"></mat-spinner></mat-icon>
      </button>
    </div>
  </div>
</div>
