import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { CustomErrorStateMatcher, PREVENTCHARACTER, PreventCharacter } from "src/app/helpers/formValidators";
import { FormsData } from "src/app/helpers/formsData";
import { RelatedHelper } from "src/app/helpers/relatedHelper";
import { Tools } from "src/app/helpers/tools";
import { ERROR_MSG } from "src/app/models/app-error.interface";
import { Coding } from "src/app/models/coding.interface";
import { Patient } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { IRelatedPerson } from "src/app/models/related.interface";
import { patientRegistrationService } from "src/app/providers/patientRegistration.service";
import { RelatedService } from "src/app/providers/related.service";
import { SessionService } from "src/app/providers/session.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";
import { PatientAlreadyExistsDialogComponent } from "../forms/add-patient/patient-already-exists-dialog/patient-already-exists-dialog.component";
import { TownServerSideSearchComponent } from "../forms/add-patient/town-server-side-search-component/town-server-side-search.component";

@Component({
  selector: "app-related-form",
  templateUrl: "./related-form.component.html",
  styleUrls: ["./related-form.component.scss"],
})
export class RelatedFormComponent implements OnInit, OnDestroy {
  @ViewChild(TownServerSideSearchComponent) townServerSideSearch: TownServerSideSearchComponent;
  public PREVENTCHARACTER = PREVENTCHARACTER;
  public isLoading = true;
  public availableLangs: Coding[];
  public maxDate: moment.Moment = moment();
  public relatedForm: UntypedFormGroup;
  public matcher = new CustomErrorStateMatcher();
  public submitted = false;
  public startFadeOut = false;
  public emailAlreadyTaken = false; // variable to stock response from put /patient
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();
  public saveInProgress = false;
  public source: string;

  @Input() patientSaved: Patient;
  @Input() isCreation: boolean;
  @Input() related: IRelatedPerson;
  @Input() isFromRouting = true;
  @Output() nextStep = new EventEmitter();
  @Output() finish = new EventEmitter();
  @Output() relatedCreated = new EventEmitter<IRelatedPerson>();
  constructor(
    private fb: UntypedFormBuilder,
    public formsData: FormsData,
    public sessionService: SessionService,
    public translateService: TranslateService,
    private dialog: MatDialog,
    private statService: UserStatisticsService,
    private relatedService: RelatedService,
    private router: Router,
    public patientRegistrationService: patientRegistrationService
  ) {
    if (this.isFromRouting) {
      this.isCreation = this.patientRegistrationService.isCreation;
      this.patientSaved = this.patientRegistrationService.patientSaved;
    }

    this.relatedForm = this.fb.group({
      name: new UntypedFormControl(undefined, [Validators.required, Validators.pattern("[^0-9]*")]),
      firstname: new UntypedFormControl(undefined, [Validators.required, Validators.pattern("[^0-9]*")]),
      birthDate: new UntypedFormControl(undefined, Validators.required),
      phone: new UntypedFormControl(undefined, [Validators.required, this.phoneValidator, Validators.pattern("[0-9+]*")]),
      mail: new UntypedFormControl(undefined, [Validators.required, Validators.email]),
      relatedPermissions: new FormGroup({
        careplan: new FormGroup({
          view: new FormControl(true),
        }),
        agenda: new FormGroup({
          view: new FormControl(true),
        }),
        drug: new FormGroup({
          view: new FormControl(true),
          edit: new FormControl(true),
        }),
        observation: new FormGroup({
          view: new FormControl(true),
          edit: new FormControl(true),
        }),
        questionnaire: new FormGroup({
          view: new FormControl(true),
          edit: new FormControl(true),
        }),
        usefulLinks: new FormGroup({
          view: new FormControl(true),
          edit: new FormControl(true),
        }),
      }),
    });
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private async initData() {
    this.availableLangs = await this.formsData.getLanguages();
    this.isLoading = false;
    if (!this.isCreation) {
      // update form when modifying existing related
      this.relatedForm.patchValue(
        {
          name: this.related.name.family.join(" ").trim(),
          firstname: this.related.name.given.join(" ").trim(),
          birthDate: this.related.birthDate,
          phone: RelatedHelper.getRelatedPhone(this.related),
          mail: RelatedHelper.getRelatedMail(this.related),
          relatedPermissions: {
            careplan: {
              view: RelatedHelper.hasReadRightOn(this.related, RelatedHelper.SHARE_CAREPLAN, false),
            },
            agenda: {
              view: RelatedHelper.hasReadRightOn(this.related, RelatedHelper.SHARE_AGENDA, false),
            },
            drug: {
              view: RelatedHelper.hasReadRightOn(this.related, RelatedHelper.SHARE_DRUG, false),
              edit: RelatedHelper.hasWriteRightOn(this.related, RelatedHelper.SHARE_DRUG, false),
            },
            observation: {
              view: RelatedHelper.hasReadRightOn(this.related, RelatedHelper.SHARE_OBSERATION, false),
              edit: RelatedHelper.hasWriteRightOn(this.related, RelatedHelper.SHARE_OBSERATION, false),
            },
            questionnaire: {
              view: RelatedHelper.hasReadRightOn(this.related, RelatedHelper.SHARE_FEELING, false),
              edit: RelatedHelper.hasWriteRightOn(this.related, RelatedHelper.SHARE_FEELING, false),
            },
            usefulLinks: {
              view: RelatedHelper.hasReadRightOn(this.related, RelatedHelper.SHARE_USEFUL_LINKS, false),
              edit: RelatedHelper.hasWriteRightOn(this.related, RelatedHelper.SHARE_USEFUL_LINKS, false),
            },
          },
        },
        { emitEvent: false, onlySelf: true }
      );
      this.relatedForm.get("mail").disable();
      this.relatedForm.get("birthDate").disable();
      this.relatedForm.get("phone").disable();
    }
  }

  public async apply(addRelated = false, forceUpdate = false, closeAfterSave = false): Promise<void> {
    if (this.relatedForm.valid) {
      const formValues = this.getRelatedFromForm();
      const relationship = this.getRelatedRelationshipFromForm();
      if (this.isCreation) {
        // invite related
        const patientIdentifier = FHIRHelper.getMainIdentifier(this.patientSaved);
        const patientReference: Reference = {
          display: patientIdentifier.label,
          reference: patientIdentifier.value,
        };
        this.isLoading = true;
        this.relatedService
          .create(
            patientReference,
            formValues.firstname,
            formValues.name,
            formValues.mail,
            formValues.phone,
            formValues.birthdate,
            relationship,
            {
              forceUpdate,
            }
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (related) => {
              this.isLoading = false;
              if (related) {
                this.patientRegistrationService.onRelatedCreated$.next(related);
                if (addRelated) {
                  this.resetForm();
                } else {
                  this.success();
                }
              }
            },
            (err) => {
              if (err?.message === ERROR_MSG.ALREADY_EXISTS && err?.additionalData) {
                this.statService.createStatEvent("Tried to create a related that already exists");
                const dialogRef = this.dialog.open(PatientAlreadyExistsDialogComponent, {
                  disableClose: true,
                  data: { ...err },
                  panelClass: "dialog-container-scroll",
                });

                dialogRef.afterClosed().subscribe((result) => {
                  if (result?.forceUpdateUser) {
                    this.apply(true, true);
                  }
                  this.isLoading = false;
                });
              }
            }
          );
      } else {
        this.isLoading = true;
        this.relatedService
          .update(
            this.related.identifier,
            this.related.patient,
            this.related.active,
            formValues.firstname,
            formValues.name,
            formValues.mail,
            formValues.phone,
            formValues.birthdate,
            relationship
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((related) => {
            this.isLoading = false;
            this.related = related;
            if (closeAfterSave) {
              this.patientRegistrationService.back();
            }
            this.initData();
          });
      }
    } else {
      let emptyForm = true;
      const invalid = [];
      const controls = this.relatedForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
        if (Tools.isDefined(controls[name].value) && controls[name].value !== "" && name !== "relatedPermissions") {
          emptyForm = false;
        }
      }
      if (emptyForm && !addRelated) {
        this.success();
      } else {
        FileLogger.warn("relatedFormComponent", "invalid form");
      }
    }
    this.submitted = true;
    this.relatedForm.markAllAsTouched();
  }

  private getRelatedFromForm() {
    const Qdata = {
      ...this.relatedForm.getRawValue(),
    };
    const related = {
      name: Qdata.name,
      firstname: Qdata.firstname,
      mail: Qdata.mail,
      phone: Qdata.phone,
      birthdate: moment(Qdata.birthDate).format("YYYY-MM-DD"),
    };
    return related;
  }

  private success(): void {
    if (this.patientRegistrationService.isLastStep) {
      this.patientRegistrationService.onFinish();
    } else {
      this.patientRegistrationService.onNextStep$.next();
    }
  }

  public phoneValidator: ValidatorFn = (control: AbstractControl): { [key: string]: true } | null => {
    return Tools.phoneValidator(control);
  };

  public emailValidator: ValidatorFn = (): ValidationErrors | null => {
    return this.emailAlreadyTaken ? { emailAlreadyTaken: true } : null;
  };

  public resetEmailAlreadyTaken(): void {
    this.emailAlreadyTaken = false;
  }

  public sendMail(): void {
    const mailText = "mailto:contact@comunicare.be?subject=Practitioner whish to use an existing email for referent";
    window.location.href = mailText;
  }

  public preventCharacter(event: KeyboardEvent, characters: PREVENTCHARACTER[], formControlName?: string): void {
    // we need this timeout because the replacement must be after the change of the input value
    setTimeout(() => {
      const value = PreventCharacter.preventMultiple(event, characters);
      this.relatedForm.get(formControlName).setValue(value);
    }, 0);
  }

  private getRelatedRelationshipFromForm(): Coding[] {
    const relatedPermissionsValues = this.relatedForm.get("relatedPermissions").value;
    return [
      {
        system: RelatedHelper.SHARE_CAREPLAN,
        code: relatedPermissionsValues.careplan.view ? RelatedHelper.SHARE_RIGHT_READONLY : RelatedHelper.SHARE_RIGHT_NOACCESS,
      },
      {
        system: RelatedHelper.SHARE_DRUG,
        code: relatedPermissionsValues.drug.edit
          ? RelatedHelper.SHARE_RIGHT_READWRITE
          : relatedPermissionsValues.drug.view
          ? RelatedHelper.SHARE_RIGHT_READONLY
          : RelatedHelper.SHARE_RIGHT_NOACCESS,
      },
      {
        system: RelatedHelper.SHARE_AGENDA,
        code: relatedPermissionsValues.agenda.view ? RelatedHelper.SHARE_RIGHT_READONLY : RelatedHelper.SHARE_RIGHT_NOACCESS,
      },
      {
        system: RelatedHelper.SHARE_OBSERATION,
        code: relatedPermissionsValues.observation.edit
          ? RelatedHelper.SHARE_RIGHT_READWRITE
          : relatedPermissionsValues.observation.view
          ? RelatedHelper.SHARE_RIGHT_READONLY
          : RelatedHelper.SHARE_RIGHT_NOACCESS,
      },
      {
        system: RelatedHelper.SHARE_FEELING,
        code: relatedPermissionsValues.questionnaire.edit
          ? RelatedHelper.SHARE_RIGHT_READWRITE
          : relatedPermissionsValues.questionnaire.view
          ? RelatedHelper.SHARE_RIGHT_READONLY
          : RelatedHelper.SHARE_RIGHT_NOACCESS,
      },
      {
        system: RelatedHelper.SHARE_NOTE,
        code: RelatedHelper.SHARE_RIGHT_NOACCESS,
      },
      {
        system: RelatedHelper.SHARE_USEFUL_LINKS,
        code: relatedPermissionsValues.usefulLinks.view ? RelatedHelper.SHARE_RIGHT_READONLY : RelatedHelper.SHARE_RIGHT_NOACCESS,
      },
    ];
  }

  private resetForm() {
    this.relatedForm.reset();
    this.relatedForm.markAsPristine();
    this.relatedForm.markAsUntouched();
    this.relatedForm.get("relatedPermissions").setValue({
      careplan: {
        view: true,
      },
      agenda: {
        view: true,
      },
      drug: {
        view: true,
        edit: true,
      },
      observation: {
        view: true,
        edit: true,
      },
      questionnaire: {
        view: true,
        edit: true,
      },
      usefulLinks: {
        view: true,
        edit: true,
      },
    });
  }
}
