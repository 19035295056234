import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { Tools } from "src/app/helpers/tools";
import { CommunicationsCrudService } from "src/app/providers/communications-crud.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../confirmation-dialog/confirmation-dialog.component";
import { AddCommunicationComponent } from "../forms/add-communication/add-communication.component";
import { GlobalHelpDialogComponent } from "../global-help-dialog/global-help-dialog.component";
import { PatientTeleconsultationDialogComponent } from "../patient-teleconsultations-dialog/patient-teleconsultations-dialog.component";
import { PreferenceDialogComponent } from "../preference-dialog/preference-dialog.component";

@Component({
  selector: "app-top-menu-icons",
  templateUrl: "./top-menu-icons.component.html",
  styleUrls: ["./top-menu-icons.component.scss"],
})
export class TopMenuIconsComponent implements OnInit, OnDestroy {
  @Input() isMatMenu: boolean;
  @Output() logout = new EventEmitter<void>();
  public isAllServices = false;
  public isMonitoringUser = false;
  public isEitherServicesAllMode = false;
  public isServiceSelected = false;
  public areBothServicesSelected = false;
  private serviceWatch$: Subscription;
  private serviceWatch2$: Subscription;
  private serviceWatch3$: Subscription;
  private onDestroy$ = new Subject<void>();

  constructor(
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    public helpData: HelpData,
    private sessionService: SessionService,
    private crudCommunication: CommunicationsCrudService,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    private translateService: TranslateService,
    private responsiveDialogService: ResponsiveDialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sessionService
      .getObservableAccount()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((account) => {
        this.serviceWatch$?.unsubscribe();
        this.serviceWatch2$?.unsubscribe();
        this.serviceWatch3$?.unsubscribe();
        this.isMonitoringUser = false;
        this.isAllServices = false;
        this.isEitherServicesAllMode = false;
        this.isServiceSelected = false;
        this.areBothServicesSelected = false;
        if (!account) {
          return;
        }
        if (
          this.sessionService.currentService &&
          this.userService.ownOrganization &&
          (this.healthcareService.availableServices()?.length > 0 || this.healthcareService.availableMonitoringServices().length > 0)
        ) {
          this.setupServiceWatch();
        } else {
          this.serviceWatch3$ = this.sessionService.servicesSetupWatch.pipe().subscribe(() => {
            this.setupServiceWatch();
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public openHelpDialog(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.globalHelp },
        disableClose: true,
      },
      { width: "55vw", height: "80vh" }
    );
  }
  public openPreferenceDialog(): void {
    const dialog = this.responsiveDialogService.open(
      PreferenceDialogComponent,
      // global config
      {
        disableClose: true,
      },
      // Desktop config
      {
        maxWidth: "80vw",
      }
    );

    dialog.afterClosed().subscribe((saved) => {
      if (saved) {
        this.sessionService.needRefreshPatientPage();
        this.sessionService.needRefreshDashboardPage();
        this.sessionService.needRefreshPatientDataList();
      }
    });
  }

  public openAddMessage(): void {
    this.responsiveDialogService.open(AddCommunicationComponent);
  }

  public newCommunication(): void {
    this.crudCommunication.createCommunication();
  }
  public addPatient(): void {
    this.router.navigate(["patientRegistration", "patient"], {
      state: {
        mode: FORMS_MODE.CREATE,
      },
    });
  }

  public addVideoCall(): void {
    const serviceRef = this.userService.isMonitoringUser
      ? this.sessionService.currentMonitoringService?.reference
      : this.sessionService.currentService?.reference;

    if (serviceRef === this.sessionService.allsOption) {
      return;
    }

    const healthcareService = this.userService.isMonitoringUser
      ? this.healthcareService.availableMonitoringServices().find((s) => s.serviceRef === serviceRef)
      : this.healthcareService.availableServices().find((s) => s.serviceRef === serviceRef);

    if (healthcareService.mainPhone) {
      this.dialog.open(PatientTeleconsultationDialogComponent, {
        disableClose: true,
        data: { appointment: null, patientUser: null, mode: FORMS_MODE.CREATE },
      });
    } else {
      const message = this.translateService
        .instant("page.teleconsultations.noPhoneError")
        .replace("%SERVICE_NAME%", this.sessionService.currentService?.display);
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message,
          type: ConfirmationDialogType.ERROR,
        },
      });
    }
  }

  private setupServiceWatch() {
    this.serviceWatch$?.unsubscribe();
    this.serviceWatch2$?.unsubscribe();
    this.isMonitoringUser = this.userService.isMonitoringUser;
    if (this.userService.isMonitoringUser) {
      this.isServiceSelected =
        Tools.isDefined(this.sessionService.currentMonitoringService) && Tools.isDefined(this.sessionService.currentService);
      this.areBothServicesSelected = this.isServiceSelected && Tools.isDefined(this.sessionService.currentService);
      this.isAllServices =
        !this.sessionService.currentMonitoringService ||
        this.sessionService.currentMonitoringService.reference === this.sessionService.allsOption;
      this.isEitherServicesAllMode =
        this.isAllServices ||
        !this.sessionService.currentService ||
        this.sessionService.currentService.reference === this.sessionService.allsOption;

      this.serviceWatch$ = this.sessionService.refreshCurrentMonitService.subscribe(() => {
        this.isAllServices = this.sessionService.currentMonitoringService?.reference === this.sessionService.allsOption;
        this.isEitherServicesAllMode =
          this.isAllServices ||
          !this.sessionService.currentService ||
          this.sessionService.currentService.reference === this.sessionService.allsOption;
        this.isServiceSelected =
          Tools.isDefined(this.sessionService.currentMonitoringService) && Tools.isDefined(this.sessionService.currentService);
        this.areBothServicesSelected = this.isServiceSelected && Tools.isDefined(this.sessionService.currentService);
      });

      this.serviceWatch2$ = this.sessionService.refreshCurrentService.subscribe(() => {
        this.isEitherServicesAllMode =
          this.isAllServices ||
          !this.sessionService.currentService ||
          this.sessionService.currentService.reference === this.sessionService.allsOption;
        this.isServiceSelected =
          Tools.isDefined(this.sessionService.currentMonitoringService) && Tools.isDefined(this.sessionService.currentService);
        this.areBothServicesSelected = this.isServiceSelected && Tools.isDefined(this.sessionService.currentService);
      });
    } else {
      this.isAllServices =
        !this.sessionService.currentService || this.sessionService.currentService.reference === this.sessionService.allsOption;
      this.isEitherServicesAllMode = this.isAllServices;

      this.isServiceSelected = Tools.isDefined(this.sessionService.currentService);
      this.areBothServicesSelected = this.isServiceSelected;

      this.serviceWatch$ = this.sessionService.refreshCurrentService.subscribe(() => {
        this.isAllServices =
          !this.sessionService.currentService || this.sessionService.currentService.reference === this.sessionService.allsOption;
        this.isEitherServicesAllMode = this.isAllServices;
        this.isServiceSelected = Tools.isDefined(this.sessionService.currentService);
        this.areBothServicesSelected = this.isServiceSelected;
      });
    }
  }

  onLogout(): void {
    this.logout.emit();
  }
}
