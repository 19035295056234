import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { DataType, Filter } from "src/app/models/filter.interface";
import { BillingDataSource } from "../../billing.datasource";
import { BILLING_TYPE, IChargeItemsInfo } from "../../billing.interface";

@Component({
  selector: "app-invoice-table",
  templateUrl: "./invoice-table.component.html",
  styleUrls: ["./invoice-table.component.scss", "../../../../../assets/comunicare-table.scss"],
})
export class InvoiceTableComponent implements AfterViewInit {
  @Input() dataSource: BillingDataSource;
  @Input() data: IChargeItemsInfo[];

  @ViewChild(MatSort) sort: MatSort;
  @Output() sortChanged = new EventEmitter<Sort>();

  @Output() filterChanged = new EventEmitter<Filter>();

  displayedColumns: string[] = [
    "lastname",
    "firstname",
    "ssin",
    "birthDate",
    "category",
    "startDate",
    "endDate",
    "code",
    "totalPrice",
    "status",
    "actions",
  ];
  public dataType = DataType;

  billingStatusClasses: { [key in BILLING_TYPE]: string } = {
    [BILLING_TYPE.PLANNED]: "planned",
    [BILLING_TYPE.BILLABLE]: "billable",
    [BILLING_TYPE.NOT_BILLABLE]: "not-billable",
    [BILLING_TYPE.BILLED]: "billed",
  };
  filters: Filter[];

  ngAfterViewInit(): void {
    // Watch sort
    this.sort.sortChange.subscribe((value) => {
      this.sortChanged.next(value);
    });
  }

  public applyFilter(filter: Filter): void {
    this.filterChanged.emit(filter);
  }

  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }
}
