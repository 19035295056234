<mat-nav-list class="p-0">
  <mat-list-item *ngFor="let invoice of data" class="app-list-item" (click)="invoiceRowClicked(invoice)">
    <div class="app-list-item-details">
      <div class="line">
        <div>
          <h3>{{ invoice.firstname + " " + invoice.lastname }}</h3>
          <p>{{ invoice.code }} - {{ invoice.category }}</p>
          <p>{{ invoice.startDate | date : "dd/MM/yyyy" }} - {{ invoice.endDate | date : "dd/MM/yyyy" }}</p>
        </div>
        <div>
          <h3 style="font-weight: 600">{{ invoice.totalPrice }}</h3>
          <span class="status-box" [ngClass]="billingStatusClasses[invoice.statut]">
            {{ "billing.status." + invoice.statut | translate }}
          </span>
        </div>
      </div>
    </div>
  </mat-list-item>
</mat-nav-list>
