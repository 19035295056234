<mat-card class="comunicare-widget" cdkDrag [cdkDragDisabled]="!isDraggable">
  <mat-card-header class="widget-header">
    <mat-card-title class="custom-widget-title">
      {{ "widget.renewablePrescriptions" | translate }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="mini-bar">
      <mat-form-field class="widget-timing-select">
        <mat-select [(value)]="selectedTiming" (selectionChange)="onTimingChange($event)">
          <mat-option *ngFor="let timing of renewalTimings" [value]="timing.value">
            {{ timing.label | translate }}
          </mat-option>
        </mat-select></mat-form-field
      >
    </div>

    <mat-list class="p-0" [ngStyle]="(dataSource.loading$ | async) || isLoading ? { display: 'none' } : {}">
      <mat-list-item *ngFor="let element of dataSource.rawData$ | async" class="widget-list-item pt-05 pb-05">
        <button
          class="mr-1"
          mat-icon-button
          [routerLink]="['/patient', { id: encodeToB64(element.caremateId) }]"
          matTooltip="{{ element.name }}"
          matTooltipClass="truncatedNamesTooltip"
        >
          <mat-icon>face</mat-icon>
        </button>

        <div class="widget-list-details">
          <div class="line">
            <p mat-line class="custom-widget-font">
              <b>{{ element.name }}</b> - {{ element.category | translate }}
            </p>
          </div>

          <div class="line">
            <p class="custom-widget-font">{{ element.date.start | date : "dd/MM/YYYY" }} - {{ element.date.end | date : "dd/MM/YYYY" }}</p>
          </div>
          <p class="custom-widget-font">
            {{ "table.duration" | translate }} : {{ element.frequency.duration }}

            <ng-container [ngSwitch]="element.frequency.unit">
              <span *ngSwitchCase="'d'"> {{ "periodUnit.day.singPlural" | translate }}</span>
              <span *ngSwitchCase="'w'"> {{ "periodUnit.week.singPlural" | translate }}</span>
              <span *ngSwitchCase="'m'"> {{ "periodUnit.month.singPlural" | translate }}</span>
              <span *ngSwitchCase="'y'"> {{ "periodUnit.year.singPlural" | translate }}</span>
            </ng-container>
          </p>
          <p class="custom-widget-font">{{ "table.adherence" | translate }} : {{ element.adherence }}</p>
        </div>
        <button mat-icon-button>
          <mat-icon>chevron_right</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>

    <p class="text-center mt-1 mb-1" *ngIf="(dataSource.loading$ | async) === false && !isLoading && !renewablePrescriptionsCount">
      {{ "widget.noData" | translate }}
    </p>

    <div class="spinner-container" *ngIf="(dataSource.loading$ | async) || isLoading">
      <mat-spinner></mat-spinner>
    </div>
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="currentPageSize ? currentPageSize : 5" [length]="renewablePrescriptionsCount">
    </mat-paginator>
  </mat-card-content>
</mat-card>
