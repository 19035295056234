import { Observable, of } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IChargeItemsInfo } from "./billing.interface";
import { BillingService } from "./services/billing.service";

export class BillingDataSource extends MatTableDataSourceBackendExtended<IChargeItemsInfo> {
  public rawData$ = this.dataSubject.asObservable();

  constructor(private billingService: BillingService) {
    super();
  }

  public data(params: DataSourceBackendParam): Observable<IChargeItemsInfo[]> {
    if (!params.services || params.services.length < 1) {
      return of([]);
    }
    return this.billingService.findInvoices(params);
  }
}
