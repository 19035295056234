<div class="left">
  <button class="color-button" mat-stroked-button (click)="patientRegistrationService.back()">
    <i class="fas fa-arrow-circle-left fa-lg"></i>
  </button>
</div>
<div class="patientCreationEditionPage">
  <div class="patientCreation" *ngIf="isCreation">
    <mat-stepper linear #stepper [orientation]="(stepperOrientation | async)!">
      <mat-step *ngFor="let step of timeline; let i = index; let isLast = last">
        <ng-template matStepLabel style="display: flex; flex-direction: column; margin-bottom: 5px">
          <h2 [style]="step.subtitles?.length ? 'margin-top: 15px; margin-bottom: 0px' : 'margin-top: 15px'">{{ step.title }}</h2>
          <div style="overflow: hidden; height: 100%">
            <div [class]="step.subtitles?.length > 1 ? 'subtitles' : ''">
              <p *ngFor="let subtitle of step.subtitles">{{ subtitle }}</p>
            </div>
          </div>
        </ng-template>
        <div *ngIf="isLoading" class="loading">
          <div class="spinner-container">
            <mat-spinner></mat-spinner>
          </div>
        </div>
        <router-outlet *ngIf="!isLoading"></router-outlet>
      </mat-step>
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
    </mat-stepper>
  </div>
  <div class="patientEdition" *ngIf="!isCreation">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ "forms.updatePatientTitle" | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-patient-form [isFromRouting]="false" [isCreation]="isCreation" [source]="source" [patient]="patient"></app-patient-form>
      </mat-expansion-panel>
      <mat-expansion-panel *ngFor="let related of relateds">
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            ("forms.updateRelatedTitle" | translate) +
              " : " +
              related.name.family.join(" ").trim() +
              " " +
              related.name.given.join(" ").trim()
          }}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-related-form
          [isFromRouting]="false"
          [isCreation]="isCreation"
          [related]="related"
          style="display: flex; justify-content: center"
        ></app-related-form>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
