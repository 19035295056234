import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataSourceBackendParam } from "../models/data-sources-backend-param.interface";
import { IPrescription, IPrescriptionInfosResponse, RENEW_TIMING } from "../models/prescription.interface";
import { PrescriptionApiService } from "./api/prescription-api.service";

@Injectable({
  providedIn: "root",
})
export class PrescriptionService {
  constructor(private prescriptionApiService: PrescriptionApiService) {}

  public getPendingPrescriptionInfos(
    params: DataSourceBackendParam & { organizations?: string; patientCaremateId?: string; period?: string; date?: string; status?: string }
  ): Observable<IPrescriptionInfosResponse[]> {
    return this.prescriptionApiService.getPrescriptionInfos(params) as Observable<IPrescriptionInfosResponse[]>;
  }

  public getPendingPrescriptionCount(param: {
    patientCaremateId?: string;
    services?: string[];
    organizations?: string[];
    period?: string;
    date?: string;
    status?: number[];
  }): Observable<{
    count: number;
  }> {
    return this.prescriptionApiService.getPrescriptionInfosCount(
      param.patientCaremateId,
      param.services,
      param.organizations,
      param.period,
      param.date,
      param.status
    );
  }

  public acceptPrescription(prescriptionId: string, careplanId?: string): Observable<any> {
    return this.prescriptionApiService.acceptPrescription(prescriptionId, careplanId) as Observable<IPrescriptionInfosResponse[]>;
  }
  public listPrescriptionsToBeRenewedInfos(
    params: DataSourceBackendParam & { timing: RENEW_TIMING; services?: string }
  ): Observable<IPrescription[]> {
    return this.prescriptionApiService.getPrescriptionsToBeRenewedInfos(params.timing, params.pageNumber, params.pageSize, params.services);
  }
  public getPrescriptionsToBeRenewedCount(
    timing: RENEW_TIMING,
    services?: string[]
  ): Observable<{
    count: number;
  }> {
    return this.prescriptionApiService.getPrescriptionsToBeRenewedCount(timing, services);
  }
}
