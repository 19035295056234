import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { fromEvent, Subject } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { debounceTime, distinctUntilChanged, first, takeUntil } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { Filter } from "src/app/models/filter.interface";
import { PreferenceContext, PreferenceUser, TableParameter } from "src/app/models/preference.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { BillingDataSource } from "../../billing.datasource";
import { IChargeItemsInfo } from "../../billing.interface";
import { BillingService } from "../../services/billing.service";

@Component({
  selector: "app-billing-page",
  templateUrl: "./billing-page.component.html",
  styleUrls: ["./billing-page.component.scss"],
})
export class BillingPageComponent implements OnInit, AfterViewInit, OnDestroy {
  protected onDestroy$ = new Subject<void>();

  private preferences: TableParameter;

  public dataSource: BillingDataSource;
  public totalCount = 0;
  public subscriptions: Subscription[] = [];
  public sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("searchInput") searchInput: ElementRef<HTMLInputElement>;

  public currentServices: string[] = [];
  public healthcareServicesRefs: string[];
  public filters: Filter[] = [];
  public currentPageSize: number;
  public isMobile: boolean;
  public data: IChargeItemsInfo[];

  constructor(
    private invoiceService: BillingService,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    public responsiveService: ResponsiveService,
    private route: ActivatedRoute,
    private preferenceService: PreferenceService
  ) {
    this.preferences = this.route.snapshot.data.preferences;
  }

  ngOnInit(): void {
    this.dataSource = new BillingDataSource(this.invoiceService);

    this.dataSource.rawData$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.data = data;
    });

    // apply filter from preference
    this.filters = this.preferences?.filters ? this.preferences.filters : [];
    this.filters?.forEach((filter) => this.dataSource.setFilter(filter));

    this.responsiveService.isHandset$.pipe(takeUntil(this.onDestroy$)).subscribe((isMobile) => {
      this.isMobile = isMobile;

      if (this.healthcareServicesRefs?.length) {
        this.loadData();
      }
    });
  }

  ngAfterViewInit(): void {
    //  get pageSize from preference
    setTimeout(() => {
      this.paginator.pageSize = this.preferences?.itemsPerPage ? this.preferences.itemsPerPage : 25;
    });
    // watch search
    fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(takeUntil(this.onDestroy$), debounceTime(150), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadData();
      });

    // watch page change
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((_page: PageEvent) => {
      this.loadData();
      this.updatePreference();
    });

    // Reactive case: when the services are initialized after the view loads
    this.sessionService.servicesSetupWatch.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.updateServicesRefsAndLoadData();
    });

    // Immediate case: if the services are already available when component loads
    if (this.sessionService.currentService) {
      this.updateServicesRefsAndLoadData();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private updateServicesRefsAndLoadData(): void {
    if (this.sessionService.currentService.reference === "all") {
      this.healthcareServicesRefs = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    } else {
      this.healthcareServicesRefs = [this.sessionService.currentService?.reference];
    }

    setTimeout(() => {
      this.loadData();
    });
  }

  loadData(): void {
    this.dataSource.loadData({
      filters: this.responsiveService.isMobile ? [] : this.dataSource.getAllFilters(), //no filter on mobile view
      sortId: this.sort?.active,
      sortOrder: this.sort?.direction,
      pageNumber: this.paginator?.pageIndex,
      pageSize: this.paginator?.pageSize,
      search: this.searchInput?.nativeElement?.value,
      services: this.healthcareServicesRefs,
    });

    this.invoiceService
      .getInvoicesCount(this.healthcareServicesRefs, this.searchInput?.nativeElement?.value, this.dataSource.getAllFilters())
      .subscribe((res) => {
        this.totalCount = res.count;
      });
  }

  onSortChanged($event: MatSort): void {
    this.sort = $event;
    this.loadData();
  }

  applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.filters = Tools.clone(this.dataSource.getAllFilters());
    this.loadData();
    this.updatePreference();
  }

  clearFilter(): void {
    this.dataSource.clearFilter();
    this.filters = [];
    this.paginator.pageIndex = 0;
    this.loadData();
    this.updatePreference();
  }
  /**
   * Preferences
   */
  public updatePreference(): Promise<void> {
    if (this.responsiveService.isMobile) {
      return; // we don't want to save filters and other preferences on smartphone since we don't have the possibility to change filter on that view
    }

    return this.preferenceService
      .update({
        context: PreferenceContext.INVOICE_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          itemsPerPage: this.paginator.pageSize,
        } as TableParameter,
      })
      .pipe(first())
      .toPromise()
      .then((parameters: PreferenceUser) => {
        const param = parameters.preferences.find((p) => p.context === PreferenceContext.INVOICE_LIST);
        this.preferences = param.parameters;
      });
  }
}
