import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EventEmitter } from "events";
import * as moment from "moment";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { Tools } from "src/app/helpers/tools";
import { UserStatus } from "src/app/models/account.interface";
import { ICareplan } from "src/app/models/careplans.interface";
import { DISABLED_PATIENT_REASON, Patient, PatientUser } from "src/app/models/patient.interface";
import { AuthService } from "src/app/providers/auth.service";
import { CareplansService } from "src/app/providers/careplans.service";
import { PatientService } from "src/app/providers/patient.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../confirmation-dialog/confirmation-dialog.component";
import { IdentityStatusChangeHistoricComponent } from "./identity-status-change-historic/identity-status-change-historic.component";
import { PatientDisableDialogComponent } from "./patient-disable-dialog/patient-disable-dialog.component";
import { PatientHistoricDialogComponent } from "./patient-historic-dialog/patient-historic-dialog.component";

@Component({
  selector: "app-patient-infos",
  templateUrl: "./patient-infos.component.html",
  styleUrls: ["./patient-infos.component.scss"],
})
export class PatientInfosComponent implements OnInit, OnDestroy {
  @Output() refreshCareplan = new EventEmitter();
  @Input() set patientUserSet(pu: PatientUser) {
    this.patientUser = pu;
    this.handleAutoEditForm();
  }
  public patientUser: PatientUser;
  private currentCareplan: ICareplan;
  public reason = DISABLED_PATIENT_REASON;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  public isAdmin = false;
  public canDeactivate = false;
  public canUpdate = false;
  public canDelete = false;
  public isServiceSelected = false;
  public country: string;
  public FRANCE: string;

  constructor(
    private router: Router,
    private patientService: PatientService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private userService: UserService,
    public helpData: HelpData,
    private authService: AuthService,
    private careplansService: CareplansService,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog
  ) {
    this.setupServicesWatch();
    this.country = this.sessionService.currentCountry;
    this.FRANCE = this.sessionService.FRANCE;
  }

  ngOnInit(): void {
    this.setupIsAdmin();
    this.setupCanDeactivate();
    this.setupCanUpdate();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setupServicesWatch() {
    this.isServiceSelected = this.sessionService.currentService !== null ? true : false;
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.isServiceSelected = this.sessionService.currentService !== null ? true : false;
    });
  }

  public handleAutoEditForm(): void {
    if (this.patientUser) {
      const user = this.patientUser?.user;
      const isSameDateOrUndefined = moment(user.birthdate).isSame(moment(user.creation), "day") || !user.birthdate;
      const patientGender = this.patientUser?.patient?.gender;

      if (user?.anonymous) {
        // get anonymous patient careplan
        this.careplansService
          .list(user.caremateIdentifier)
          .pipe(first(), takeUntil(this.onDestroy$))
          .subscribe((cp) => {
            if (cp.length > 0) {
              this.currentCareplan = cp[0];
            }
            if (isSameDateOrUndefined && !patientGender) {
              this.initializeAnonymousPatient();
            }
          });
      }
    }
  }

  public initializeAnonymousPatient(): void {
    this.router.navigate(["patientRegistration", "careplan"], {
      state: {
        directToCareplan: true,
        patientUser: this.patientUser,
        careplan: this.currentCareplan ? this.currentCareplan : undefined,
        includeBirthdateAndGenderForm: true,
      },
    });
  }

  public goToPatients(): void {
    const historyPatientUrl = this.sessionService.patientPageFrom;
    if (historyPatientUrl) {
      this.sessionService.patientPageFrom = "";
      this.router.navigate([historyPatientUrl]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  public editPatient(): void {
    this.router.navigate(["patientRegistration", "patient"], {
      state: { patient: Tools.clone(this.patientUser), mode: FORMS_MODE.UPDATE },
    });
  }

  public getMail(patient: Patient): string | null {
    return this.patientService.getMail(patient);
  }

  public getPhone(patient: Patient): string | null {
    return this.patientService.getPhone(patient);
  }

  public getBirthdate(patient: Patient): string {
    return this.patientService.getBirthdate(patient);
  }

  public getId(patient: Patient): string | null {
    return this.patientService.getInternalId(patient);
  }

  public disablePatient(patient: PatientUser): void {
    const patients = [];
    patients.push(patient);
    this.dialog.open(PatientDisableDialogComponent, {
      data: { patients },
      disableClose: true,
    });
  }

  public activatePatient(patient: Patient): void {
    this.translateService
      .get("page.patientlist.activatePatientConfirm")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((trans: string) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: { message: trans, type: ConfirmationDialogType.CONFIRM },
        });

        dialogRef.afterClosed().subscribe((yes) => {
          if (yes) {
            this.patientService.activate(patient).subscribe(
              () => {
                const msg = this.translateService.instant("common.success");
                this.snackBar.open(msg, "ok", { duration: 3000 });
              },
              (err) => {
                FileLogger.error("PatientInfosComponent", "activatePatient", err);
                const msg = this.translateService.instant("common.updateFail");
                this.snackBar.open(msg, "ok", { duration: 3000 });
              }
            );
          }
        });
      });
  }

  public getAuthCode(patient: Patient): string | null {
    return this.patientService.computeAuthCode(patient);
  }

  public removeForEver(patientId: string): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("common.removeForEverAsk"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .subscribe((yes) => {
        if (yes) {
          this.patientService.removeForEver(patientId).subscribe(() => {
            const msg = this.translateService.instant("common.success");
            this.snackBar.open(msg, "ok", { duration: 3000 });
            this.goToPatients();
          });
        }
      });
  }

  private setupIsAdmin() {
    if (this.sessionService.isAdmin()) {
      this.isAdmin = true;
      this.userService
        .isAuthorized("patient", "DELETE")
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((isAuthorized) => {
          this.canDelete = isAuthorized;
        });
    } else {
      this.isAdmin = false;
      this.canDelete = false;
    }
  }

  private setupCanDeactivate() {
    this.userService
      .isAuthorized("deactivatePatient", "PUT")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAuthorized) => {
        this.canDeactivate = isAuthorized;
      });
  }

  private setupCanUpdate() {
    this.userService
      .isAuthorized("patient", "PUT")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAuthorized) => {
        this.canUpdate = isAuthorized;
      });
  }

  public openPatientHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: {
          slides: this.helpData.patientPageHelp(this.canDelete, this.canDeactivate, this.canUpdate),
        },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  public isNewPatient(patient: PatientUser): boolean {
    return patient?.user?.status === UserStatus.NEW;
  }

  public isActivePatient(patient: PatientUser): boolean {
    return patient?.user?.status === UserStatus.ACTIVE;
  }

  public isInactivePatient(patient: PatientUser): boolean {
    return patient?.user?.status === UserStatus.INACTIVE;
  }

  /**
   * Request server to reset patient password (only when user is fully registered)
   */
  public resetPatientPassword(patient: PatientUser): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("page.patientlist.resetPasswordConfirm"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .subscribe((yes) => {
        if (yes) {
          this.authService.resetPassword(patient?.user.caremateIdentifier).subscribe(() => {
            this.snackBar.open(this.translateService.instant("common.success"), undefined, {
              duration: 3000,
            });
          });
        }
      });
  }

  /**
   * Request server to send passcode again (when user is not fully registered yet)
   */
  public resendPasscode(patient: PatientUser): void {
    this.resetPatientPassword(patient); // same procedure
  }

  public openHistoric(patient: PatientUser): void {
    this.dialog.open(PatientHistoricDialogComponent, {
      data: { patient },
      disableClose: true,
    });
  }

  public showStatusChangeHistoric(patient: PatientUser): void {
    this.dialog.open(IdentityStatusChangeHistoricComponent, {
      data: { identity: patient.patient.identity },
    });
  }
}
