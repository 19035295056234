import { Observable } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IPrescriptionInfosResponse } from "src/app/models/prescription.interface";
import { PrescriptionService } from "src/app/providers/prescription.service";

export class PendingPrescriptionsDataSource extends MatTableDataSourceBackendExtended<IPrescriptionInfosResponse> {
  public rawData$ = this.dataSubject.asObservable();

  constructor(private prescriptionService: PrescriptionService) {
    super();
  }

  public data(params: DataSourceBackendParam): Observable<IPrescriptionInfosResponse[]> {
    return this.prescriptionService.getPendingPrescriptionInfos(params);
  }
}
