import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
@Injectable({
  providedIn: "root",
})
export class PwaService {
  public promptEvent: any;
  constructor(private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === "VERSION_READY") {
          this.swUpdate.activateUpdate().then(() => {
            window.location.reload();
          });
        }
      });
    }
  }
}
