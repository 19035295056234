import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { IObsDefWithTransOrigin } from "src/app/models/observations.interface";
import { IConfiguration, IObservationParam } from "src/app/models/patientConfig.interface";
import { PatientService } from "src/app/providers/patient.service";
import { UserService } from "src/app/providers/user.service";
import { TimingEditorComponent } from "../../timing-editor/timing-editor.component";

@Component({
  selector: "app-observation-definition-list",
  templateUrl: "./observation-definition-list.component.html",
  styleUrls: ["./observation-definition-list.component.scss"],
})
export class ObservationDefinitionListComponent implements OnInit, OnDestroy {
  @ViewChild(TimingEditorComponent) freqEditor: TimingEditorComponent;
  private onDestroy$ = new Subject<void>();
  public pageLoaded = false;
  public careplanObs: IObsDefWithTransOrigin[];
  public activityObs: IObsDefWithTransOrigin[];
  public standaloneObs: IObsDefWithTransOrigin[];
  public questionnaireObs: IObsDefWithTransOrigin[];
  public standaloneObsChecked: string[] = [];
  public hasChanges = false;
  public canAddStandaloneObs = false;
  public canUpdateObsParamFreq = false;
  public obsParamsToUpdate: IObservationParam = null;
  public patientObsLoinc: string[] = []; // loinc of the obs the patient already has when we open the dialog
  private patientConfig: IConfiguration;

  constructor(
    private patientService: PatientService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string; language?: string }
  ) {}

  ngOnInit(): void {
    this.loadObsDefinitions(true);
    this.loadPatientConfiguration();
    if (this.userService.isAuthorizedSync(null, "dashboard/configurationPatient", "PUT")) {
      this.canAddStandaloneObs = true;
    }
    if (this.userService.isAuthorizedSync(null, "dashboard/obsParamsFreqConfig", "PUT")) {
      this.canUpdateObsParamFreq = true;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadObsDefinitions(firstInit = false): void {
    this.patientService
      .getPatientObservationDefinitions(this.data.patientId, this.data.language)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((obsDef) => {
        this.careplanObs = obsDef.careplan;
        this.activityObs = obsDef.activity;
        this.standaloneObs = obsDef.standalone;
        this.questionnaireObs = obsDef.questionnaire;
        if (firstInit) {
          this.initStandaloneObsChecked();
        }
        this.pageLoaded = true;
      });
  }

  private loadPatientConfiguration(): void {
    this.patientService
      .getPatientConfiguration(this.data.patientId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((config) => {
        this.patientConfig = config;
        this.patientObsLoinc = this.patientConfig.parameters.observationParams.map((o) => String(o.type));
      });
  }

  private initStandaloneObsChecked(): void {
    this.standaloneObs.forEach((obs) => {
      if (obs.active) {
        this.standaloneObsChecked.push(obs.observationDefinition.loinc);
      }
    });
  }

  public updatePatientConfig(): void {
    this.pageLoaded = false;
    this.hasChanges = false;
    this.patientService.updatePatientConfiguration(this.data.patientId, this.standaloneObsChecked).subscribe(() => {
      this.snackBar
        .open(`✅ ${this.translateService.instant("common.success")}`, null, {
          duration: 4000,
        })
        .afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loadObsDefinitions();
          this.loadPatientConfiguration();
        });
    });
  }

  public onStandaloneObsChange($event: MatCheckboxChange): void {
    if ($event.checked && !this.standaloneObsChecked.includes($event.source.value)) {
      this.standaloneObsChecked.push($event.source.value);
    } else if (!$event.checked && this.standaloneObsChecked.includes($event.source.value)) {
      const index = this.standaloneObsChecked.findIndex((l) => l === $event.source.value);
      if (index !== -1) {
        this.standaloneObsChecked.splice(index, 1);
      }
    }
    const activeObs: string[] = [];
    for (const o of this.standaloneObs) {
      if (o.active) {
        activeObs.push(o.observationDefinition.loinc);
      }
    }
    if (Tools.isEqual(this.standaloneObsChecked, activeObs)) {
      this.hasChanges = false;
    } else {
      this.hasChanges = true;
    }
  }

  public async saveParamsFreq(): Promise<void> {
    if (!this.obsParamsToUpdate) {
      return;
    }
    if (!this.freqEditor.save()) {
      return;
    }
    this.pageLoaded = false;
    this.hasChanges = false;
    this.patientService
      .updatePatientObsParamsTiming(this.data.patientId, String(this.obsParamsToUpdate.type), this.obsParamsToUpdate.frequency)
      .subscribe(() => {
        this.snackBar
          .open(`✅ ${this.translateService.instant("common.success")}`, null, {
            duration: 4000,
          })
          .afterDismissed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.obsParamsToUpdate = null;
            this.loadObsDefinitions();
            this.loadPatientConfiguration();
          });
      });
  }

  public updateObsParamsFreq(obsLoinc: string): void {
    if (!this.canUpdateObsParamFreq && !this.patientConfig) {
      return;
    }
    this.obsParamsToUpdate = this.patientConfig.parameters.observationParams.find(
      (o) => Tools.observationLoincConversionMap(String(o.type)) === obsLoinc
    );
  }
}
