import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Tools } from "src/app/helpers/tools";
import { ApiResponse } from "src/app/models/api-response.interface";
import { AppError } from "src/app/models/app-error.interface";
import { Identifier } from "src/app/models/identifier.interface";
import { SessionService } from "../session.service";
import { environment } from "./../../../environments/environment";
export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
@Injectable({
  providedIn: "root",
})
export class ApiService {
  private url: string = environment.apiUrl;
  private maintenanceUrl: string = environment.maintenanceUrl;

  constructor(public http: HttpClient, private sessionService: SessionService) {}

  public get(
    endpoint: string,
    params?: Record<string, string | number | boolean | string[] | number[] | Identifier>,
    forMaintenance = false
  ): Observable<unknown> {
    // TODO remove this when we have a maintenance server in prod
    if (forMaintenance && !this.maintenanceUrl) {
      return of([]);
    }
    const reqOpts = {
      params: new HttpParams({ encoder: new CustomHttpParamEncoder() }),
      headers: null,
    };

    if (params) {
      reqOpts.params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
      for (const k in params) {
        if (Tools.isDefined(params[k])) {
          reqOpts.params = reqOpts.params.set(k, params[k].toString());
        }
      }
    }

    reqOpts.headers = this.appendHeaders(reqOpts);

    const options = {
      headers: reqOpts.headers,
      params: reqOpts.params,
      responseType: "json" as const,
    };
    const fullUrl = (forMaintenance ? this.maintenanceUrl : this.url) + "/" + endpoint;
    return this.http.get(fullUrl, options).pipe(
      switchMap((result: ApiResponse) => {
        if (!result.success) {
          return throwError({
            global: false,
            message: result.message,
          } as AppError);
        } else {
          return of(result.data);
        }
      })
    );
  }

  public getBlob(endpoint: string, params?: Record<string, string | number | boolean>): Observable<unknown> {
    const reqOpts = {
      params: new HttpParams({ encoder: new CustomHttpParamEncoder() }),
      headers: null,
    };

    if (params) {
      reqOpts.params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
      for (const k in params) {
        if (params[k]) {
          reqOpts.params = reqOpts.params.set(k, params[k].toString());
        }
      }
    }

    reqOpts.headers = this.appendHeaders(reqOpts);
    const options = {
      headers: reqOpts.headers,
      params: reqOpts.params,
      responseType: "blob" as "json",
    };
    return this.http.get(this.url + "/" + endpoint, options).pipe(
      switchMap((result: ApiResponse | Blob) => {
        const apiResponse: ApiResponse = this.blobToApiResponse(result);
        if (apiResponse.success === false) {
          return throwError({
            global: false,
            message: apiResponse.message,
          } as AppError);
        } else {
          return of(result as Blob);
        }
      })
    );
  }

  private blobToApiResponse(blob): ApiResponse {
    const url = URL.createObjectURL(blob);
    const xmlRequest = new XMLHttpRequest();
    xmlRequest.open("GET", url, false);
    xmlRequest.send();
    URL.revokeObjectURL(url);
    let response;
    try {
      response = JSON.parse(xmlRequest.responseText);
    } catch (error) {
      // if not parsable, the blob contain a file and we don't need to check the route is successfull
      response = {};
    }
    return response;
  }

  public post(
    endpoint: string,
    body: unknown,
    params?: Record<string, string | number | boolean>,
    isFormData?: boolean
  ): Observable<unknown> {
    const reqOpts = {
      params: new HttpParams(),
      headers: null,
    };
    reqOpts.headers = isFormData ? { "x-access-token": this.sessionService.token } : this.appendHeaders(reqOpts);
    if (params) {
      for (const k in params) {
        if (params[k]) {
          reqOpts.params = reqOpts.params.set(k, params[k].toString());
        }
      }
    }
    const options = {
      headers: reqOpts.headers,
      params: reqOpts.params,
      responseType: "json" as const,
    };
    return this.http.post(this.url + "/" + endpoint, body, options).pipe(
      switchMap((result: ApiResponse) => {
        if (!result.success) {
          return throwError({
            global: false,
            message: result.message,
            additionalData: result.additionalData,
          } as AppError);
        } else {
          return of(result.data);
        }
      })
    );
  }

  public postReturnBlob(
    endpoint: string,
    body: unknown,
    params?: Record<string, string | number | boolean>,
    isFormData?: boolean
  ): Observable<unknown> {
    const reqOpts = {
      params: new HttpParams({ encoder: new CustomHttpParamEncoder() }),
      headers: null,
    };

    if (params) {
      reqOpts.params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
      for (const k in params) {
        if (params[k] !== undefined && params[k] !== null) {
          reqOpts.params = reqOpts.params.set(k, params[k].toString());
        }
      }
    }

    reqOpts.headers = isFormData ? new HttpHeaders({ "x-access-token": this.sessionService.token }) : this.appendHeaders(reqOpts);

    const options: {
      headers: HttpHeaders;
      params: HttpParams;
      responseType: "blob";
    } = {
      headers: reqOpts.headers,
      params: reqOpts.params,
      responseType: "blob",
    };

    return this.http.post(this.url + "/" + endpoint, body, options).pipe(
      switchMap((result: Blob) => {
        const apiResponse: ApiResponse = this.blobToApiResponse(result);
        if (apiResponse.success === false) {
          return throwError({
            global: false,
            message: apiResponse.message,
          } as AppError);
        } else {
          return of(result);
        }
      })
    );
  }

  public put(endpoint: string, body: unknown): Observable<unknown> {
    const reqOpts = {
      params: new HttpParams(),
      headers: null,
    };
    reqOpts.headers = this.appendHeaders(reqOpts);
    const options = {
      headers: reqOpts.headers,
      params: reqOpts.params,
      responseType: "json" as const,
    };
    return this.http.put(this.url + "/" + endpoint, body, options).pipe(
      switchMap((result: ApiResponse) => {
        if (!result.success) {
          return throwError({
            global: false,
            message: result.message,
            additionalData: result.additionalData,
          } as AppError);
        } else {
          return of(result.data);
        }
      })
    );
  }

  public deleteWithParams(endpoint: string, params?: Record<string, string | number | boolean>): Observable<unknown> {
    const reqOpts = {
      params: new HttpParams({ encoder: new CustomHttpParamEncoder() }),
      headers: null,
    };

    if (params) {
      reqOpts.params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
      for (const k in params) {
        if (params[k]) {
          reqOpts.params = reqOpts.params.set(k, params[k].toString());
        }
      }
    }
    reqOpts.headers = this.appendHeaders(reqOpts);
    const options = {
      headers: reqOpts.headers,
      params: reqOpts.params,
      responseType: "json" as const,
    };
    return this.http.request("delete", this.url + "/" + endpoint, options).pipe(
      switchMap((result: ApiResponse) => {
        if (!result.success) {
          return throwError({
            global: false,
            message: result.message,
          } as AppError);
        } else {
          return of(result.data);
        }
      })
    );
  }

  public delete(endpoint: string, body: unknown): Observable<unknown> {
    const reqOpts = {
      params: new HttpParams(),
      headers: null,
    };
    reqOpts.headers = this.appendHeaders(reqOpts);
    const options = {
      body,
      headers: reqOpts.headers,
      params: reqOpts.params,
      responseType: "json" as const,
    };
    return this.http.request("delete", this.url + "/" + endpoint, options).pipe(
      switchMap((result: ApiResponse) => {
        if (!result.success) {
          return throwError({
            global: false,
            message: result.message,
          } as AppError);
        } else {
          return of(result.data);
        }
      })
    );
  }

  private appendHeaders(reqOpts?: { headers: Record<string, unknown> }) {
    const headers = {
      ...reqOpts.headers,
      "Content-Type": "application/json",
    };

    if (this.sessionService.token) {
      headers["x-access-token"] = this.sessionService.token;
    }

    return headers;
  }

  public get serverPrefix(): string {
    return environment.production ? "001" : "002";
  }
}
