import { NgModule } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { UserRole } from "../models/account.interface";
import { Coding } from "../models/coding.interface";
import { KeyValue } from "../models/keyValues.model";
import { Patient } from "../models/patient.interface";
import { Practitioner } from "../models/practitioner.model";
import { ITranslation } from "../models/translation.interface";
import { LanguagesService } from "../providers/languages.service";
import { SessionService } from "../providers/session.service";
import { FHIRHelper } from "./FHIRhelper";
import { Tools } from "./tools";

@NgModule()
export class FormsData {
  constructor(
    private translateService: TranslateService,
    protected sessionService: SessionService,
    protected languagesService: LanguagesService
  ) {}

  public get DAYS(): KeyValue[] {
    return [1, 2, 3, 4, 5, 6, 7].map(
      (v) =>
        new KeyValue({
          key: moment().isoWeekday(v).locale(this.translateService.currentLang).format("dddd"),
          value: v,
        })
    );
  }

  public get MONTHS(): KeyValue[] {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
      (v) =>
        new KeyValue({
          key: moment().month(v).locale(this.translateService.currentLang).format("MMMM"),
          value: v + 1,
        })
    );
  }

  public get TIMING(): KeyValue[] {
    return [
      {
        key: "mydrugs.rising",
        value: TIMING.RISING,
      },
      {
        key: "mydrugs.morning",
        value: TIMING.MORNING,
      },
      {
        key: "mydrugs.noon",
        value: TIMING.NOON,
      },
      {
        key: "mydrugs.evening",
        value: TIMING.EVENING,
      },
      {
        key: "mydrugs.beding",
        value: TIMING.BEDING,
      },
    ].map((v) => new KeyValue(v));
  }
  public static get QUANTITY(): KeyValue[] {
    return [
      {
        key: "1/4",
        value: 0.25,
      },
      {
        key: "1/3",
        value: 0.33,
      },
      {
        key: "1/2",
        value: 0.5,
      },
      {
        key: "2/3",
        value: 0.66,
      },
      {
        key: "3/4",
        value: 0.75,
      },
    ].map((v) => new KeyValue(v));
  }

  public get EVERY_TYPES(): KeyValue[] {
    return [
      /*{
                key: this.translateService.instant('periodUnit.hour.singPlural'),
                value: 'h'
            },*/
      {
        key: this.translateService.instant("periodUnit.day.singPlural"),
        value: "d",
      },
      {
        key: this.translateService.instant("periodUnit.week.singPlural"),
        value: "w",
      },
      {
        key: this.translateService.instant("periodUnit.month.singPlural"),
        value: "m",
      },
    ].map((v) => new KeyValue(v));
  }

  public get PRESCIPTORS(): KeyValue[] {
    return [
      {
        key: this.translateService.instant("mydrugs.hospital"),
        value: "H",
      },
      {
        key: this.translateService.instant("mydrugs.familydoctor"),
        value: "D",
      },
      {
        key: this.translateService.instant("mydrugs.pharmacyst"),
        value: "P",
      },
    ].map((v) => new KeyValue(v));
  }

  public get TITLES(): string[] {
    return [
      this.translateService.instant("forms.title.doctor.short"),
      this.translateService.instant("forms.title.professor.short"),
      this.translateService.instant("forms.title.mister.short"),
      this.translateService.instant("forms.title.miss.short"),
    ];
  }

  public get QUALIFICATIONS(): Coding[] {
    return [
      {
        system: FHIRHelper.SYSTEM_SNOMED,
        code: "106292003",
        display: this.translateService.instant("forms.roles.106292003"),
      },
      {
        system: FHIRHelper.SYSTEM_SNOMED,
        code: "309343006",
        display: this.translateService.instant("forms.roles.309343006"),
      },
      {
        system: FHIRHelper.SYSTEM_SNOMED,
        code: "394572006",
        display: this.translateService.instant("forms.roles.394572006"),
      },
      {
        system: FHIRHelper.SYSTEM_SNOMED,
        code: "394738000",
        display: this.translateService.instant("forms.roles.394738000"),
      },
      {
        system: FHIRHelper.SYSTEM_SNOMED,
        code: "46255001",
        display: this.translateService.instant("forms.roles.46255001"),
      },
    ];
  }

  public get ROLES(): Coding[] {
    return [
      {
        code: UserRole.PRACTITIONER.toString(),
        display: this.translateService.instant("permission.role2"),
      },
      {
        code: UserRole.ADMIN.toString(),
        display: this.translateService.instant("permission.role3"),
      },
      {
        code: UserRole.ORAGNIZATION_ADMIN.toString(),
        display: this.translateService.instant("permission.role6"),
      },
    ];
  }

  public async getLanguages(): Promise<Coding[]> {
    const availableLangs: ITranslation[] = await this.languagesService.list().toPromise();
    const languageOptions: Coding[] = [];
    availableLangs.map((lang) => {
      languageOptions.push({
        code: lang.term,
        display: Tools.getTranslation(lang, this.sessionService.userLang, lang.term),
      });
    });
    return languageOptions;
  }

  public get GENDER(): Coding[] {
    return [
      { code: "male", display: this.translateService.instant("forms.male") },
      {
        code: "female",
        display: this.translateService.instant("forms.female"),
      },
    ];
  }

  public getPreferredLang(user: Patient | Practitioner): string {
    if (user && user.communication.length) {
      const preferred = user.communication.find((c) => c.preferred);
      if (preferred.language.coding.length) {
        return preferred.language.coding[0].code;
      }
    }
    return null;
  }

  public get PERIOD_UNITS(): KeyValue[] {
    return [
      {
        key: this.translateService.instant("periodUnit.day.singPlural"),
        value: "day",
      },
      {
        key: this.translateService.instant("periodUnit.week.singPlural"),
        value: "week",
      },
      {
        key: this.translateService.instant("periodUnit.month.singPlural"),
        value: "month",
      },
      {
        key: this.translateService.instant("periodUnit.year.singPlural"),
        value: "year",
      },
    ].map((v) => new KeyValue(v));
  }
  public static filterQuantities(keyword: string, valid: boolean, mustBeNumber: boolean): string[] {
    let word = keyword;
    if (word?.length === 0 || !valid) {
      word = "0";
    }
    if (valid && isNaN(Number(word)) && mustBeNumber) {
      word = word.split(" + ")[0];
      if (isNaN(Number(word))) {
        word = "1";
      }
    }
    const isNumber = !isNaN(Number(word));
    const nbKeyword = isNumber ? Number(word) : 0;
    const strKeyword = nbKeyword.toString();
    const isZero = nbKeyword === 0;
    const result = valid && !!keyword ? [keyword] : isZero ? [] : [strKeyword];
    const composed = [];
    for (const option of FormsData.QUANTITY) {
      if ((option.key as string).startsWith(strKeyword) && !result.includes(option.key)) {
        result.push(option.key);
      }
      const s = (isZero ? "" : strKeyword + " + ") + option.key;
      if (!result.includes(s)) {
        composed.push(s);
      }
    }
    result.push(...composed);
    return result;
  }
}

export const enum TIMING {
  RISING = "R",
  BEDING = "B",
  MORNING = "M",
  NOON = "N",
  EVENING = "E",
  NONE = "",
}

export const enum CONTROL_KEYS {
  name = "name",
  firstname = "firstname",
  birthDate = "birthDate",
  phone = "phone",
  mail = "mail",
  userLang = "userLang",
  gender = "gender",
  secretary = "secretary",
  internalId = "internalId",
  nurse = "nurse",
  doc = "doc",
  insurance = "insurance",
  insuranceId = "insuranceId",
}

export enum FORMS_MODE {
  CREATE,
  UPDATE,
  DISPLAY,
}

export const DEFAULT_DURATION = 15;

export function DEFAULT_HOUR(): Date {
  const date = new Date();
  date.setMinutes(date.getMinutes() - (date.getMinutes() % 5) + 5);
  date.setSeconds(0);
  return date;
}
