<div class="ui-dialog-container">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button mat-dialog-close color="warn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-dialog-content">
    <h2>{{ "alerts.preferenceTitle" | translate }}</h2>
    <mat-checkbox [(ngModel)]="showAlerts.levelOne" [checked]="showAlerts.levelOne">
      {{ "alerts.showLevelOneAlert" | translate }} </mat-checkbox
    ><br />
    <mat-checkbox [(ngModel)]="showAlerts.levelTwo" [checked]="showAlerts.levelTwo">
      {{ "alerts.showLevelTwoAlert" | translate }} </mat-checkbox
    ><br />
    <mat-checkbox [(ngModel)]="showAlerts.levelThree" [checked]="showAlerts.levelThree">
      {{ "alerts.showLevelThreeAlert" | translate }} </mat-checkbox
    ><br />
  </div>
  <div class="ui-dialog-actions">
    <button mat-raised-button color="primary" type="button" (click)="onSave()">
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
