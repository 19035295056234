import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { FHIRHelper } from "src/app/helpers/FHIRhelper";
import { ICareplan } from "src/app/models/careplans.interface";
import { IPrescriptionInfosResponse } from "src/app/models/prescription.interface";
import { CareplansService } from "src/app/providers/careplans.service";
import { PatientService } from "src/app/providers/patient.service";
import { SessionService } from "src/app/providers/session.service";

@Component({
  selector: "app-careplan-choice-dialog",
  templateUrl: "./careplan-choice-dialog.component.html",
  styleUrls: ["./careplan-choice-dialog.component.scss"],
})
export class CareplanChoiceDialogComponent implements OnInit {
  careplans: ICareplan[];
  patientId: string;
  selectedCareplanId: string;
  SYSTEM_COMUNICARE = FHIRHelper.SYSTEM_COMUNICARE;
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { prescription: IPrescriptionInfosResponse },
    private careplansService: CareplansService,
    private patientService: PatientService,
    private sessionService: SessionService,
    private dialogRef: MatDialogRef<CareplanChoiceDialogComponent>,
    private router: Router
  ) {
    this.patientId = this.data.prescription.patientCaremateId;
  }
  ngOnInit(): void {
    this.getCareplans();
  }

  public getCareplans(): void {
    this.careplansService
      .list(this.patientId)
      .pipe(first())
      .subscribe((careplans) => {
        this.careplans = careplans;
        this.loading = false;
      });
  }

  openAddCareplanDialog(): void {
    // const dialog = this.responsiveDialog.open(CareplanDialogComponent, {});
    this.patientService.getPatientUser(this.patientId).subscribe((patientUser) => {
      this.router.navigate(["patientRegistration", "careplan"], {
        state: {
          directToCareplan: true,
          patientUser: patientUser,
          careplan: undefined,
          registration: true,
          healthcareService: this.sessionService.currentService,
        },
      });
    });
  }

  public apply(): void {
    if (!this.selectedCareplanId) return;
    this.dialogRef.close(this.selectedCareplanId);
  }
}
