import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IRelatedPerson } from "src/app/models/related.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class RelatedApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Related", // Theme !
      ["dashboard/relatedinvite"], // Post route paths
      ["dashboard/related"], // Get route paths
      ["dashboard/related"], // Update route paths
      [] // Delete route paths
    );
  }

  public create(related: IRelatedPerson, reqParams: Record<string, string | boolean>): Observable<IRelatedPerson> {
    return this.api.post(this.createRoutes[0], related, reqParams) as Observable<IRelatedPerson>;
  }

  public getPatientRelateds(patientId: string): Observable<IRelatedPerson[]> {
    return this.api.get(this.readRoutes[0], { caremateId: patientId }) as Observable<IRelatedPerson[]>;
  }

  public update(related: IRelatedPerson): Observable<IRelatedPerson> {
    return this.api.put(this.updateRoutes[0], related) as Observable<IRelatedPerson>;
  }
}
