import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FiltersDisplayModule } from "src/app/components/filters-display/filters-display.module";
import { ItemFilterModule } from "src/app/components/item-filter/item-filter.module";
import { I18nModule } from "src/app/i18n.module";
import { BillingPageComponent } from "./components/billing-page/billing-page.component";
import { InvoiceListComponent } from "./components/invoice-list/invoice-list.component";
import { InvoiceTableComponent } from "./components/invoice-table/invoice-table.component";

@NgModule({
  declarations: [BillingPageComponent, InvoiceListComponent, InvoiceTableComponent, BillingPageComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    I18nModule,
    MatListModule,
    MatChipsModule,
    ItemFilterModule,
    FiltersDisplayModule,
    MatTooltipModule,
  ],
  exports: [BillingPageComponent, InvoiceListComponent, BillingPageComponent],
})
export class BillingModule {}
