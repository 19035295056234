import { Injectable, Type } from "@angular/core";
import { LastPatientWidgetComponent } from "../components/widgets/last-patient-widget/last-patient-widget.component";
import { PatientAlertNoTransmissionWidgetComponent } from "../components/widgets/patient-alert-no-transmission-widget/patient-alert-no-transmission-widget.component";
import { PatientAlertOrangeWidgetComponent } from "../components/widgets/patient-alert-orange-widget/patient-alert-orange-widget.component";
import { PatientAlertRedWidgetComponent } from "../components/widgets/patient-alert-red-widget/patient-alert-red-widget.component";
import { PatientLastActivityWidgetComponent } from "../components/widgets/patient-last-activity-widget/patient-last-activity-widget.component";
import { PatientListWidgetComponent } from "../components/widgets/patient-list-widget/patient-list-widget.component";
import { PendingPrescriptionsWidgetComponent } from "../components/widgets/pending-prescriptions-widget/pending-prescriptions-widget.component";
import { RenewablePrescriptionsWidgetComponent } from "../components/widgets/renewable-prescriptions-widget/renewable-prescriptions-widget.component";
import { TeleConsultationWidgetComponent } from "../components/widgets/tele-consultation-widget/tele-consultation-widget.component";
import { WidgetItem } from "../components/widgets/widget-item";
import { WidgetName, WidgetStored } from "../models/widget.interface";

@Injectable()
export class WidgetManagerService {
  public getWidgetItems(widgetStored: WidgetStored[]): WidgetItem[] {
    return widgetStored.map((w) => new WidgetItem(this.mapWidget(w.name), w.name, w.column, w.row));
  }

  public get defaultParameter(): { nbColumn: number; widgets: WidgetStored[] } {
    return {
      nbColumn: this.defaultNbColumn,
      widgets: this.defaultWidgets,
    };
  }

  public getAllWidgetName(
    showPatientAlertNoTransmission = false,
    showPendingPrescriptions = false,
    showRenewablePrescriptions = false
  ): string[] {
    const widgetList = [
      WidgetName.PATIENT_ALERT_ORANGE,
      WidgetName.PATIENT_ALERT_RED,
      WidgetName.TELE_CONSULTATION,
      WidgetName.LAST_PATIENT,
      WidgetName.PATIENT_LAST_ACTIVITY,
      WidgetName.PATIENT_LIST,
    ];
    if (showPatientAlertNoTransmission) {
      widgetList.push(WidgetName.PATIENT_ALERT_NO_TRANSMISSION);
    }
    if (showPendingPrescriptions) {
      widgetList.push(WidgetName.PENDING_PRESCRIPTIONS);
    }
    if (showRenewablePrescriptions) {
      widgetList.push(WidgetName.RENEWABLE_PRESCRIPTIONS);
    }
    return widgetList;
  }

  public getAllVisibleWidgetName(
    showPatientAlertNoTransmission = false,
    showPendingPrescriptions = false,
    showRenewablePrescriptions = false
  ): string[] {
    const widgetList = [
      WidgetName.PATIENT_ALERT_ORANGE,
      WidgetName.PATIENT_ALERT_RED,
      WidgetName.TELE_CONSULTATION,
      WidgetName.LAST_PATIENT,
      WidgetName.PATIENT_LAST_ACTIVITY,
    ];
    if (showPatientAlertNoTransmission) {
      widgetList.push(WidgetName.PATIENT_ALERT_NO_TRANSMISSION);
    }
    if (showPendingPrescriptions) {
      widgetList.push(WidgetName.PENDING_PRESCRIPTIONS);
    }
    if (showRenewablePrescriptions) {
      widgetList.push(WidgetName.RENEWABLE_PRESCRIPTIONS);
    }
    return widgetList;
  }

  public mapWidget(widgetName: string): Type<unknown> {
    switch (widgetName) {
      case WidgetName.PATIENT_ALERT_ORANGE:
        return PatientAlertOrangeWidgetComponent;
      case WidgetName.PATIENT_ALERT_RED:
        return PatientAlertRedWidgetComponent;
      case WidgetName.TELE_CONSULTATION:
        return TeleConsultationWidgetComponent;
      case WidgetName.LAST_PATIENT:
        return LastPatientWidgetComponent;
      case WidgetName.PATIENT_LAST_ACTIVITY:
        return PatientLastActivityWidgetComponent;
      case WidgetName.PATIENT_LIST:
        return PatientListWidgetComponent;
      case WidgetName.PATIENT_ALERT_NO_TRANSMISSION:
        return PatientAlertNoTransmissionWidgetComponent;
      case WidgetName.PENDING_PRESCRIPTIONS:
        return PendingPrescriptionsWidgetComponent;
      case WidgetName.RENEWABLE_PRESCRIPTIONS:
        return RenewablePrescriptionsWidgetComponent;
      default:
        return undefined;
    }
  }

  private get defaultWidgets(): WidgetStored[] {
    return [
      {
        name: WidgetName.PATIENT_ALERT_ORANGE,
        column: 0,
      },
      {
        name: WidgetName.PATIENT_ALERT_RED,
        column: 0,
      },
      {
        name: WidgetName.TELE_CONSULTATION,
        column: 1,
      },
      {
        name: WidgetName.LAST_PATIENT,
        column: 2,
      },
      {
        name: WidgetName.PATIENT_LAST_ACTIVITY,
        column: 2,
      },
      {
        name: WidgetName.PATIENT_LIST,
        column: 1,
      },
    ];
  }

  private get defaultNbColumn(): number {
    return 3;
  }
}
