<div class="ui-dialog-container">
  <div class="ui-dialog-toolbar">
    <button mat-icon-button mat-dialog-close color="warn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="ui-dialog-content">
    <ng-container *ngIf="!loading; else spinner">
      <h3>{{ "careplanChoiceDialog.title" | translate }}</h3>

      <div class="flex">
        <!-- Add your content here -->
        <mat-form-field *ngIf="careplans?.length > 0">
          <mat-label>{{ "careplanChoiceDialog.label" | translate }}</mat-label>
          <mat-select [(value)]="selectedCareplanId">
            <mat-option *ngFor="let careplan of careplans" [value]="careplan.identifier | identifier : SYSTEM_COMUNICARE">
              {{ careplan.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ui-separator>{{ "common.or" | translate }}</ui-separator>

        <button mat-raised-button color="primary" (click)="openAddCareplanDialog()">
          {{ "careplanChoiceDialog.assignCareplan" | translate }}
        </button>
      </div>
    </ng-container>
  </div>
  <div class="ui-dialog-actions">
    <!-- Add your actions here -->
    <button mat-stroked-button mat-dialog-close>{{ "common.cancel" | translate }}</button>
    <button mat-raised-button color="primary" (click)="apply()" [disabled]="!selectedCareplanId">{{ "common.save" | translate }}</button>
  </div>
</div>

<ng-template #spinner>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
