<!-- GLOBAL SEARCH -->
<div class="toolbar">
  <div class="left">
    <mat-form-field>
      <input matInput [placeholder]="'itemFilter.searchPlaceHolder' | translate" #searchInput />
      <button
        type="button"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchInput.value = ''"
        [style.visibility]="searchInput.value ? 'visible' : 'hidden'"
        #searchClearBtn
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="spacer"></div>
  <div class="right">
    <button
      mat-stroked-button
      (click)="clearFilter()"
      matTooltip="{{ 'itemFilter.clearAll' | translate }}"
      *ngIf="(responsiveService.isHandset$ | async) === false"
    >
      <img class="custom-icon" src="assets/images/custom-icon/clear-filter.png" />
    </button>
  </div>
</div>

<!-- Active filter list -->
<app-filters-display *ngIf="!isMobile" [filters]="this.filters" [parent]="'patientList'" (removeFilter)="applyFilter($event)">
</app-filters-display>

<mat-card [ngClass]="{ 'm-0': isMobile }">
  <!-- 
    We use the same dataSource for app-invoice-list and app-invoice-table.
    When switching from desktop to phone mode, the data are not rendered in the table if we just use the datasource 
    Therefore, we pass "data" to this two children.
  -->
  <!-- List for smartphone view -->
  <app-invoice-list *ngIf="isMobile" [data]="data"></app-invoice-list>
  <!-- Table for desktop view -->
  <app-invoice-table
    *ngIf="!isMobile"
    (sortChanged)="onSortChanged($event)"
    (filterChanged)="applyFilter($event)"
    [dataSource]="dataSource"
    [data]="data"
  ></app-invoice-table>
  <!-- Paginator is common to both views -->
  <mat-paginator [length]="totalCount" [pageSize]="25" [pageSizeOptions]="[25, 50, 100, 250]"> </mat-paginator>
</mat-card>
