import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { first } from "rxjs/operators";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../components/confirmation-dialog/confirmation-dialog.component";
import { IGroup } from "../components/patient-careplans/patient-careplans.component";
import { Base64Helper } from "../helpers/Base64Helper";
import { FHIRHelper } from "../helpers/FHIRhelper";
import { Tools } from "../helpers/tools";
import { ICareplan } from "../models/careplans.interface";
import { Patient, PatientUser } from "../models/patient.interface";
import { Reference } from "../models/reference.interface";
import { IRelatedPerson } from "../models/related.interface";
import { PatientService } from "./patient.service";

@Injectable({
  providedIn: "root",
})
export class patientRegistrationService {
  public go2patientPage: boolean;
  public patientSaved: Patient;
  public previousUrl: string;
  public isForceUpdateUser: boolean;
  public isCreation: boolean;
  public source: string;
  public patientUser: PatientUser;
  public careplan: ICareplan;
  public careplans: IGroup[];
  public createNewCareplan = true;
  public includeBirthdateAndGenderForm = false;
  public registration: boolean;
  public healthcareService: Reference;
  public isLastStep = false;

  public onPatientCreated$ = new Subject<{ patientSaved: Patient; forceUpdateUser?: string }>();
  public onRelatedCreated$ = new Subject<IRelatedPerson>();
  public onNeedReferentChanged$ = new Subject<boolean>();
  public onNeedCareplanChanged$ = new Subject<boolean>();

  public onNextStep$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private patientService: PatientService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public onFinish(): void {
    this.translateService
      .get("common.saveSuccess")
      .pipe(first())
      .subscribe((trans) => {
        if (!this.isForceUpdateUser) {
          if (this.patientSaved?.noSms) {
            trans += " Authocode : " + this.patientService.computeAuthCode(this.patientSaved);
          }
          const dialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            data: {
              message: trans,
              type: ConfirmationDialogType.SUCCESS,
              showButtons: this.go2patientPage === undefined,
            },
          });

          dialog.afterClosed().subscribe((res) => {
            // if preference is already set
            if (this.go2patientPage === true) {
              this.navigateToPatientPage();
            } else {
              // redirect if wanted
              if (res) {
                this.navigateToPatientPage();
              } else {
                this.back();
              }
            }
          });
        } else {
          this.back();
          this.snackBar.open(trans, "ok", { duration: 3000 });
        }
      });
  }

  private navigateToPatientPage(): void {
    const patient = Tools.isDefined(this.patientSaved) ? this.patientSaved : this.patientUser.patient;
    const patientId = FHIRHelper.getMainIdentifier(patient).value;
    const b64Id = Base64Helper.utf8_to_b64(patientId);
    this.router.navigateByUrl(`/patient;id=${b64Id}`, {
      state: undefined,
    });
  }

  public back(): void {
    this.router.navigateByUrl(this.previousUrl ?? "/");
  }
}
