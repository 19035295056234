import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { BehaviorSubject, combineLatest, zip } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { FileLogger } from "src/app/helpers/fileLogger";
import { HelpData } from "src/app/helpers/helpData";
import { Tools } from "src/app/helpers/tools";
import { DataType, Filter } from "src/app/models/filter.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { Quantity } from "src/app/models/quantity.model";
import { Reference } from "src/app/models/reference.interface";
import { IVitalProfileDefinition } from "src/app/models/vitalProfileDefinition.interface";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { VitalProfileDefinitionsService } from "src/app/providers/vital-profile-definition.service";
import { VitalSignService } from "src/app/providers/vital-sign.service";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { ValueArrayDialogComponent } from "../value-array-dialog/value-array-dialog.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { VitalSignsHistoricComponent } from "./vital-signs-historic/vital-signs-historic.component";
import { VitalSignDataSource } from "./vital-signs-list-datasource";
export interface CsvRow {
  date: string;
  [key: string]: unknown; // allow any string properties
}
@Component({
  selector: "app-patient-vital-signs",
  templateUrl: "./patient-vital-signs.component.html",
  styleUrls: ["./patient-vital-signs.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class PatientVitalSignsComponent extends WidgetBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  private hasData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.patientId = pu.user.caremateIdentifier;
      this.vitalSigns = pu.user.vitalProfile?.map((vs) => new Quantity(vs, this.sessionService));
      this.initDataAndAutoRefresh(pu.user.caremateIdentifier, this.preferenceService.getAutoRefreshWidget());
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Quantity>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("focus") target: ElementRef;

  public scrollAfterDataInit = false;
  public displayedColumns: string[] = ["name", "value", "unit", "action"];
  public dataSource: VitalSignDataSource;
  public intakeDrugs: Notification[] = [];
  private currentPageSize: number;
  public defaultSort: MatSortable = {
    id: "default",
    start: "asc",
    disableClear: true,
  };

  public patientId: string;
  public editElementCode: string;
  public vpdArray: IVitalProfileDefinition[] = [];

  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;
  public vitalSigns: Quantity[] = [];
  private refreshInterval;

  public formValue = this.formBuilder.group({});
  public currentLang: string;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match

  public actions: WidgetActionConfig[] = [
    {
      type: "button",
      icon: "file_download",
      label: "observation.export",
      ariaLabel: "icon button with a download icon",
      condition$: combineLatest([this.hasData$, this.userService.isAuthorized("dashboard/exportVitalSignsForPatient", "GET")]).pipe(
        map(([hasData, isAuthorized]) => hasData && isAuthorized)
      ),
      action: (): unknown => this.onExportVitalSigns(),
    },
    {
      type: "button",
      icon: "history",
      label: "btn.history",
      ariaLabel: "icon button with a history icon",
      condition$: this.userService.isAuthorized("vitalSignHistoric", "GET"),
      action: (): unknown => this.showHistoric(),
    },
  ];

  constructor(
    private preferenceService: PreferenceService,
    private responsiveDialog: ResponsiveDialogService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private vsService: VitalSignService,
    private sessionService: SessionService,
    public helpData: HelpData,
    private patientService: PatientService,
    private vitalProfileDefinitionService: VitalProfileDefinitionsService,
    private userService: UserService,
    protected responsiveService: ResponsiveService
  ) {
    super(responsiveService);
  }

  ngOnInit(): void {
    this.dataSource = new VitalSignDataSource();
    this.currentLang = this.sessionService.userLang;
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLang = this.sessionService.userLang;
    });

    // Triggered after adding careplan to refresh the vitalSigns
    this.sessionService.refreshVitalSignsTile.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initData(this.patientId);
    });
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearAutoRefresh();
  }

  private initDataAndAutoRefresh(patientId: string, _autoRefreshIntervalMs = 60000) {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    // this.refreshInterval = setInterval(() => { this.initData(patientId); }, autoRefreshIntervalMs);
    this.initData(patientId);
  }

  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  private initData(patientId: string) {
    const getPatientUser = this.patientService.getPatientUser(patientId);
    const getDefList = this.vitalProfileDefinitionService.list(this.patientId);

    zip(getPatientUser, getDefList)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([pu, defList]) => {
        this.vpdArray = defList;

        // if array not initialized yet
        if (!this.vitalSigns?.length) {
          this.vitalSigns = [];
        }

        this.vitalSigns = defList.map((def) => {
          const valueFound = pu.user.vitalProfile?.find((vp) => def.code === vp.code);

          return new Quantity(
            {
              code: def.code,
              value: Tools.isDefined(valueFound?.value) ? valueFound?.value : null,
              valueArray: valueFound?.valueArray,
              unit: def?.unit ? def.unit[this.sessionService.userLang] : undefined,
            },
            this.sessionService,
            def
          );
        });

        // if vitals sign is not set by patient we create empty quantity (only saved if we change it)
        defList.forEach((vpd) => {
          const isPresent = this.vitalSigns.find((vs) => vs.code === vpd.code);
          if (!isPresent) {
            this.vitalSigns.push(
              new Quantity(
                {
                  code: vpd.code,
                  value: 0,
                  valueArray: undefined,
                  unit: undefined,
                  system: undefined,
                },
                this.sessionService
              )
            );
          }
        });

        const group: unknown = {};
        this.vitalSigns?.forEach((vs) => {
          group[vs.code] = new UntypedFormControl(vs.value, this.valueValidator(vs.code));
        });

        this.formValue = this.formBuilder.group(group);
        this.loadDataSource();

        this.loadPreferences();
      });
  }

  private initPaginatorAndSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator?.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference(false);
      }
      this.currentPageSize = this.paginator?.pageSize;
    });
  }

  private loadDataSource() {
    if (this.dataSource && this.vitalSigns) {
      this.dataSource.loadData(this.vitalSigns);
      this.hasData$.next(this.dataSource.data.length ? true : false);
      this.loading = false;
    }
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  private clearFilter() {
    this.sort.sort(this.defaultSort);
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
  }

  /**
   * Preferences
   */
  private updatePreference(updateFocus: boolean) {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_VITAL_SIGN_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (updateFocus) {
          this.updateLastFocus();
        }
      });
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.PATIENT_VITAL_SIGN_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
        if (this.scrollAfterDataInit && this.sessionService.globalPref?.keepLayoutFromPatientPage) {
          this.scroll();
        }
      });
  }

  /**
   * TODO : when we move mouse after click, scrollIntoView seems to not work
   */
  private scroll() {
    if (this.target) {
      setTimeout(() => {
        this.target.nativeElement.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }

  /**
   * TODO : NONE BIG MODE YET
   */
  private updateLastFocus() {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_PAGE,
        parameters: {
          lastFocusWidgetName: PatientWidgetName.VITAL_SIGNS,
        } as PatientPageParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  public save(el: Quantity): void {
    if (!el?.code || this.formValue.get(el.code).hasError("valueValidator")) {
      FileLogger.warn("PatientVitalSignsComponent", "validator error", this.editElementCode, "none");
      return;
    }
    const newValue = this.formValue.get(el.code).value;
    el.castValue2NbrAndChangeIt(newValue);
    const practitionerReference: Reference = {
      display: this.sessionService.account.displayName,
      reference: this.sessionService.account.caremateIdentifier,
    };
    this.editElementCode = undefined;
    this.vsService.save(el, this.patientId, practitionerReference).pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  public valueValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const v: number = Tools.isDefined(control.value) && control.value !== "" ? +control.value : null;
      if (!Number.isFinite(v) || (Tools.isNotDefined(this.getCurrentDefinition(controlName)?.min) && v < 0)) {
        return { valueValidator: true };
      }

      // verication step : search for min and max values
      // min can be equal to 0, so we must use Tools.isDefined
      if (Tools.isDefined(this.getCurrentDefinition(controlName)?.min)) {
        if (v < this.getCurrentDefinition(controlName)?.min) {
          return { valueValidator: true };
        }
      }

      // max can be equal to 0, so we must use Tools.isDefined
      if (Tools.isDefined(this.getCurrentDefinition(controlName)?.max)) {
        if (v > this.getCurrentDefinition(controlName)?.max) {
          return { valueValidator: true };
        }
      }

      return null;
    };
  }

  public showHistoric(): void {
    this.dialog.open(VitalSignsHistoricComponent, {
      maxWidth: "auto",
      data: {
        patientId: this.patientId,
        definitions: this.vpdArray,
      },
    });
  }

  public openRefValueHelp(): void {
    this.responsiveDialog.open(
      GlobalHelpDialogComponent,
      {
        data: { slides: this.helpData.patientVitalsignsHelp },
        disableClose: true,
      },
      { maxWidth: "80vw" }
    );
  }

  public getCurrentDefinition(code: string): IVitalProfileDefinition {
    return this.vpdArray.find((vpd) => code === vpd.code);
  }

  public openValueArrayDialog(vitalSign: Quantity): void {
    const dialog = this.dialog.open(ValueArrayDialogComponent, {
      data: { vitalSign },
      disableClose: false,
      width: "100%",
    });

    dialog.afterClosed().subscribe((data: { vitalSign: Quantity; mustUpdate: boolean }) => {
      if (data?.vitalSign && data?.mustUpdate) {
        const practitionerReference: Reference = {
          display: this.sessionService.account.displayName,
          reference: this.sessionService.account.caremateIdentifier,
        };
        this.vsService.save(data?.vitalSign, this.patientId, practitionerReference).pipe(takeUntil(this.onDestroy$)).subscribe();
      }
    });
  }

  public onExportVitalSigns(): void {
    this.vsService.exportVitalSigns(this.patientId, this.sessionService.userLang);
  }
}
