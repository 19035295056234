import { Pipe, PipeTransform } from "@angular/core";
import { Identifier } from "../models/identifier.interface";

@Pipe({ name: "identifier" })
export class IdentifierPipe implements PipeTransform {
  /**
   * Pipe to extract an identifier value from an array of identifiers based on a given system.
   * @param identifiers Array of identifiers
   * @param system System to search for
   */
  transform(identifiers: Identifier[], system: string): string | undefined {
    return identifiers?.find((id) => id.system === system)?.value;
  }
}
