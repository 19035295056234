import { Observable } from "rxjs";
import { MatTableDataSourceBackendExtended } from "src/app/helpers/datasource-backend";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { IPrescription, RENEW_TIMING } from "src/app/models/prescription.interface";
import { PrescriptionService } from "src/app/providers/prescription.service";

export class PrescriptionsToBeRenewedDataSource extends MatTableDataSourceBackendExtended<IPrescription> {
  public rawData$ = this.dataSubject.asObservable();
  constructor(private prescriptionService: PrescriptionService) {
    super();
  }

  public data(params: DataSourceBackendParam & { timing: RENEW_TIMING; services?: string }): Observable<IPrescription[]> {
    return this.prescriptionService.listPrescriptionsToBeRenewedInfos(params);
  }
}
