<!-- TABLE -->
<mat-table [dataSource]="data" matSort matSortActive="patientName" matSortDirection="asc" matSortDisableClear class="full-width-table">
  <!-- Name Column -->
  <ng-container matColumnDef="lastname">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "model.patient.name" | translate }}
      <app-item-filter
        fieldName="{{ 'model.patient.name' | translate }}"
        [translateFieldKey]="'model.patient.name'"
        propertyName="lastname"
        [dataType]="dataType.TEXT"
        [defaultValue]="getFilter('lastname')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.lastname }}</mat-cell>
  </ng-container>

  <!-- Firstname Column -->
  <ng-container matColumnDef="firstname">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "model.patient.firstname" | translate }}
      <app-item-filter
        fieldName="{{ 'model.patient.firstname' | translate }}"
        [translateFieldKey]="'model.patient.firstname'"
        propertyName="firstname"
        [dataType]="dataType.TEXT"
        [defaultValue]="getFilter('firstname')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.firstname }}</mat-cell>
  </ng-container>

  <!-- SSIN Column -->
  <ng-container matColumnDef="ssin">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "forms.SSIN" | translate }}
      <app-item-filter
        fieldName="{{ 'forms.SSIN' | translate }}"
        [translateFieldKey]="'forms.SSIN'"
        propertyName="ssin"
        [dataType]="dataType.TEXT"
        [defaultValue]="getFilter('ssin')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.ssin }}</mat-cell>
  </ng-container>

  <!-- Birth Date -->
  <ng-container matColumnDef="birthDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.birthdate" | translate }}
      <app-item-filter
        fieldName="{{ 'table.birthdate' | translate }}"
        [translateFieldKey]="'table.birthdate'"
        propertyName="birthDate"
        [dataType]="dataType.DATE"
        [defaultValue]="getFilter('birthDate')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.birthDate | date }}</mat-cell>
  </ng-container>

  <!-- Category -->
  <ng-container matColumnDef="category">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.category" | translate }}
      <app-item-filter
        fieldName="{{ 'table.category' | translate }}"
        [translateFieldKey]="'table.category'"
        propertyName="category"
        [dataType]="dataType.TEXT"
        [defaultValue]="getFilter('category')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.category }}</mat-cell>
  </ng-container>

  <!-- Start Date -->
  <ng-container matColumnDef="startDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.start" | translate }}
      <app-item-filter
        fieldName="{{ 'table.start' | translate }}"
        [translateFieldKey]="'table.start'"
        propertyName="startDate"
        [dataType]="dataType.DATE"
        [defaultValue]="getFilter('startDate')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.startDate | date }}</mat-cell>
  </ng-container>

  <!-- End Date -->
  <ng-container matColumnDef="endDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.end" | translate }}
      <app-item-filter
        fieldName="{{ 'table.end' | translate }}"
        [translateFieldKey]="'table.end'"
        propertyName="endDate"
        [dataType]="dataType.DATE"
        [defaultValue]="getFilter('endDate')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.endDate | date }}</mat-cell>
  </ng-container>

  <!-- Code -->
  <ng-container matColumnDef="code">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.code" | translate }}
      <app-item-filter
        fieldName="{{ 'table.code' | translate }}"
        [translateFieldKey]="'table.code'"
        propertyName="code"
        [dataType]="dataType.TEXT"
        [defaultValue]="getFilter('code')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.code }}</mat-cell>
  </ng-container>

  <!-- Amount -->
  <ng-container matColumnDef="totalPrice">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.amount" | translate }}
      <app-item-filter
        fieldName="{{ 'table.amount' | translate }}"
        [translateFieldKey]="'table.amount'"
        propertyName="totalPrice"
        [dataType]="dataType.TEXT"
        [defaultValue]="getFilter('totalPrice')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.totalPrice }}</mat-cell>
  </ng-container>

  <!-- Status -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "table.status" | translate }}
      <app-item-filter
        fieldName="{{ 'table.status' | translate }}"
        [translateFieldKey]="'table.status'"
        propertyName="billingStatus"
        translatePrefix="billing.status"
        [dataType]="dataType.CHOICE"
        [defaultValue]="getFilter('billingStatus')"
        (applyFilter)="applyFilter($event)"
      >
      </app-item-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let row"
      ><span class="status-box" [ngClass]="billingStatusClasses[row.statut]">
        {{ "billing.status." + row.statut | translate }}
      </span></mat-cell
    >
  </ng-container>

  <!-- Actions -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>{{ "table.action" | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button mat-icon-button>
        <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-paper-plane"></mat-icon>
      </button>
      <button mat-icon-button>
        <mat-icon class="fa-icon" fontSet="fas" fontIcon="fa-download"></mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <!-- Header & Rows -->
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
