import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable, of } from "rxjs";
import { concatMap } from "rxjs/operators";
import {
  IDrugIntakeNotif,
  NOTIFICATION_STATUS,
  NOTIFICATION_SYSTEM_STATUS,
  NOTIFICATION_TYPE,
  Notification,
} from "src/app/models/notifications.interface";
import { ArrayHelper } from "../helpers/ArrayHelper";
import { FileLogger } from "../helpers/fileLogger";
import { Tools } from "../helpers/tools";
import { AppError } from "../models/app-error.interface";
import { SCHEDULE_PERIOD } from "../models/careplans.interface";
import { IDrugInfo, IExportDrugIntakePdfBody } from "../models/drugInfo.interface";
import { ACTION_DRUGS_TYPE, IDrugAction, IDrugsHistoric } from "../models/drugsHistoric.interface";
import { ActionStatusEntity, CycleSchema, Entity, EntityDrug, Entitylink, IDrugStock, IEntitylink } from "../models/entity.interface";
import { KeyValue } from "../models/keyValues.model";
import { Reference } from "../models/reference.interface";
import { IQuantities, ITiming } from "../models/sharedInterfaces";
import { Timing } from "../models/sharedModels.model";
import { IStepwise } from "../models/stepwiseDrug.interface";
import { TIMING_CODES, TimingData } from "../models/timingData.model";
import { DrugsApiService } from "./api/drugs-api.service";
import { DrugSchemaService } from "./drugSchema.service";
import { SessionService } from "./session.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class DrugsService {
  constructor(
    private drugsApiService: DrugsApiService,
    private translateService: TranslateService,
    private sessionsService: SessionService,
    private userService: UserService
  ) {}

  public static getCibledNotification(drugId: string, notifications: Notification[]): Notification[] {
    return notifications.filter((n) => n.appId === drugId && moment(n.time).isBefore(moment()));
  }

  public static drugTaken(
    entity: IEntitylink,
    historic: IDrugsHistoric[],
    intakeDrugs: Notification[]
  ): {
    unknow: number;
    take: number;
    untake: number;
  } {
    const asNecessary = entity?.entityData?.frequency?.asNecessary;
    const entityId = entity._id;
    const drugHistoric = historic.filter((v) => v.entityId === entityId).reverse();
    const cibledNotif = DrugsService.getCibledNotification(entityId, intakeDrugs).filter(ArrayHelper.onlyExtraUniqueNotifications);
    const taken = cibledNotif.filter(
      (n) => n.status === NOTIFICATION_STATUS.ACCEPTED && (asNecessary ? n?.unscheduledIntake === true : n?.unscheduledIntake !== true)
    ).length;
    const total = asNecessary ? taken : DrugsService.getSupposedIntakes(entity.entityData, drugHistoric).length;
    const notTaken = cibledNotif.filter((n) => n.status === NOTIFICATION_STATUS.REJECTED).length;
    const unknow = total - taken - notTaken;
    const percentGreen = total ? (taken / total) * 100 : 0;
    const percentOrange = total ? (unknow / total) * 100 : 100;
    return {
      unknow: percentOrange,
      take: percentGreen,
      untake: total ? (notTaken / total) * 100 : 0,
    };
  }

  public static combineSupposedAndRealIntakes(supposedIntakes: Notification[], receivedIntakes: Notification[]): Notification[] {
    const combined: Notification[] = [];
    let prevDate = null;
    let i = -1;
    let k = 0;
    for (const s of supposedIntakes) {
      ++i;
      const d = moment(s.time);
      const date = d.format("YYYY MM DD");

      if (prevDate && prevDate === date) {
        continue;
      }
      prevDate = date;
      const realsSameDay = [];
      const firstK = receivedIntakes.findIndex((r) => moment(r.time).format("YYYY MM DD") === date);
      if (firstK > -1) {
        // Check that we did not miss any "taken" or "not taken" information:
        // (it could happen if the users made weird timing changes in between validating intakes)
        while (firstK > k) {
          combined.push(receivedIntakes[k]);
          ++k;
        }
        for (const r of receivedIntakes.slice(firstK)) {
          if (moment(r.time).format("YYYY MM DD") === date) {
            realsSameDay.push(r);
            ++k;
          } else {
            break;
          }
        }
        ++k;
      }
      const supposedSameDay = [];
      for (const r of supposedIntakes.slice(i)) {
        if (moment(r.time).format("YYYY MM DD") === date) {
          supposedSameDay.push(r);
        } else {
          break;
        }
      }
      // if there no field "moment" in the real notifications, it means it's the
      // old system and we can't use the supposed intakes. We will just rely on the
      // 'unknown' notifications saved in db.
      const oldSystem: boolean = realsSameDay.length > 0 && !Tools.isDefined(realsSameDay[0].moment);
      if (realsSameDay.length >= supposedSameDay.length || oldSystem) {
        combined.push(...realsSameDay);
      } else {
        // try to find which 'real' notif corresponds to which 'supposed' one
        const foundIds: string[] = [];
        for (const s2 of supposedSameDay) {
          const same = realsSameDay.find((r) => r.moment === s2.moment);
          // keep the real one if we have it
          if (same) {
            combined.push(same);
            foundIds.push(same._id);
          }
          // else use the supposed one
          else {
            combined.push(s2);
          }
        }
        // Check that we did not miss any "taken" or "not taken" information:
        // (it could happen if the users made weird timing changes in between validating intakes)
        const missed = realsSameDay.filter((r) => r.status !== NOTIFICATION_STATUS.NONE && !foundIds.includes(r._id));
        if (missed.length > 0) {
          combined.push(...missed);
        }
      }
    }
    // add not corresponding intakes (i.e unscheduled intakes)
    receivedIntakes.forEach((r) => {
      if (combined.findIndex((c) => c.time === r.time) === -1) {
        combined.push(r);
      }
    });
    return combined.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf());
  }

  public static getSupposedIntakes(drug: EntityDrug, historic: IDrugsHistoric[]): Notification[] {
    const intakes: Notification[] = [];
    let currentTiming = drug.frequency;
    let startDate = moment(drug.frequency.boundsPeriod?.start).startOf("day");
    let endDate = moment().endOf("day");
    if (currentTiming.boundsPeriod?.end) {
      const drugEnd = moment(currentTiming.boundsPeriod.end).endOf("day");
      if (endDate.isAfter(moment(drugEnd))) {
        endDate = drugEnd;
      }
    }
    const cycle = drug.cycle;
    const stepwises = drug.stepwiseSchema?.stepwises;
    // Makes sure the historic is sorted with newest first
    historic.sort((a: IDrugsHistoric, b: IDrugsHistoric) => {
      if (a.date === b.date) {
        return 0;
      }
      if (moment(a.date).isAfter(moment(b.date))) {
        return -1;
      } else {
        return 1;
      }
    });

    for (const h of historic) {
      const freqActions = h.actions.filter(
        (a) =>
          a.actionType === ACTION_DRUGS_TYPE.UPDATE_FREQUENCY ||
          a.actionType === ACTION_DRUGS_TYPE.UPDATE_MOMENT ||
          a.actionType === ACTION_DRUGS_TYPE.UPDATE_DATE
      );
      // if we have frequency changes, we need to split the intakes into different periods:
      if (freqActions.length > 0) {
        const changeDate = moment(h.date).startOf("day");
        // compute the previous period's intake
        intakes.push(...this.computeIntakes(changeDate, endDate, currentTiming, cycle, stepwises));
        // start setting up the new period:
        endDate = changeDate.subtract(1, "days");
        currentTiming = this.getOldTimingFromHistoric(currentTiming, freqActions);
        startDate = moment(currentTiming.boundsPeriod.start).startOf("day");
        if (endDate.isBefore(startDate)) {
          endDate = startDate;
        }
      }
    }
    intakes.push(...this.computeIntakes(startDate, endDate, currentTiming, cycle, stepwises));
    return intakes;
  }

  private static computeIntakes(
    startDate: moment.Moment,
    endDate: moment.Moment,
    timing: ITiming,
    cycle?: CycleSchema,
    stepwises?: IStepwise[]
  ): Notification[] {
    const intakesNotifcations: Notification[] = [];
    if (stepwises?.length > 0) {
      intakesNotifcations.push(...this.computeIntakesStepwiseDrug(stepwises, timing, startDate, endDate));
    } else {
      const intakesDates: string[] =
        cycle && cycle.cycle.length > 0
          ? DrugSchemaService.getCycleInstances(cycle, timing, startDate, endDate)
          : TimingData.getTimingInstances(timing, startDate, endDate);
      const intakesPerDay = TimingData.getIntakesPerDay(timing);
      for (const d of intakesDates.reverse()) {
        for (const t of intakesPerDay) {
          const notif: Notification = {
            account: "",
            systemId: 0,
            appId: "",
            active: true,
            ntype: NOTIFICATION_TYPE.DRUG,
            message: "",
            time: d,
            moment: t,
            trigger: "",
            at: "",
            start: "",
            end: "",
            systemStatus: NOTIFICATION_SYSTEM_STATUS.SCHEDULED,
            status: NOTIFICATION_STATUS.NONE,
            comment: "",
            quantity: this.getMomentsQuantity(t, timing.quantities), //prendre la quantities [t transformé] //quantity//
          };
          intakesNotifcations.push(notif);
        }
      }
    }
    return intakesNotifcations;
  }

  private static computeIntakesStepwiseDrug(
    stepwises: IStepwise[],
    timing,
    startDate: moment.Moment,
    endDate: moment.Moment
  ): Notification[] {
    const notif: Notification[] = [];
    stepwises.forEach((stepwise) => {
      const startDay = moment(timing.boundsPeriod.start).add(stepwise.startDay, "d");
      stepwise.days.forEach((d, i) => {
        const frequency: Timing = {
          boundsPeriod: {
            start: moment(Tools.clone(startDay)).add(i, "d").format(),
          },
          period: 1,
          periodUnits: "d",
        };
        frequency.boundsPeriod.end = frequency.boundsPeriod.start;
        d.moment.forEach((m) => {
          frequency.timingCode = TIMING_CODES.find((t) => t.display === m.timingCode).value;
          const timingInstances = TimingData.getTimingInstances(frequency, startDate, endDate);
          m.drugs.forEach(() => {
            const intakesPerDay = TimingData.getIntakesPerDay(frequency);
            timingInstances.forEach((instance) => {
              for (const t of intakesPerDay) {
                notif.push({
                  account: "",
                  systemId: 0,
                  appId: "",
                  active: true,
                  ntype: NOTIFICATION_TYPE.DRUG,
                  message: "",
                  time: instance,
                  moment: t,
                  trigger: "",
                  at: "",
                  start: "",
                  end: "",
                  systemStatus: NOTIFICATION_SYSTEM_STATUS.SCHEDULED,
                  status: NOTIFICATION_STATUS.NONE,
                  comment: "",
                  quantity: this.getMomentsQuantity(t, timing.quantities),
                });
              }
            });
          });
        });
      });
    });
    return notif;
  }

  private static getOldTimingFromHistoric(currentTiming: ITiming, actions: IDrugAction[]) {
    const oldTiming: ITiming = Tools.clone(currentTiming);
    for (const a of actions) {
      if (a.actionType === ACTION_DRUGS_TYPE.UPDATE_FREQUENCY) {
        return JSON.parse(a.exValue) as ITiming;
      } else if (a.actionType === ACTION_DRUGS_TYPE.UPDATE_MOMENT) {
        if (a.exValue?.includes(":")) {
          const times = a.exValue.split(", ");
          oldTiming.timeOfDay = times;
        } else {
          oldTiming.timingCode = a.exValue;
        }
      } else if (a.actionType === ACTION_DRUGS_TYPE.UPDATE_DATE) {
        // We need to know whether it's the start or end date !
        const isStartDate = a.newValue === currentTiming.boundsPeriod.start;
        if (isStartDate) {
          oldTiming.boundsPeriod.start = a.exValue;
        } else {
          oldTiming.boundsPeriod.end = a.exValue;
        }
      }
    }
    return oldTiming;
  }

  public list(patientId: string): Observable<Entitylink[]> {
    const routeName = this.drugsApiService.readRoutes[0];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("DrugsService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<Entitylink[]>;
        }
        return this.drugsApiService.list(patientId);
      })
    );
  }

  public listIntake(patientId: string, itemId?: string): Observable<Notification[]> {
    const routeName = this.drugsApiService.readRoutes[1];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("DrugsService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<Notification[]>;
        }
        return this.drugsApiService.listIntake(patientId, itemId);
      })
    );
  }

  public getOne(id: string): Promise<Entitylink> {
    return this.drugsApiService.getOne(id).toPromise();
  }

  public save(drug: IEntitylink): Observable<number> {
    return this.drugsApiService.save(drug);
  }

  public delete(drug: IEntitylink): Observable<number> {
    drug.actionStatus = ActionStatusEntity.DELETED;
    return this.drugsApiService.save(drug);
  }

  public getHistoric(patientId: string): Observable<IDrugsHistoric[]> {
    const routeName = this.drugsApiService.readRoutes[4];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("DrugsService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<IDrugsHistoric[]>;
        }
        return this.drugsApiService.getHistoric(patientId);
      })
    );
  }

  public listInfo(query: string): Observable<IDrugInfo[]> {
    const routeName = this.drugsApiService.readRoutes[2];
    return this.userService.isAuthorized(routeName, "GET").pipe(
      concatMap((isAuth) => {
        if (!isAuth) {
          FileLogger.warn("DrugsService", "User does not have access to: GET " + routeName);
          return of([]) as Observable<IDrugInfo[]>;
        }
        return this.drugsApiService.listInfo(query, this.sessionsService.userLang);
      })
    );
  }

  /**
   * return short/long description for an activity
   */
  public computeFrequency(timing: ITiming, withShortLabel = false): string {
    if (!timing) {
      return "";
    }
    const freqOption = TimingData.getFreqOption(timing);
    const timingOption = TimingData.getTimingOption(timing);
    const hasTiming = TimingData.hasTiming(timing, freqOption, timingOption);
    // fix when drug have no timingCode (from careplan for now)
    if (!hasTiming) {
      return "";
    }
    let description = "";
    const timesPerDay = TimingData.getIntakesPerDay(timing, timingOption).length;
    const timesPerDayStr = timesPerDay > 0 ? timesPerDay.toString() : "";

    if (freqOption === TimingData.asNecessaryOption) {
      return this.translateService.instant(TimingData.asNecessaryOptionDisplay);
    } else if (freqOption === TimingData.fixedDaysOption) {
      const nbDays = timing.event.length;
      description += timesPerDayStr + "x/ " + nbDays + " " + this.translateService.instant(TimingData.fixedDaysOptionDisplay);
      if (!withShortLabel) {
        const dates = timing.event.map((e) => moment(e).format("DD-MM-YYYY"));
        description += " (" + dates.join(", ") + ")";
      }
      return description;
    }
    const period = TimingData.getScheduledPeriod(timing);
    let timingPeriod = 0;
    if (timing.period > 1) {
      timingPeriod = timing.period;
    }
    switch (period) {
      // minute aren't supported yet
      case SCHEDULE_PERIOD.MINUTE:
        description += this.translateService.instant(withShortLabel ? "periodUnit.minute.short" : "periodUnit.minute.plural");
        break;
      // HOUR : every X hours (if x=1, don't show)
      case SCHEDULE_PERIOD.HOUR:
        description += this.translateService.instant("periodUnit.everyF") + " " + (timingPeriod > 1 ? timingPeriod + " " : "");
        description += this.translateService.instant(withShortLabel ? "periodUnit.hour.short" : "periodUnit.hour.plural");
        break;
      // DAY : X x/d,  every Y days (if Y=1, don't show)
      case SCHEDULE_PERIOD.DAY:
        description +=
          timesPerDayStr +
          "x/" +
          this.translateService.instant("periodUnit.day.short") +
          " " +
          this.translateService.instant("periodUnit.everyM") +
          " " +
          (timingPeriod > 1 ? timingPeriod + " " : "");
        description += this.translateService.instant(withShortLabel ? "periodUnit.day.short" : "periodUnit.day.plural");
        break;
      // WEEK : X x/d, Yd every Z weeks (if Z=1, don't show)
      case SCHEDULE_PERIOD.WEEK:
        // add number per day
        description += timesPerDayStr + "x/" + this.translateService.instant("periodUnit.day.short") + ", ";
        // add number of days
        description +=
          this.getNbrDays(timing.when) +
          this.translateService.instant("periodUnit.day.short") +
          " " +
          this.translateService.instant("periodUnit.everyF") +
          " ";
        // add number of weeks
        description +=
          (timingPeriod > 1 ? timingPeriod + " " : "") +
          this.translateService.instant(withShortLabel ? "periodUnit.week.short" : "periodUnit.week.plural");
        // day
        description += ` (${this.getDayNames(timing.when)})`;
        break;
      // Month : Xx/d, Yd every Z month (if Z=1, don't show)
      case SCHEDULE_PERIOD.MONTH:
        // add number per day
        description += timesPerDayStr + "x/" + this.translateService.instant("periodUnit.day.short") + ", ";
        // add number of days
        description +=
          this.getNbrDays(timing.when) +
          this.translateService.instant("periodUnit.day.short") +
          " " +
          this.translateService.instant("periodUnit.everyM") +
          " ";
        // add number of months
        description +=
          (timingPeriod > 1 ? timingPeriod + " " : "") +
          this.translateService.instant(withShortLabel ? "periodUnit.month.short" : "periodUnit.month.plural");
        // day of Month
        description += ` (${this.getDayOfMonth(timing.when)})`;
        break;
      // year aren't supported yet
      case SCHEDULE_PERIOD.YEAR:
        description += this.translateService.instant(withShortLabel ? "periodUnit.year.short" : "periodUnit.year.plural");
        break;
    }

    if (timing.count && !withShortLabel) {
      description += " (max " + timing.count + "x)";
    }
    return description;
  }

  public getDayOfMonth(when: string): string {
    if (!when || when === "null") {
      return "";
    }
    let str = "";
    const arrWhen = (JSON.parse(when) as unknown[]).map((w) => String(w)).filter((v) => Tools.isDefined(v));

    arrWhen.forEach((v, i) => {
      str += v + (i < arrWhen.length - 1 ? ", " : "");
    });

    return str;
  }

  public getNbrDays(when: string): number {
    if (when && when !== "null") {
      return (JSON.parse(when) as unknown[]).map((w) => String(w)).filter((v) => Tools.isDefined(v)).length;
    } else {
      return 0;
    }
  }

  public getDayNames(when: string): string {
    if (!when || when === "null") {
      return "";
    }
    let str = "";
    const dayNames = this.keyDayNames();
    const arrWhen = (JSON.parse(when) as unknown[]).map((w) => String(w)).filter((v) => Tools.isDefined(v));

    const filtered = dayNames.filter((kv) => arrWhen.indexOf(kv.key) >= 0);

    filtered.forEach((kv, i) => {
      str += kv.value + (i < filtered.length - 1 ? ", " : "");
    });

    return str;
  }

  /**
   * return week days long name (in locale) and day number (1=monday, 7=sunday)
   */
  public keyDayNames(): KeyValue[] {
    const userLang = this.sessionsService.userLang;
    const dayNames = new Array<KeyValue>();
    for (let i = 1; i <= 7; i++) {
      dayNames.push(
        new KeyValue({
          key: i.toString(),
          value: moment().locale(userLang).isoWeekday(i).format("dddd"),
        })
      );
    }
    return dayNames;
  }

  public getPerformerName(reference: string): string {
    switch (reference) {
      case "H":
        return this.translateService.instant("drugagenda.hospital");
      case "D":
        return this.translateService.instant("drugagenda.familydoctor");
      case "P":
        return this.translateService.instant("drugagenda.pharmacist");
    }
  }

  /**
   * Generates a string representation of the drug's administration timing,
   * based on its frequency settings.
   *
   * If the drug uses a "fixed hours" timing configuration, the method returns
   * a comma-separated list of times (e.g., "08:00, 12:00, 18:00").
   *
   * Otherwise, it returns a localized list of moments (e.g., "Morning", "Evening"),
   * optionally followed by the corresponding quantity (e.g., "Morning: 1 inhalation"),
   * one per line (using HTML line breaks).
   *
   * Also leverages `getMomentsQuantity` to handle moment-to-quantity mapping,
   * including normalization of keys like "rising" → "rise" or "beding" → "bedtime".
   *
   * @param {EntityDrug} entityDrug - The drug entity containing frequency, timing, and quantity data.
   * @returns {string} A formatted HTML string describing the timing and quantities for the drug.
   */
  public getTiming(entityDrug: EntityDrug): string {
    let str = "";
    if (!TimingData.hasTiming(entityDrug.frequency)) {
      return str;
    }
    if (TimingData.getTimingOption(entityDrug.frequency) === TimingData.fixedHoursTimingOption) {
      str = entityDrug.frequency.timeOfDay.join(", ");
    } else {
      let nbrEl = 0;
      Entity.toKeyValues(entityDrug.frequency.timingCode).forEach((kv) => {
        if (kv.value) {
          const momentQuantity = DrugsService.getMomentsQuantity(kv.key, entityDrug?.frequency?.quantities);

          str +=
            (+nbrEl !== 0 ? "<br />" : "") +
            this.translateService.instant("mydrugs." + kv.key) +
            (momentQuantity ? ": " + EntityDrug.computeQuantityDisplay(momentQuantity) : "");
          nbrEl++;
        }
      });
    }
    return str;
  }

  /**
   * Transform TimingCode into 3 string
   */
  public getTimingFromTimingCode(timingCode: string): string {
    if (timingCode.includes(":")) {
      return timingCode;
    }
    let str = "";
    let nbrEl = 0;
    Entity.toKeyValues(timingCode).forEach((kv) => {
      if (kv.value) {
        str += (+nbrEl !== 0 ? ", " : "") + this.translateService.instant("mydrugs." + kv.key);
        nbrEl++;
      }
    });
    return str;
  }

  public async exportDrugs(patientId: string, lang: string): Promise<void> {
    this.drugsApiService.exportDrugs(patientId, lang).subscribe(
      (blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url);
      },
      (error: AppError) => {
        FileLogger.error("DrugsService", "exportDrugs", error);
        throw new Error(error.message);
      }
    );
  }

  public addDrugIntake(
    patientId: string,
    drug: IEntitylink,
    intakeValidation: boolean,
    quantityTaken?: string,
    comment?: string,
    time?: string,
    momentOfDay?: string,
    administrationTime?: string,
    quantity?: string,
    allNotifications?: Notification[]
  ): Observable<Notification> {
    const author: Reference = {
      reference: this.sessionsService.account.caremateIdentifier,
      display: this.sessionsService.account.displayName,
    };
    const intakes: IDrugIntakeNotif[] = [
      {
        drugId: drug._id,
        patientId,
        intakeValidation,
        author: author,
        drugStart: drug.entityData.frequency?.boundsPeriod?.start,
        drugEnd: drug.entityData.frequency?.boundsPeriod?.end,
        comment,
        time: this.ensureUniqueNotificationTime(this.extractDrugIntakesInfo(allNotifications), drug, time, author),
        administrationTime,
        moment: momentOfDay,
        quantity,
        quantityTaken,
        unscheduledIntake: false,
      },
    ];

    // If the quantity taken is greater than the quantity scheduled:
    if (quantityTaken && quantity && Number.isFinite(Number(quantity)) && Number(quantityTaken) > Number(drug.entityData.quantity)) {
      const surplus = Number(quantityTaken) - Number(quantity);

      intakes[0].quantityTaken = (+quantity).toFixed(2);
      intakes.push({
        drugId: drug._id,
        patientId,
        intakeValidation,
        author: author,
        drugStart: drug.entityData.frequency?.boundsPeriod?.start,
        drugEnd: drug.entityData.frequency?.boundsPeriod?.end,
        comment,
        time: this.ensureUniqueNotificationTime(
          [...this.extractDrugIntakesInfo(allNotifications), ...this.extractDrugIntakesInfo([intakes[0]])],
          drug,
          time,
          author
        ),
        administrationTime,
        moment: momentOfDay,
        //we don't pass the quantity for unscheduledIntake
        quantityTaken: surplus.toFixed(2),
        unscheduledIntake: true,
      });
    }
    if (drug.entityData.managedStock && this.userService.isAuthorizedSync(null, this.drugsApiService.updateRoutes[0], "PUT")) {
      let totalRemainingUsagesInc = -Number(quantityTaken);
      let totalUsagesInc = 0;
      if (drug.entityData.stock) {
        totalRemainingUsagesInc = drug.entityData.stock.totalRemainingUsages - Number(quantityTaken);
        totalUsagesInc = drug.entityData.stock.totalUsages;
        drug.entityData.stock = null;
        this.save(drug).subscribe({
          error: (err) => {
            console.error("Error while removing old stock: ", err);
          },
        });
      }
      this.drugsApiService
        .updateDrugStock({
          patientId,
          drugId: drug._id,
          totalRemainingUsagesInc,
          totalUsagesInc,
        })
        .subscribe({
          error: (err) => {
            console.error("Error while updating stock: ", err);
          },
        });
    }
    return this.drugsApiService.createDrugIntakes(intakes);
  }

  public getDrugInfo(drug: EntityDrug): Observable<IDrugInfo> {
    return this.drugsApiService.getDrugInfo(drug.reference, drug.source);
  }

  /**
   * Retrieves the dosage quantity for a specific moment of the day from a IQuantities object.
   *
   * This method also handles common mismatches between expected keys
   * and actual values by mapping:
   * - "rising" → "rise"
   * - "beding" → "bedtime"
   *
   * @param {string} moment - The moment of the day (e.g., "rising", "beding", "noon", etc.).
   * @param {IQuantities} quantities - the timing quantities
   * @returns {string} The quantity to take at the specified moment
   */
  public static getMomentsQuantity(moment: string, quantities: IQuantities): string {
    let quantity: string;
    switch (moment) {
      case "rising":
        quantity = quantities?.rise;
        break;
      case "beding":
        quantity = quantities?.bedtime;
        break;
      default:
        quantity = quantities?.[moment];
        break;
    }
    return quantity ?? "";
  }

  public async getDrugStock(patientId: string, drugId: string): Promise<IDrugStock> {
    return this.drugsApiService.getDrugStock(patientId, drugId).toPromise();
  }

  public async createDrugIntakesPDF(drugIntakePdfBody: IExportDrugIntakePdfBody): Promise<void> {
    return new Promise((resolve) => {
      this.drugsApiService.createDrugIntakesPDF(drugIntakePdfBody).subscribe(
        (blob) => {
          const url = URL.createObjectURL(blob);
          window.open(url);
          resolve();
        },
        (error: AppError) => {
          FileLogger.error("DrugsService", "createDrugIntakesPDF", error);
          resolve();
        }
      );
    });
  }

  public isInfinite(date: string): boolean {
    if (date && date.length && date[0] === "9") {
      return true;
    }
  }

  public getFormatDate(d: Date): string {
    const date = new Date(d);
    return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  }

  /**
   * Checks if the notification time is unique by comparing existing notifications.
   * If a notification with the same (drugId, time, author) already exists,
   * it increments the time by one second until a unique time is found.
   *
   * @param allNotifications - An array of notifications to check against.
   * @param drug - The `drug` object containing the medication ID (`_id`) used for indexing.
   * @param time - The initially proposed time for the notification.
   * @param author - The author of the notification, used for comparison.
   * @returns A unique time after verification.
   */
  private ensureUniqueNotificationTime(
    allNotifications: { drugId: string; time: string; author: Reference }[],
    drug: IEntitylink,
    time: string,
    author: Reference
  ): string {
    while (allNotifications.find((n) => n.drugId === drug._id && n.time === time && n.author?.reference === author?.reference)) {
      time = moment(time).add(1, "second").format();
    }
    return time;
  }

  /**
   * Extracts the essential information from each notification or drug intake.
   * This method returns an array of objects containing the drug ID, time, and author.
   *
   * @param notifs - An array of notifications or drug intake notifications to transform.
   * @returns An array of objects containing `drugId`, `time`, and `author` properties for each notification.
   */
  private extractDrugIntakesInfo(notifs: (Notification | IDrugIntakeNotif)[]): { drugId: string; time: string; author: Reference }[] {
    return notifs.map((notif) => ({
      drugId: "appId" in notif ? notif.appId : notif.drugId,
      time: notif.time,
      author: notif.author,
    }));
  }
}
