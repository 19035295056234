import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IPrescription, IPrescriptionInfosResponse, RENEW_TIMING } from "src/app/models/prescription.interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class PrescriptionApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Prescription", // Theme !
      ["dashboard/acceptPrescription"], // Post route paths
      [
        "dashboard/prescriptionInfos",
        "dashboard/nbPrescriptionInfos",
        "dashboard/prescriptionsToBeRenewedInfos",
        "dashboard/nbPrescriptionsToBeRenewed",
      ], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public getPrescriptionInfos(params): Observable<IPrescriptionInfosResponse[]> {
    return this.api.get(this.readRoutes[0], params) as Observable<IPrescriptionInfosResponse[]>;
  }

  public acceptPrescription(prescriptionId: string, careplanId?: string): Observable<unknown> {
    const params = { prescriptionId, careplanId };
    return this.api.post(this.createRoutes[0], params) as Observable<unknown>;
  }

  public getPrescriptionInfosCount(
    patientCaremateId?: string,
    services?: string[],
    organizations?: string[],
    period?: string,
    date?: string,
    status?: number[]
  ): Observable<{
    count: number;
  }> {
    const params = { patientCaremateId, services, organizations, period, date, status: status };
    return this.api.get(this.readRoutes[1], params) as Observable<{
      count: number;
    }>;
  }

  public getPrescriptionsToBeRenewedInfos(
    timing: RENEW_TIMING,
    pageNumber: number,
    pageSize: number,
    services?: string
  ): Observable<IPrescription[]> {
    const params = { timing, pageNumber, pageSize, services };
    return this.api.get(this.readRoutes[2], params) as Observable<IPrescription[]>;
  }

  public getPrescriptionsToBeRenewedCount(
    timing: RENEW_TIMING,
    services?: string[]
  ): Observable<{
    count: number;
  }> {
    const params = { timing, services };
    return this.api.get(this.readRoutes[3], params) as Observable<{
      count: number;
    }>;
  }
}
