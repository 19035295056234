import { Notation } from "./careplans.interface";
import { Codes } from "./codes.interface";
import { Identifier } from "./identifier.interface";
import { Period } from "./period.interface";
import { Reference } from "./reference.interface";
import { IEntity } from "./sharedInterfaces";

export enum PRESCRIPTION_STATUS {
  REQUEST,
  IN_PROGRESS,
  CANCELED,
  COMPLETED,
}

export interface IPrescriptionInfosResponse {
  prescriptionId: string;
  patientCaremateId: string;
  firstname: string;
  lastname: string;
  status: PRESCRIPTION_STATUS;
  category: string;
  dateRequest: string;
}

export interface IOccurencePrescription {
  occurenceDateTime: string;
  occurenceTiming: IOccurenceTiming;
  renewal: boolean;
  renewalParams?: IOccurenceTiming;
}

export interface IOccurenceTiming {
  duration: number;
  durationUnit: string;
}

export interface IBasedOnPrescription {
  careplan: Reference | null;
  prescription: Reference | null; // Previous prescription linked to new one
}
export interface IPausePrescription {
  occurencePeriod: Period;
  notes: Notation | null;
}

export interface IPrescriptionAddress {
  country: string;
  city: string;
  postalCode: string;
  streetName: string;
  streetNumber: string;
  box: string;
}

export enum DEVICE_TYPE {
  BUY,
  RENT,
}

export enum DEVICE_PAID_BY {
  PATIENT,
  ORG,
  COM,
}

export interface IPrescriptionDevice {
  orderId: string;
  type: DEVICE_TYPE;
  paidBy: DEVICE_PAID_BY;
  reimbursed: boolean;
}

export interface IOrderDetail {
  devices: IPrescriptionDevice[];
  deliverTo: {
    name: string;
    address: IPrescriptionAddress;
  };
}

export interface IPrescription extends IEntity {
  identifier: Identifier[];
  category: Codes;
  status: PRESCRIPTION_STATUS;
  subject: Reference;
  basedOn: IBasedOnPrescription;
  authoredOn: string;
  occurence: IOccurencePrescription | null;
  pauses: IPausePrescription[];
  author: Reference;
  requester: Reference | null;
  performer: Reference[];
  orderDetail?: IOrderDetail;
  reason: Reference[];
}

export enum RENEW_TIMING {
  WEEK = "WEEK",
  TWO_WEEKS = "TWO_WEEKS",
  MONTH = "MONTH",
  END_OF_MONTH = "END_OF_MONTH",
}
