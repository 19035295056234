import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import moment from "moment";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { FormsData } from "src/app/helpers/formsData";
import { EntityDrug, IEntitylink } from "src/app/models/entity.interface";
import { Notification } from "src/app/models/notifications.interface";
import { DrugsService } from "src/app/providers/drugs.service";

@Component({
  selector: "app-add-drug-intake-dialog",
  templateUrl: "./add-drug-intake-dialog.component.html",
  styleUrls: ["./add-drug-intake-dialog.component.scss"],
})
export class AddDrugIntakeDialogComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public form: UntypedFormGroup;
  public filteredQuantities: string[] = [];
  public availableQuantities: Observable<string[]>;

  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AddDrugIntakeDialogComponent>,
    private fb: UntypedFormBuilder,
    private drugService: DrugsService,
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string; drug: IEntitylink; time: string; moment: string; quantity: string; allNotifications: Notification[] }
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      intakeValidation: [true, Validators.required],
      quantityTaken: this.createQuantityFormControl(this.data.quantity),
      time: [moment().format("HH:mm"), Validators.required],
      comment: ["", []],
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public save(): void {
    this.isLoading = true;
    const entityDrug = this.data.drug.entityData as EntityDrug;
    const formValue = this.form.getRawValue();
    const quantityTaken = EntityDrug.computeQuantityValue(formValue.quantityTaken, entityDrug.managedStock);
    const today = this.data.time ? moment(this.data.time) : moment().startOf("day");
    const timeTaken = moment(formValue.time, "HH:mm");
    const finalDateTime = today.set("hour", timeTaken.hour()).set("minute", timeTaken.minute());
    this.drugService
      .addDrugIntake(
        this.data.patientId,
        this.data.drug,
        formValue.intakeValidation,
        quantityTaken,
        formValue.comment,
        this.data.time,
        this.data.moment,
        finalDateTime.format(),
        this.data.quantity, // !!! that's the quantity from the notification that match the historical value !!!
        this.data.allNotifications
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((notif) => {
        this.isLoading = false;
        this.dialogRef.close(notif);
      });
  }

  private createQuantityFormControl(quantityValue: string): FormControl {
    const entityDrug = this.data.drug.entityData as EntityDrug;
    const quantityValidator = entityDrug.managedStock ? [Validators.pattern(/^[0-9]*(\s\+\s)?([1-3]\/[2-4])?$/)] : undefined;
    const v = EntityDrug.computeQuantityDisplay(quantityValue, true);
    const fc = new FormControl(null, quantityValidator);
    // This will create some sort of autocomplete for the quantities
    // Each quantity input has its autocomplete filter:
    const obs: Observable<string[]> = fc.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      map((r) => {
        return FormsData.filterQuantities(r, fc.valid, entityDrug.managedStock);
      })
    );
    this.availableQuantities = obs;
    setTimeout(() => {
      fc.setValue(v);
    }, 0);
    return fc;
  }
}
