import { Component, Input, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { BillingDataSource } from "../../billing.datasource";
import { BILLING_TYPE, IChargeItemsInfo } from "../../billing.interface";

@Component({
  selector: "app-invoice-list",
  templateUrl: "./invoice-list.component.html",
  styleUrls: ["./invoice-list.component.scss", "../../../../../assets/comunicare-list.scss"],
})
export class InvoiceListComponent implements OnDestroy {
  @Input() dataSource: BillingDataSource;
  @Input() data: IChargeItemsInfo[];
  protected onDestroy$ = new Subject<void>();
  billingStatusClasses: { [key in BILLING_TYPE]: string } = {
    [BILLING_TYPE.PLANNED]: "planned",
    [BILLING_TYPE.BILLABLE]: "billable",
    [BILLING_TYPE.NOT_BILLABLE]: "not-billable",
    [BILLING_TYPE.BILLED]: "billed",
  };

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public invoiceRowClicked(_event): void {
    //@TODO: CMATE-7027
  }
}
