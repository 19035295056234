import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiSeparatorComponent } from "./ui-separator.component";

@NgModule({
  declarations: [UiSeparatorComponent],
  imports: [CommonModule],
  exports: [UiSeparatorComponent],
})
export class UiSeparatorModule {}
