import { CdkDrag } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable } from "@angular/material/table";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { IPrescriptionInfosResponse, PRESCRIPTION_STATUS } from "src/app/models/prescription.interface";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { PrescriptionService } from "src/app/providers/prescription.service";
import { ResponsiveDialogService } from "src/app/providers/responsive-dialog.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { CareplanChoiceDialogComponent } from "./careplan-choice-dialog/careplan-choice-dialog.component";
import { PendingPrescriptionsDataSource } from "./pending-prescriptions-widget-datasource";

@Component({
  selector: "app-pending-prescriptions-widget",
  templateUrl: "./pending-prescriptions-widget.component.html",
  styleUrls: ["./pending-prescriptions-widget.component.scss"],
})
export class PendingPrescriptionsWidgetComponent implements IWidgetComponent, OnInit, OnDestroy {
  @ViewChild(CdkDrag, { static: true }) cdkDrag: CdkDrag;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<IPrescriptionInfosResponse>;

  public isLoading = true;
  public isDraggable = true;
  public displayedColumns: string[] = ["patient", "status", "category", "dateRequest", "actions"];
  public dataSource: PendingPrescriptionsDataSource;
  public pendingPrescriptions: IPrescriptionInfosResponse[] = [];
  private onDestroy$ = new Subject<void>();
  public widgetName = WidgetName;
  prescriptionInfoSubscription: Subscription;
  currentPageSize = 5;
  pendingPrescriptionsCount: number;
  private healthcareServicesRefs: string[];
  currentSubscription: Subscription;

  constructor(
    private prescriptionService: PrescriptionService,
    private preferenceService: PreferenceService,
    private responsiveDialog: ResponsiveDialogService,
    private router: Router,
    private sessionService: SessionService,
    private healthcareService: HealthcareserviceService,
    public responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this.dataSource = new PendingPrescriptionsDataSource(this.prescriptionService);

    // Subscribe to services setup changes
    this.sessionService.servicesSetupWatch.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.updateHealthcareServicesRefsAndLoad();
    });

    // If services are already available at init time, load data immediately
    if (this.sessionService.currentService) {
      this.updateHealthcareServicesRefsAndLoad();
    }
  }

  private updateHealthcareServicesRefsAndLoad(): void {
    if (this.sessionService.currentService.reference === "all") {
      // Use all available service references
      this.healthcareServicesRefs = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    } else {
      // Use only the current service reference
      this.healthcareServicesRefs = [this.sessionService.currentService?.reference];
    }

    // Load pending prescriptions
    this.loadPendingPrescriptions();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadPendingPrescriptions() {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }

    this.currentSubscription = this.prescriptionService
      .getPendingPrescriptionCount({
        services: this.healthcareServicesRefs,
        status: [PRESCRIPTION_STATUS.REQUEST],
      })
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.pendingPrescriptionsCount = result?.count;
        this.dataSource.loadData({
          pageNumber: 0,
          pageSize: this.currentPageSize,
          filters: [],
          services: this.healthcareServicesRefs,
          status: [PRESCRIPTION_STATUS.REQUEST],
        });

        this.isLoading = false;
      });
  }

  public validatePrescription(prescription: IPrescriptionInfosResponse): void {
    // For every prescriptions that is not "Telemonitoring"

    if (prescription.category !== "Telemonitoring") {
      this.prescriptionService
        .acceptPrescription(prescription.prescriptionId)
        .pipe(first())
        .subscribe(() => {
          this.loadPendingPrescriptions();
        });
      return;
    }

    // For "Telemonitoring" prescriptions
    const dialog = this.responsiveDialog.open(CareplanChoiceDialogComponent, {
      data: {
        prescription: prescription,
      },
    });

    dialog.afterClosed().subscribe((careplanId) => {
      if (!careplanId) return; // If no careplan is selected, do nothing

      this.prescriptionService
        .acceptPrescription(prescription.prescriptionId, careplanId)
        .pipe(first())
        .subscribe(() => {
          this.loadPendingPrescriptions();
        });
    });
  }

  public encodeToB64(str: string): string {
    return Base64Helper.utf8_to_b64(str);
  }
}
